import React, { useState, useEffect } from 'react';
import { updateAccountDetails, sendOTP, verifyOTP, checkUserFields } from '../../utils/accountAPI';
import { User } from '../../../../types';
import styles from './PersonalInfoForm.module.css';
import { useError } from '../../components/ErrorContext';

interface PersonalInfoFormProps {
  user: Partial<User>;
  token: string;
  onUpdate: (updatedUser: Partial<User>) => void;
  onSuccess: (message: string) => void;
}

const PersonalInfoForm: React.FC<PersonalInfoFormProps> = ({ user, token, onUpdate, onSuccess }) => {
  const { showError } = useError();
  const [formData, setFormData] = useState({
    first_name: user.first_name || '',
    last_name: user.last_name || '',
    email: user.email || '',
    phone: user.phone || '',
    type: user.type,
    username: user.username || '',
    is_active: user.is_active,
    push_notifications: user.push_notifications,
    credits: user.credits,
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
    newEmail: user.email || '',
    newPhone: user.phone || '',
    emailOtp: '',
    phoneOtp: '',
  });
  
  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const [isChangingEmail, setIsChangingEmail] = useState(false);
  const [isChangingPhone, setIsChangingPhone] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isPhoneVerified, setIsPhoneVerified] = useState(false);
  const [phoneTimer, setPhoneTimer] = useState(0);
  const [emailTimer, setEmailTimer] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let timerId: NodeJS.Timeout;
    if (phoneTimer > 0) {
      timerId = setInterval(() => setPhoneTimer(prev => prev - 1), 1000);
    }
    return () => clearInterval(timerId);
  }, [phoneTimer]);

  useEffect(() => {
    let timerId: NodeJS.Timeout;
    if (emailTimer > 0) {
      timerId = setInterval(() => setEmailTimer(prev => prev - 1), 1000);
    }
    return () => clearInterval(timerId);
  }, [emailTimer]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const validatePassword = (password: string): boolean => {
    const regex = /^(?=.*[A-Z])(?=.*\d).{8,}$/;
    return regex.test(password);
  };

  const handleSendOTP = async (type: 'email' | 'phone') => {
    const contact = type === 'email' ? formData.newEmail : formData.newPhone;
    const timer = type === 'email' ? emailTimer : phoneTimer;
    
    if (timer > 0) {
      showError(`Please wait ${timer} seconds before requesting a new OTP.`);
      return;
    }

    if (!contact) {
      showError(`Please enter a valid ${type} address before requesting OTP.`);
      return;
    }

    try {
      const response = await sendOTP(contact, type);
      
      if (!response) {
        showError(`Failed to send OTP to ${type}. Please check your ${type} and try again.`);
        return;
      }

      if (type === 'email') {
        setEmailTimer(60);
      } else {
        setPhoneTimer(60);
      }
      showError(`OTP sent to your ${type}`, 'info');
    } catch (err: any) {
      // Handle specific error cases
      if (err.response?.status === 429) {
        showError(`Too many OTP requests. Please try again later.`);
      } else if (err.response?.status === 400) {
        showError(`Invalid ${type} format. Please check and try again.`);
      } else if (err.response?.status === 503) {
        showError(`OTP service is currently unavailable. Please try again later.`);
      } else {
        showError(`Failed to send OTP to ${type}. ${err.response?.data?.message || 'Please try again.'}`);
      }
    }
  };

  const handleVerifyOTP = async (type: 'email' | 'phone') => {
    const otp = type === 'email' ? formData.emailOtp : formData.phoneOtp;
    const contact = type === 'email' ? formData.newEmail : formData.newPhone;

    if (!otp) {
      showError(`Please enter the OTP sent to your ${type}.`);
      return;
    }

    try {
      const verified = await verifyOTP(contact || '', otp, type);
      if (verified) {
        if (type === 'email') {
          setIsEmailVerified(true);
        } else {
          setIsPhoneVerified(true);
        }
        showError(`${type} verified successfully`, 'info');
      } else {
        showError(`Invalid OTP for ${type}. Please check and try again.`);
      }
    } catch (err: any) {
      if (err.response?.status === 401) {
        showError(`Invalid or expired OTP. Please request a new one.`);
      } else if (err.response?.status === 429) {
        showError(`Too many verification attempts. Please try again later.`);
      } else {
        showError(`Failed to verify ${type}. ${err.response?.data?.message || 'Please try again.'}`);
      }
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      const updates: Partial<User> & { oldPassword?: string; newPassword?: string } = {
        ...user,
        first_name: formData.first_name,
        last_name: formData.last_name,
      };

      if (isChangingPassword) {
        if (!formData.oldPassword) {
          throw new Error('Current password is required');
        }
        if (formData.newPassword !== formData.confirmPassword) {
          throw new Error('New passwords do not match');
        }
        if (!validatePassword(formData.newPassword)) {
          throw new Error('Password must be at least 8 characters with 1 uppercase and 1 number');
        }
        updates.oldPassword = formData.oldPassword;
        updates.newPassword = formData.newPassword;
      }

      if (isChangingEmail && formData.newEmail !== user.email) {
        if (!isEmailVerified) {
          throw new Error('Please verify your new email');
        }
        updates.email = formData.newEmail;
      } else {
        updates.email = user.email;
      }

      if (isChangingPhone && formData.newPhone !== user.phone) {
        if (!isPhoneVerified) {
          throw new Error('Please verify your new phone number');
        }
        updates.phone = formData.newPhone;
      } else {
        updates.phone = user.phone;
      }

      try {
        const response = await updateAccountDetails(token, updates);
        onUpdate(response.data);
        onSuccess('Account updated successfully');
        
        setIsChangingPassword(false);
        setIsChangingEmail(false);
        setIsChangingPhone(false);
        setIsEmailVerified(false);
        setIsPhoneVerified(false);
        
        setFormData(prev => ({
          ...prev,
          ...response.data,
          newEmail: response.data.email || '',
          newPhone: response.data.phone || '',
          oldPassword: '',
          newPassword: '',
          confirmPassword: '',
          emailOtp: '',
          phoneOtp: '',
        }));
        
      } catch (error: any) {
        if (error.response?.status === 401) {
          showError('Current password is incorrect');
          return;
        }
        throw error;
      }
      
    } catch (err) {
      showError(err instanceof Error ? err.message : 'Failed to update account');
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className={styles.form}>
      <div className={styles.formGroup}>
        <label htmlFor="first_name">First Name:</label>
        <input
          type="text"
          id="first_name"
          name="first_name"
          value={formData.first_name || ''}
          onChange={handleInputChange}
          required
        />
      </div>

      <div className={styles.formGroup}>
        <label htmlFor="last_name">Last Name:</label>
        <input
          type="text"
          id="last_name"
          name="last_name"
          value={formData.last_name || ''}
          onChange={handleInputChange}
          required
        />
      </div>

      {/* Email Section */}
      <div className={styles.formGroup}>
        <label>Email:</label>
        {!isChangingEmail ? (
          <div className={styles.valueWithEdit}>
            <span>{user.email}</span>
            <button type="button" onClick={() => setIsChangingEmail(true)}>
              Change Email
            </button>
          </div>
        ) : (
          <div className={styles.verificationGroup}>
            <input
              type="email"
              name="newEmail"
              value={formData.newEmail}
              onChange={handleInputChange}
              placeholder="New Email"
            />
            {!isEmailVerified && (
              <>
                <input
                  type="text"
                  name="emailOtp"
                  value={formData.emailOtp}
                  onChange={handleInputChange}
                  placeholder="Enter OTP"
                />
                <button
                  type="button"
                  onClick={() => handleSendOTP('email')}
                  disabled={emailTimer > 0}
                >
                  {emailTimer > 0 ? `Resend in ${emailTimer}s` : 'Send OTP'}
                </button>
                <button
                  type="button"
                  onClick={() => handleVerifyOTP('email')}
                >
                  Verify
                </button>
              </>
            )}
          </div>
        )}
      </div>

      {/* Phone Section */}
      <div className={styles.formGroup}>
        <label>Phone:</label>
        {!isChangingPhone ? (
          <div className={styles.valueWithEdit}>
            <span>{user.phone}</span>
            <button type="button" onClick={() => setIsChangingPhone(true)}>
              Change Phone
            </button>
          </div>
        ) : (
          <div className={styles.verificationGroup}>
            <input
              type="tel"
              name="newPhone"
              value={formData.newPhone}
              onChange={handleInputChange}
              placeholder="New Phone"
            />
            {!isPhoneVerified && (
              <>
                <input
                  type="text"
                  name="phoneOtp"
                  value={formData.phoneOtp}
                  onChange={handleInputChange}
                  placeholder="Enter OTP"
                />
                <button
                  type="button"
                  onClick={() => handleSendOTP('phone')}
                  disabled={phoneTimer > 0}
                >
                  {phoneTimer > 0 ? `Resend in ${phoneTimer}s` : 'Send OTP'}
                </button>
                <button
                  type="button"
                  onClick={() => handleVerifyOTP('phone')}
                >
                  Verify
                </button>
              </>
            )}
          </div>
        )}
      </div>

      {/* Password Section */}
      <div className={styles.formGroup}>
        <label>Password:</label>
        {!isChangingPassword ? (
          <button
            type="button"
            onClick={() => setIsChangingPassword(true)}
            className={styles.changePasswordBtn}
          >
            Change Password
          </button>
        ) : (
          <div className={styles.passwordGroup}>
            <input
              type="password"
              name="oldPassword"
              value={formData.oldPassword}
              onChange={handleInputChange}
              placeholder="Current Password"
            />
            <input
              type="password"
              name="newPassword"
              value={formData.newPassword}
              onChange={handleInputChange}
              placeholder="New Password"
            />
            <input
              type="password"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleInputChange}
              placeholder="Confirm New Password"
            />
          </div>
        )}
      </div>

      <button
        type="submit"
        className={styles.submitButton}
        disabled={loading}
      >
        {loading ? 'Updating...' : 'Update Account'}
      </button>
    </form>
  );
};

export default PersonalInfoForm; 