import React from 'react';
import { Link } from 'react-router-dom';
import { Mail, Phone, MapPin, Star } from 'lucide-react';
import styles from './footer.module.css';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className={styles.footer}>
      <div className={styles.footer__content}>
        <div className={styles.footer__sectionMain}>
          <img
            src="https://ushop.bid/uploads/logo.png"
            alt="UShop Logo"
            className={styles.footer__logo}
          />
          <p className={styles.footer__description}>
            UShop: Your premier destination for exciting online auctions. Discover great deals and
            enjoy thrilling bidding experiences.
          </p>
        </div>
        
        <div className={styles.footer__sections}>
          <div className={styles.footer__section}>
            <h3 className={styles.footer__title}>Site Map</h3>
            <div className={styles.footer__menuGrid}>
              <ul className={styles.footer__list}>
                <li>
                  <Link to="/" className={styles.footer__link}>Home</Link>
                </li>
                <li>
                  <Link to="/upcoming" className={styles.footer__link}>Upcoming</Link>
                </li>
                <li>
                  <Link to="/winners" className={styles.footer__link}>Winners</Link>
                </li>
                <li>
                  <Link to="/market" className={styles.footer__link}>Market</Link>
                </li>
              </ul>
              <ul className={styles.footer__list}>
                <li>
                  <Link to="/faq" className={styles.footer__link}>FAQ</Link>
                </li>
                <li>
                  <Link to="/info" className={styles.footer__link}>Legal</Link>
                </li>
                <li>
                  <Link to="/contact" className={styles.footer__link}>Contact</Link>
                </li>
                <li>
                  <Link to="/charity" className={styles.footer__link}>Charity</Link>
                </li>
              </ul>
            </div>
          </div>

         

          <div className={styles.footer__section}>
            <h3 className={styles.footer__title}>Payments & Trust</h3>
            <div className={styles.footer__payments}>
              <div className={styles.footer__paymentIcons}>
                <img src="https://raw.githubusercontent.com/datatrans/payment-logos/master/assets/cards/visa.svg" alt="Visa" />
                <img src="https://raw.githubusercontent.com/datatrans/payment-logos/master/assets/cards/mastercard.svg" alt="Mastercard" />
                <img src="https://raw.githubusercontent.com/datatrans/payment-logos/master/assets/cards/american-express.svg" alt="American Express" />
                <img src="https://raw.githubusercontent.com/datatrans/payment-logos/master/assets/apm/paypal.svg" alt="PayPal" />
                <img src="https://raw.githubusercontent.com/datatrans/payment-logos/master/assets/wallets/apple-pay.svg" alt="Apple Pay" />
                <img src="https://raw.githubusercontent.com/datatrans/payment-logos/master/assets/wallets/google-pay.svg" alt="Google Pay" />
              </div>
            </div>
            <div className={styles.footer__trustpilot}>
              <h4>Ratings</h4>
              <div className={styles.footer__rating}>
                <Star size={20} fill="#00b67a" color="#00b67a" />
                <Star size={20} fill="#00b67a" color="#00b67a" />
                <Star size={20} fill="#00b67a" color="#00b67a" />
                <Star size={20} fill="#00b67a" color="#00b67a" />
                {/* <Star size={20} fill="#00b67a" color="#00b67a" /> */}
                <span>on Trustpilot</span>
              </div>
              <a 
                href="https://www.trustpilot.com/review/ushop.bid" 
                target="_blank" 
                rel="noopener noreferrer"
                className={styles.footer__trustLink}
              >
                Read our reviews
              </a>
              <br />
              <a 
                href="https://www.trustpilot.com/review/ushop.bid" 
                target="_blank" 
                rel="noopener noreferrer"
                className={styles.footer__trustLink}
              >
                Leave a review
              </a>
            </div>
          </div>

          <div className={styles.footer__section}>
            <h3 className={styles.footer__title}>Follow Us</h3>
            <div className={styles.footer__qrCodes}>
              <a
                href="https://www.facebook.com/profile.php?id=100083428923085"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.footer__qrCode}
              >
                <h5>Facebook</h5>
                <img
                  src={`${process.env.REACT_APP_API_URL?.replace('/api', '') || 'http://127.0.0.1:4000'
                    }/uploads/fbqr.png`}
                  alt="Facebook QR Code"
                />
              </a>
              <a
                href="https://www.tiktok.com/@ushop.bid?_t=8q5ato3Ljl2&_r=1"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.footer__qrCode}
              >
                <h5>TikTok</h5>
                <img
                  src={`${process.env.REACT_APP_API_URL?.replace('/api', '') || 'http://127.0.0.1:4000'
                    }/uploads/tiktokqr.png`}
                  alt="TikTok QR Code"
                />
              </a>
              <a
                href="https://www.instagram.com/ushop.bid?igsh=cDd3aWF4NWd5OGJs"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.footer__qrCode}
              >
                <h5>Instagram</h5>
                <img
                  src={`${process.env.REACT_APP_API_URL?.replace('/api', '') || 'http://127.0.0.1:4000'
                    }/uploads/instaqr.png`}
                  alt="Instagram QR Code"
                />
              </a>
            </div>
          </div>


          <div className={styles.footer__section}>
            <h3 className={styles.footer__title}>Contact</h3>
            <ul className={styles.footer__list}>
              <li className={styles.footer__contactItem}>
                <a href="mailto:support@ushop.bid" className={styles.footer__link}>
                  info@ushop.bid
                </a>
              </li>
              <li className={styles.footer__contactItem}>
                <span>27 Old Gloucester St, London, WC1N 3AX</span>
              </li>
            </ul>
          </div>


        </div>
      </div>
      <div className={styles.footer__bottom}>

            <div className={styles.footer__companyInfo}>
            <p className={styles.footer__copyright}>&copy; {currentYear} UShop. All rights reserved.</p>

              <p>USHOP.BID Ltd. Company No. 15504752</p>
              <span className={styles.footer__divider}>•</span>
              <p>Registered in England and Wales</p>
            </div>
          </div>

    </footer>
  );
};

export default Footer;
