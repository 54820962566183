// src/pages/UsersPage.tsx
import React, { useState, useEffect } from 'react';
import UserList from '../components/UserList';
import UserForm from '../components/UserForm';
import AddressForm from '../components/AddressForm';
import { getUsers, getAddresses } from '../../utils/adminAPI';
import { Address, User } from '../../../../types';

const UsersPage: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [addresses, setAddresses] = useState<Address[]>([]);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [selectedAddress, setSelectedAddress] = useState<Address | null>(null);
  const [isAddingAddress, setIsAddingAddress] = useState(false);
  const [addressUserId, setAddressUserId] = useState<number | null>(null);
  const token = localStorage.getItem('token');

  useEffect(() => {
    fetchUsers();
    fetchAddresses();
  }, []);

  const fetchUsers = async () => {
    try {
      if(token){
        const response = await getUsers(token);
        setUsers(response.data);
      }
    } catch (error) {
      console.error('Failed to fetch users', error);
    }
  };

  const fetchAddresses = async () => {
    try {
      if(token){
        const response = await getAddresses(token);
        setAddresses(response.data);
      }
    } catch (error) {
      console.error('Failed to fetch addresses', error);
    }
  };

  const handleEdit = (user: User) => {
    setSelectedUser(user);
    setSelectedAddress(null);
    setIsAddingAddress(false);
  };

  const handleEditAddress = (address: Address) => {
    setSelectedAddress(address);
    setSelectedUser(null);
    setIsAddingAddress(false);
  };

  const handleAddAddress = (userId: number) => {
    setAddressUserId(userId);
    setSelectedAddress(null);
    setSelectedUser(null);
    setIsAddingAddress(true);
  };

  const handleFormSubmit = () => {
    fetchUsers();
    fetchAddresses();
    setSelectedUser(null);
    setSelectedAddress(null);
    setIsAddingAddress(false);
  };

  return (
    <div>
            <h1>Create User</h1>

       { <UserForm selectedUser={null} onSubmit={handleFormSubmit}
       />}
      
      <h1>User Management</h1>

      <UserList
        users={users}
        addresses={addresses}
        onEdit={handleEdit}
        onEditAddress={handleEditAddress}
        onAddAddress={handleAddAddress}
        fetchUsers={fetchUsers}
        fetchAddresses={fetchAddresses}
      />
     
 
    </div>
  );
};

export default UsersPage;
