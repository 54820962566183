import React from 'react';
import styles from './loading.module.css';

interface LoadingProps {
  message?: string;
  subMessage?: string;
}

const Loading: React.FC<LoadingProps> = ({ 
  message = 'Loading...',
  subMessage = 'Please wait while we fetch the data'
}) => {
  return (
    <div className={styles.loading}>
      <div className={styles.loadingContainer}>
        <div className={styles.loadingRing}></div>
        <div className={styles.loadingRing}></div>
        <div className={styles.loadingRing}></div>
      </div>
      <p className="mt-6 text-gray-700 font-medium animate-pulse">
        {message}
      </p>
      <div className="mt-2 text-sm text-gray-500 animate-fade-in">
        {subMessage}
      </div>
    </div>
  );
};

export default Loading;
