import React, { useState, useEffect } from 'react';
import { registerUser, sendOTP, verifyOTP, checkUserFields, loginUser, getAccountDetails } from '../../utils/accountAPI';
import { User, Address } from '../../../../types';
import styles from './RegisterPage.module.css';
import { useError } from '../../components/ErrorContext'; // Import the Error Context
import CreditForm from '../components/CreditForm';
import { useNavigate, useSearchParams } from 'react-router-dom';

type UserRegistrationData = Omit<User, 'id' | 'credits' | 'reg_date' | 'is_active'> & { referrer_username?: string };
type AddressRegistrationData = Omit<Address, 'id' | 'user_id'>;

const RegistrationPage: React.FC = () => {
  const [user, setUser] = useState<Partial<User>>({});

  const { showError } = useError(); // Use the context
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState<UserRegistrationData & AddressRegistrationData & { 
    confirmPassword: string,
    referrer_username?: string 
  }>({
    username: '',
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    confirmPassword: '',
    phone: '',
    address_type: 'SHIPPING',
    street_address: '',
    city: '',
    state_or_province: '',
    postal_code: '',
    country: '',
    type: 0,
    last_login: new Date().toISOString(),
    push_notifications: true,
    referrer_username: '',
  });
  // const [emailOtp, setEmailOtp] = useState('');
  const [phoneOtp, setPhoneOtp] = useState('');
  // const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isPhoneVerified, setIsPhoneVerified] = useState(false);
  // const [emailOtpAttempts, setEmailOtpAttempts] = useState(0);
  const [phoneOtpAttempts, setPhoneOtpAttempts] = useState(0);
  // const [emailTimer, setEmailTimer] = useState(0); // Timer for email OTP
  const [phoneTimer, setPhoneTimer] = useState(0); // Timer for phone OTP
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [isRegistered, setIsRegistered] = useState(false);
  const [userToken, setUserToken] = useState('');
  const [userCredits, setUserCredits] = useState(0);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const refUsername = searchParams.get('ref');
    if (refUsername) {
      setFormData(prev => ({
        ...prev,
        referrer_username: refUsername
      }));
    }
  }, [searchParams]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/account');
    }
  }, [navigate]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    if ( (name === 'phone' && isPhoneVerified)) {
      return; // Prevent changes if already verified
    }
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const validateStep = (currentStep: number): boolean => {
    switch (currentStep) {
      case 1:
        return (
          formData.username !== '' &&
          formData.first_name !== '' &&
          formData.last_name !== '' &&
          formData.password !== '' &&
          formData.confirmPassword !== '' &&
          formData.password === formData.confirmPassword &&
          validatePassword(formData.password)
        );
      case 2:
        return (
          formData.email !== '' &&
          formData.phone !== '' &&
          formData.email.includes('@') && // Basic email format check
          (formData.phone?.length || 0) >= 10     // Basic phone length check
        );
      case 3:
        return (
          phoneOtp !== '' && 
          isPhoneVerified
        );
      case 4:
        return (
          formData.street_address !== '' &&
          formData.city !== '' &&
          formData.state_or_province !== '' &&
          formData.postal_code !== '' &&
          formData.country !== ''
        );
      case 5:
        // Referral step - always valid since referral is optional
        return true;
      default:
        return false;
    }
  };

  const checkExistingFields = async () => {
    try {
      // Only check fields that have values
      const fieldsToCheck: { [key: string]: string } = {};
      if (formData.username) fieldsToCheck.username = formData.username;
      if (formData.email) fieldsToCheck.email = formData.email;
      if (formData.phone) fieldsToCheck.phone = formData.phone;

      const response = await checkUserFields(fieldsToCheck);

      if (response.exists) {
        const fields = response.fields.join(', ');
        showError(`The following field(s) are already taken: ${fields}. Please choose different values.`, 'warning');
        return false;
      }
      return true;
    } catch (err) {
      showError('An error occurred while checking your information. Please try again.');
      return false;
    }
  };

  const verifyOTPs = async () => {
    // if (!isEmailVerified) {
    //   try {
    //     const emailVerified = await verifyOTP(formData.email, emailOtp, 'email');
    //     if (!emailVerified) {
    //       showError('Email OTP verification failed. Please check and try again.'); // Use showError
    //       return false;
    //     }
    //     setIsEmailVerified(true);
    //     setEmailTimer(0); // Reset the timer
    //     setEmailOtpAttempts(0); // Reset attempts
    //   } catch (err) {
    //     showError('An error occurred during email verification. Please try again.'); // Use showError
    //     return false;
    //   }
    // }

    if (!isPhoneVerified) {
      try {
        const phoneVerified = await verifyOTP(formData.phone || '', phoneOtp, 'phone');
        if (!phoneVerified) {
          showError('Phone OTP verification failed. Please check and try again.'); // Use showError
          return false;
        }
        setIsPhoneVerified(true);
        setPhoneTimer(0); // Reset the timer
        setPhoneOtpAttempts(0); // Reset attempts
      } catch (err) {
        showError('An error occurred during phone verification. Please try again.'); // Use showError
        return false;
      }
    }

    return true;
  };

  const handleNextStep = async () => {
    if (validateStep(step)) {
      switch (step) {
        case 1:
          // Check username availability when moving from step 1
          const usernameAvailable = await checkExistingFields();
          if (!usernameAvailable) return;
          break;
          
        case 2:
          // Check email and phone availability when moving from step 2
          const contactFieldsAvailable = await checkExistingFields();
          if (!contactFieldsAvailable) return;
          
          const phoneOtpSent = await sendOTP(formData.phone || '', 'phone');
          if (!phoneOtpSent) {
            showError('Failed to send OTP. Please check your phone number and try again.');
            return;
          }
          setPhoneTimer(60);
          setPhoneOtpAttempts(prev => prev + 1);
          break;
          
        case 3:
          const otpsVerified = await verifyOTPs();
          if (!otpsVerified) return;
          const verifiedFieldsAvailable = await checkExistingFields();
          if (!verifiedFieldsAvailable) return;
          break;
          
        case 4:
          // If there's no referral username and we're on step 4, handle registration
          if (!formData.referrer_username) {
            await handleRegistration();
            return;
          }
          // Otherwise proceed to referral step if referrer exists
          if (!validateStep(4)) {
            showError('Please complete all address fields before proceeding.');
            return;
          }
          break;
          
        case 5:
          // This should be handled by handleSubmit instead
          return;
      }
      setStep((prev) => prev + 1);
    } else {
      showError('Please fill in all required fields correctly before proceeding.');
    }
  };

  const handlePrevStep = () => {
    if (step > 1) setStep((prev) => prev - 1);
  };

  const handleSendOTP = async (type: 'email' | 'phone') => {
    // const attempts = type === 'email' ? emailOtpAttempts : phoneOtpAttempts;
    const attempts = phoneOtpAttempts;
    // const timer = type === 'email' ? emailTimer : phoneTimer;
    const timer = phoneTimer;

    if (attempts >= 3) {
      showError(`You've exceeded the maximum number of OTP requests for ${type}. Please try again later.`); // Use showError
      return false;
    }

    if (timer > 0) {
      showError(`Please wait ${timer} seconds before requesting a new OTP.`); // Use showError
      return false;
    }

    try {
      const contact = type === 'email' ? formData.email : formData.phone;
      await sendOTP(contact || '', type);
      showError(`${type.charAt(0).toUpperCase() + type.slice(1)} OTP sent successfully!`); // Use showError
      if (type === 'phone') {
        setPhoneOtpAttempts(prev => prev + 1);
        setPhoneTimer(60); // Start the 60-second timer
      } else {
          // setEmailOtpAttempts(prev => prev + 1);
        // setEmailTimer(60); // Start the 60-second timer
      }
      return true;
    } catch (err) {
      showError(`Failed to send OTP to ${type}. Please try again.`); // Use showError
      return false;
    }
  };

  // Timer effect for email and phone OTP
  // useEffect(() => {
  //   let timerId: NodeJS.Timeout;
  //   if (emailTimer > 0) {
  //     timerId = setInterval(() => setEmailTimer(prev => prev - 1), 1000);
  //   }
  //   return () => clearInterval(timerId);
  // }, [emailTimer]);

  useEffect(() => {
    let timerId: NodeJS.Timeout;
    if (phoneTimer > 0) {
      timerId = setInterval(() => setPhoneTimer(prev => prev - 1), 1000);
    }
    return () => clearInterval(timerId);
  }, [phoneTimer]);

  const validatePassword = (password: string): boolean => {
    const regex = /^(?=.*[A-Z])(?=.*\d).{8,}$/;
    return regex.test(password);
  };

  const handleRegistration = async () => {
    try {
      const userData: UserRegistrationData = {
        username: formData.username,
        first_name: formData.first_name,
        last_name: formData.last_name,
        email: formData.email,
        phone: formData.phone,
        password: formData.password,
        type: formData.type,
        last_login: formData.last_login,
        push_notifications: true,
        referrer_username: formData.referrer_username
      };

      const addressData: AddressRegistrationData = {
        address_type: formData.address_type,
        street_address: formData.street_address,
        city: formData.city,
        state_or_province: formData.state_or_province,
        postal_code: formData.postal_code,
        country: formData.country,
      };

      const regResponse = await registerUser(userData, addressData);
      setUser({
        ...regResponse.data,
        type: regResponse.data.type as 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | undefined
      });
      showError('Registration successful! Please add credits to your account.', 'info');
      
      const loginResponse = await loginUser(formData.email, formData.password);
      if (loginResponse && loginResponse.data.token) {
        localStorage.setItem('token', loginResponse.data.token);
        navigate('/first-time-offer');
      } else {
        navigate('/login');
      }
    } catch (err) {
      showError('Registration failed. Please try again.');
    }
  };

  const handleCreditsAdded = (newCredits: number) => {
    setUser(prev => ({ ...prev, credits: newCredits }));
    // After credits are added, redirect to the account page or dashboard
    navigate('/account');
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <div className={styles.formStep}>
            <h2 className={styles.formSubtitle}>User Details</h2>
            <div className={styles.formGroup}>
              <label className={styles.formLabel} htmlFor="username">Username</label>
              <input
                className={styles.formInput}
                type="text"
                id="username"
                name="username"
                value={formData.username}
                onChange={handleInputChange}
                placeholder="Choose a username"
                required
              />
            </div>
            <div className={styles.formGroup}>
              <label className={styles.formLabel} htmlFor="first_name">First Name</label>
              <input
                className={styles.formInput}
                type="text"
                id="first_name"
                name="first_name"
                value={formData.first_name}
                onChange={handleInputChange}
                placeholder="Enter your first name"
                required
              />
            </div>
            <div className={styles.formGroup}>
              <label className={styles.formLabel} htmlFor="last_name">Last Name</label>
              <input
                className={styles.formInput}
                type="text"
                id="last_name"
                name="last_name"
                value={formData.last_name}
                onChange={handleInputChange}
                placeholder="Enter your last name"
                required
              />
            </div>

            <div className={styles.formGroup}>
              <label className={styles.formLabel} htmlFor="password">Password</label>
              <input
                className={styles.formInput}
                type="password"
                id="password"
                name="password"
                value={formData.password}
                onChange={(e) => {
                  setFormData({ ...formData, password: e.target.value });
                  if (!validatePassword(e.target.value)) {
                    setPasswordError('Password must be at least 8 characters long, contain 1 uppercase letter, and 1 number');
                  } else {
                    setPasswordError(null);
                  }
                }}
                required
              />
              {passwordError && <p className={styles.errorMessage}>{passwordError}</p>}
            </div>
            <div className={styles.formGroup}>
              <label className={styles.formLabel} htmlFor="confirmPassword">Confirm Password</label>
              <input
                className={styles.formInput}
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleInputChange}
                placeholder="Confirm your password"
                required
              />
            </div>
          </div>
        );
      case 2: // New case for contact details
        return (
          <div className={styles.formStep}>
            <h2 className={styles.formSubtitle}>Contact Details</h2>
            {/* <p className={styles.helpText}>Please enter your email and phone number. <br /> You will receive an OTP to verify your phone number.</p><br /> */}
            <div className={styles.formGroup}>
              <label className={styles.formLabel} htmlFor="email">Email</label>
              <input
                className={styles.formInput}
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="Enter your email"
                required
              />
            </div>
            <div className={styles.formGroup}>
              <label className={styles.formLabel} htmlFor="phone">Phone Number</label>
              <input
                className={styles.formInput}
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                placeholder="Enter your phone number"
                required
              />
            </div>
          </div>
        );
      case 3:
        return (
          <div className={styles.formStep}>
            <h2 className={styles.formSubtitle}>Verification</h2>
            {/* <p className={styles.helpText}>Enter the OTP sent to your phone number.</p> */}
            
            {/* <div className={styles.verificationGroup}>
              <input
                className={styles.formInput}
                type="text"
                id="emailOtp"
                value={emailOtp}
                onChange={(e) => setEmailOtp(e.target.value)}
                placeholder="Enter Email OTP"
                required
                disabled={isEmailVerified}
              />
              <button 
                type="button" 
                className={`${styles.resendButton} ${isEmailVerified ? styles.verified : ''}`}
                onClick={() => handleSendOTP('email')} 
                disabled={isEmailVerified || emailTimer > 0}
              >
                {isEmailVerified ? '✓ Verified' : emailTimer > 0 ? `Wait ${emailTimer}s` : 'Resend OTP'}
              </button>
            </div> */}

            <div className={styles.verificationGroup}>
              <input
                className={styles.formInput}
                type="text"
                id="phoneOtp"
                value={phoneOtp}
                onChange={(e) => setPhoneOtp(e.target.value)}
                placeholder="Enter Phone OTP"
                required
                disabled={isPhoneVerified}
              />
              <button 
                type="button"
                className={`${styles.resendButton} ${isPhoneVerified ? styles.verified : ''}`}
                onClick={() => handleSendOTP('phone')} 
                disabled={isPhoneVerified || phoneTimer > 0}
              >
                {isPhoneVerified ? '✓ Verified' : phoneTimer > 0 ? `Wait ${phoneTimer}s` : 'Resend OTP'}
              </button>
            </div>
          </div>
        );
      case 4:
        return (
          <div className={styles.formStep}>
            <h2 className={styles.formSubtitle}>Shipping Address</h2>
            <div className={styles.formGroup}>
              <label className={styles.formLabel} htmlFor="street_address">Street Address</label>
              <input
                className={styles.formInput}
                type="text"
                id="street_address"
                name="street_address"
                value={formData.street_address}
                onChange={handleInputChange}
                placeholder="Enter your street address"
                required
              />
            </div>
            <div className={styles.formGroup}>
              <label className={styles.formLabel} htmlFor="city">City</label>
              <input
                className={styles.formInput}
                type="text"
                id="city"
                name="city"
                value={formData.city}
                onChange={handleInputChange}
                placeholder="Enter your city"
                required
              />
            </div>
            <div className={styles.formGroup}>
              <label className={styles.formLabel} htmlFor="state_or_province">State/Province</label>
              <input
                className={styles.formInput}
                type="text"
                id="state_or_province"
                name="state_or_province"
                value={formData.state_or_province}
                onChange={handleInputChange}
                placeholder="Enter your state or province"
              />
            </div>
            <div className={styles.formGroup}>
              <label className={styles.formLabel} htmlFor="postal_code">Postal Code</label>
              <input
                className={styles.formInput}
                type="text"
                id="postal_code"
                name="postal_code"
                value={formData.postal_code}
                onChange={handleInputChange}
                placeholder="Enter your postal code"
              />
            </div>
            <div className={styles.formGroup}>
              <label className={styles.formLabel} htmlFor="country">Country</label>
              <input
                className={styles.formInput}
                type="text"
                id="country"
                name="country"
                value={formData.country}
                onChange={handleInputChange}
                placeholder="Enter your country"
                required
              />
            </div>
          </div>
        );
      case 5:
        // Only show step 5 if there's a referrer_username
        return formData.referrer_username ? (
          <div className={styles.formStep}>
            <div className={styles.referralSection}>
              <h2 className={styles.formSubtitle}>Referral Program</h2>
              <div className={styles.referralInfo}>
                <p>You were referred by {formData.referrer_username}! <br /> Both you and your referrer will receive 5 free credits!</p>
              </div>
              
              <div className={styles.finalStepInfo}>
                <h3>What's Next?</h3>
                <p>After completing your registration, you'll be taken to our exclusive new user special offer page! </p>
                <p>Then get ready to start bidding!!!</p>
              </div>
              <br />
            </div>
          </div>
        ) : null;
      default:
        return null;
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (step === 6) {
      handleRegistration();
    } else {
      handleNextStep();
    }
  };

  if (isRegistered) {
    return (
      <div className={styles.registrationContainer}>
        <h1 className={styles.registrationTitle}>Registration Successful!</h1>
        <p>Congratulations! Your account has been created successfully.</p>
        <CreditForm
          token={userToken}
          currentCredits={user.credits || 0}
          onCreditsAdded={handleCreditsAdded}
        />
        <button
          onClick={() => navigate('/account')}
          className={`${styles.button} ${styles.buttonSubmit}`}
        >
          Go to Account
        </button>
      </div>
    );
  }

  return (
    <div className={styles.registrationContainer}>
      <h1 className={styles.registrationTitle}>Create your account</h1>
      <form onSubmit={handleSubmit}>
        <div className={styles.stepIndicators}>
          {[1, 2, 3, 4, 5].map((i) => (
            <div
              key={i}
              className={`${styles.stepIndicator} ${step === i ? styles.active : ''} ${step > i ? styles.completed : ''}`}
            >
              {step > i ? '✓' : i}
            </div>
          ))}
        </div>
        {renderStep()}
        <div className={styles.formNavigation}>
          {step > 1 && (
            <button type="button" onClick={handlePrevStep} className={`${styles.button} ${styles.buttonPrev}`}>
              Previous
            </button>
          )}
          {step === 3 ? (
            <button
              type="button"
              className={`${styles.button} ${styles.buttonNext}`}
              onClick={async () => {
                const otpsVerified = await verifyOTPs();
                if (otpsVerified) {
                  setStep((prev) => prev + 1);
                }
              }}
            >
              Verify & Continue
            </button>
          ) : step < 5 ? (
            <button type="button" onClick={handleNextStep} className={`${styles.button} ${styles.buttonNext}`}>
              Next
            </button>
          ) : (
            <button type="submit" onClick={handleRegistration} className={`${styles.button} ${styles.buttonSubmit}`}>
              Complete Registration
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default RegistrationPage;
