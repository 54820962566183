import React, { useState, useEffect } from 'react';
import { Address } from '../../../../types';
import { updateAddressDetails, getAddressDetails } from '../../utils/accountAPI';
import styles from './AddressForm.module.css';

interface AddressFormProps {
    token: string;
    type: 'SHIPPING' | 'BILLING';
}

const AddressForm: React.FC<AddressFormProps> = ({ token, type }) => {
    const [address, setAddress] = useState<Partial<Address>>({ address_type: type });
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchAddress();
    }, [token, type]);

    const fetchAddress = async () => {
        setLoading(true);
        try {
            const response = await getAddressDetails(token);
            const reversedData = [...response.data].reverse();
            const foundAddress = reversedData.find(addr => addr.address_type === type);

            if (foundAddress) {
                setAddress({ ...foundAddress, address_type: type });
            } else {
                setAddress({ address_type: type });
            }
            setError('');
            setSuccessMessage('');
        } catch (err) {
            setError(`Failed to fetch ${type.toLowerCase()} address`);
            setSuccessMessage('');
        } finally {
            setLoading(false);
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setAddress(prevAddress => ({
            ...prevAddress,
            [name]: value,
        }));
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setError('');
        setSuccessMessage('');
        setLoading(true);

        try {
            const updatedAddress = { ...address, address_type: type };
            await updateAddressDetails(token, updatedAddress);
            setSuccessMessage(`${type} address updated successfully`);
            setError('');
        } catch (err) {
            setError(`Failed to update ${type.toLowerCase()} address`);
            setSuccessMessage('');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className={styles.formContainer}>
            <h2 className={styles.formTitle}>{type} ADDRESS</h2>
            {loading && <p className={styles.loadingMessage}>Loading...</p>}
            {successMessage && <p className={styles.successMessage}>{successMessage}</p>}
            {error && <p className={styles.errorMessage}>{error}</p>}
            <form onSubmit={handleSubmit} className={styles.form}>
                <input type="hidden" name="address_type" value={type} />
                <div className={styles.formGroup}>
                    <label htmlFor="street_address">Street Address:</label>
                    <input
                        type="text"
                        id="street_address"
                        name="street_address"
                        value={address.street_address || ''}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className={styles.formGroup}>
                    <label htmlFor="city">City:</label>
                    <input
                        type="text"
                        id="city"
                        name="city"
                        value={address.city || ''}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className={styles.formGroup}>
                    <label htmlFor="state_or_province">State/Province:</label>
                    <input
                        type="text"
                        id="state_or_province"
                        name="state_or_province"
                        value={address.state_or_province || ''}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className={styles.formGroup}>
                    <label htmlFor="postal_code">Postal Code:</label>
                    <input
                        type="text"
                        id="postal_code"
                        name="postal_code"
                        value={address.postal_code || ''}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className={styles.formGroup}>
                    <label htmlFor="country">Country:</label>
                    <input
                        type="text"
                        id="country"
                        name="country"
                        value={address.country || ''}
                        onChange={handleChange}
                        required
                    />
                </div>
                <button type="submit" className={styles.submitButton} disabled={loading}>
                    {loading ? 'Updating...' : `Update  Address`}
                </button>
            </form>
        </div>
    );
};

export default AddressForm;