import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAccountDetails } from '../../utils/accountAPI';
import CreditsSection from '../components/CreditForm';
import styles from './FirstTimeOfferPage.module.css';

const FirstTimeOfferPage: React.FC = () => {
  const [user, setUser] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
      return;
    }

    const fetchUserData = async () => {
      try {
        const response = await getAccountDetails(token);
        setUser(response.data);
      } catch (err) {
        setError('Failed to fetch user data');
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [navigate]);

  const handleCreditsAdded = (newCredits: number) => {
    setUser((prev: any) => ({ ...prev, credits: newCredits }));
    navigate('/');
  };

  const handlePurchase = (credits: number) => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
      return;
    }
    // This will open the CreditsSection with the pre-selected amount
    setUser((prev: any) => ({ ...prev, selectedCredits: credits }));
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className={styles.firstTimeOfferPage}>
      <h1 className={styles.welcomeTitle}>Welcome to UShop.bid, {user?.first_name}!</h1>
      <p className={styles.offerDescription}>As a new user, we're offering you special deals on your first credit purchase:</p>
      
      <div className={styles.offersContainer}>
        <div className={styles.offerCard}>
          <h2 className={styles.offerTitle}>Starter Pack</h2>
          <p className={styles.offerDetails}>5 credits for £1</p>
          <button 
            className={styles.purchaseButton} 
            onClick={() => handlePurchase(5)}
          >
            Buy Now
          </button>
        </div>
        
        <div className={styles.offerCard}>
          <h2 className={styles.offerTitle}>Value Pack</h2>
          <p className={styles.offerDetails}>25 credits for £10</p>
          <button 
            className={styles.purchaseButton} 
            onClick={() => handlePurchase(25)}
          >
            Buy Now
          </button>
        </div>
      </div>
      
      <p className={styles.orDivider}>Or choose your own amount:</p>

      <CreditsSection
        token={localStorage.getItem('token') || ''}
        currentCredits={user?.credits || 0}
        onCreditsAdded={handleCreditsAdded}
        initialSelectedCredits={user?.selectedCredits}
      />
      
      <button onClick={() => navigate('/account')} className={styles.skipButton}>
        Skip for now
      </button>
    </div>
  );
};

export default FirstTimeOfferPage;
