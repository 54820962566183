import React, { useEffect, useState } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { getUserBiddingHistory } from '../../utils/accountAPI';
import { Bid, Auction, Product } from '../../../../types';
import { getAuctionById, getProductById, getBids } from '../../utils/coreAPI';
import styles from './BiddingHistory.module.css';
import { getProductImage } from '../../utils/imageHelpers';
import Loading from '../../components/loading';

interface BiddingHistoryProps {
  token: string;
}

interface EnrichedBid extends Bid {
  auction: Auction;
  product: Product;
  bidCount: number;
  productImageUrl?: string;
}

const BIDDING_HISTORY_HEADERS = ['Bid ID', 'Product', 'Bids', 'Status', 'Date'];

const BiddingHistory: React.FC<BiddingHistoryProps> = ({ token }) => {
  const [bids, setBids] = useState<EnrichedBid[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const bidsPerPage = 5;

  useEffect(() => {
    fetchBiddingHistory();
  }, [token]);

  const countPreviousBids = (allBids: Bid[], currentBid: Bid): number => {
    return allBids.filter(bid =>
      bid.auction_id === currentBid.auction_id && bid.bid_time <= currentBid.bid_time
    ).length;
  };

  const fetchBiddingHistory = async () => {
    setLoading(true);
    setError('');
    try {
      const [userBids, allBids] = await Promise.all([
        getUserBiddingHistory(token),
        getBids()
      ]);

      const enrichedBids = await Promise.all(userBids.map(async (bid) => {
        const auction = await getAuctionById(bid.auction_id);
        const product = await getProductById(auction.data.product_id);
        const bidCount = countPreviousBids(allBids.data, bid);
        const productImageUrl = getProductImage(product.data);
        
        return {
          ...bid,
          auction: auction.data,
          product: product.data,
          bidCount,
          productImageUrl,
        };
      }));
      setBids(enrichedBids);
    } catch (err: any) {
      setError('Failed to fetch bidding history.');
    } finally {
      setLoading(false);
    }
  };

  const totalPages = Math.ceil(bids.length / bidsPerPage);
  const currentBids = bids.slice((currentPage - 1) * bidsPerPage, currentPage * bidsPerPage);

  const handlePrevPage = () => {
    setCurrentPage(prev => Math.max(prev - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage(prev => Math.min(prev + 1, totalPages));
  };

  const handlePageClick = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.header}>Bidding History</h2>
      
      {loading ? (
        <Loading 
          message="Loading your bidding history..." 
          subMessage="Fetching your latest bids" 
        />
      ) : error ? (
        <div className={styles.error}>{error}</div>
      ) : bids.length === 0 ? (
        <div className="text-gray-600 text-center p-8">No bidding history available.</div>
      ) : (
        <>
          <div className="overflow-x-auto">
            <table className={styles.table}>
              <thead>
                <tr>
                  {BIDDING_HISTORY_HEADERS.map((header) => (
                    <th key={header} className="px-6 py-3 text-white text-sm font-medium text-center">
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {currentBids.map((bid) => (
                  <tr key={bid.id} className="hover:bg-gray-50 transition-colors">
                    <td className="px-6 py-4 text-center">{bid.id}</td>
                    <td className="px-6 py-4">
                      <div className="flex items-center justify-center space-x-3">
                        {bid.productImageUrl && (
                          <img
                            src={bid.productImageUrl}
                            alt={bid.product.title}
                            className="w-12 h-12 object-cover rounded-md"
                          />
                        )}
                        <span className="text-sm font-medium text-gray-900">{bid.product.title}</span>
                      </div>
                    </td>
                    <td className="px-6 py-4 text-center">{bid.bidCount}</td>
                    <td className="px-6 py-4">
                      <span className={`${styles.status} ${bid.auction.status === 'active' ? styles.active : 
                        bid.auction.status === 'completed' ? styles.completed : 
                        styles.default}`}>
                        {bid.auction.status}
                      </span>
                    </td>
                    <td className="px-6 py-4 text-center text-sm text-gray-500">
                      {new Date(bid.bid_time).toLocaleString()}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {totalPages > 1 && (
            <div className={styles.pagination}>
              <button
                onClick={handlePrevPage}
                disabled={currentPage === 1}
                className={styles.navButton}
                aria-label="Previous page"
              >
                <ChevronLeft className="w-4 h-4" />
              </button>
              
              {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
                <button
                  key={page}
                  onClick={() => handlePageClick(page)}
                  className={`${styles.paginationButton} ${
                    currentPage === page ? styles.active : ''
                  }`}
                  aria-label={`Page ${page}`}
                  aria-current={currentPage === page ? 'page' : undefined}
                >
                  {page}
                </button>
              ))}

              <button
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
                className={styles.navButton}
                aria-label="Next page"
              >
                <ChevronRight className="w-4 h-4" />
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default BiddingHistory;