// src/pages/HomePage.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import { sendLockScreenNotificationToAllDevices } from '../../utils/pushNotifications'; // Import the function

const HomePage: React.FC = () => {
  const links = [
    { path: '/admin/users', label: 'Manage Users' },
    { path: '/admin/products', label: 'Manage Products' },
    { path: '/admin/categories', label: 'Manage Categories' },
    { path: '/admin/auctions', label: 'Manage Auctions' },
    { path: '/admin/bids', label: 'Manage Bids' },
    { path: '/admin/invoices', label: 'Manage Invoices' },
    { path: '/admin/reviews', label: 'Manage Reviews' },
    { path: '/admin/notifications', label: 'Manage Notifications' },
    { path: '/admin/media', label: 'Manage Media' },
    { path: '/gov', label: 'Governor' },
  ];

  // Sort links alphabetically by label
  const sortedLinks = links.sort((a, b) => a.label.localeCompare(b.label));

  const handleSendTestNotification = async () => {
    const notificationData = {
      title: 'Test Notification',
      body: 'This is a test lock screen notification.',
      icon: 'path/to/icon.png', // Optional: specify an icon
      badge: 'path/to/badge.png', // Optional: specify a badge
    };

    try {
      await sendLockScreenNotificationToAllDevices(notificationData);
      alert('Test notification sent successfully!');
    } catch (error) {
      console.error('Error sending test notification:', error);
      alert('Failed to send test notification.');
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', padding: '20px' }}>
      <h1>Welcome to the Admin Panel</h1>
      <p>Manage your auctions, users, products, and more from this dashboard.</p>
      <button onClick={handleSendTestNotification} style={styles.button}>
        Send Test Lock Screen Notification
      </button>
      {sortedLinks.map((link) => (
        <Link key={link.path} to={link.path}>
          <button style={styles.button}>{link.label}</button>
        </Link>
      ))}
    </div>
  );
};

const styles = {
  button: {
    padding: '10px 20px',
    fontSize: '16px',
    cursor: 'pointer',
    backgroundColor: '#4CAF50',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
  },
};

export default HomePage;
