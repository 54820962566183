import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Check, X, User, LogOut, Coins } from 'lucide-react';
import { getUserNotifs, readNotification, deleteNotification, getAccountDetails, updateAccountDetails } from '../utils/accountAPI';
import { Notification, User as UserType } from '../../../types';
import io from 'socket.io-client';
import styles from './NavBar.module.css';
import { subscribeToPushNotifications } from '../utils/pushNotifications';

// Add this helper function at the top
const isPWA = () => {
    return window.matchMedia('(display-mode: standalone)').matches ||
           // window.navigator.standalone is only available on iOS Safari
           ('standalone' in window.navigator && window.navigator.standalone) ||
           document.referrer.includes('android-app://');
};

// Modify the checkTokenExpiration function to return the expiration time
function checkTokenExpiration(token: string): { isExpired: boolean; expirationTime: number } {
    try {
        const payload = JSON.parse(atob(token.split('.')[1]));
        const expirationTime = payload.exp * 1000; // Convert to milliseconds
        return { 
            isExpired: Date.now() >= expirationTime,
            expirationTime: expirationTime
        };
    } catch (error) {
        console.error('Error checking token expiration:', error);
        return { isExpired: true, expirationTime: 0 }; // Assume token is expired if there's an error
    }
}

const Navbar: React.FC = () => {
    const [isNotificationDrawerOpen, setIsNotificationDrawerOpen] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [notifications, setNotifications] = useState<Notification[]>([]);
    const [newNotifications, setNewNotifications] = useState<Notification[]>([]);
    const [notificationFilter, setNotificationFilter] = useState<'all' | 'read' | 'unread'>('all');
    const [unreadCount, setUnreadCount] = useState(0);
    const [user, setUser] = useState<Partial<UserType>>({});
    const [credits, setCredits] = useState(0);
    const [isUpdating, setIsUpdating] = useState(false);
    const navigate = useNavigate();
    const token = localStorage.getItem('token');
    const { pathname } = useLocation();
    const [isScrolled, setIsScrolled] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [menuHeight, setMenuHeight] = useState(0);
    const navbarRef = useRef<HTMLDivElement>(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    // Socket initialization
    useEffect(() => {
        const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:4000';
        const socketUrl = apiUrl.endsWith('/api') ? apiUrl.slice(0, -4) : apiUrl;
        const socket = io(socketUrl);
        if (user.id) {
            socket.emit('authenticate', user.id);
        }

        const handleNewNotification = (newNotification: Notification) => {
            setNewNotifications(prev => [newNotification, ...prev].slice(0, 5));
            setNotifications(prev => [newNotification, ...prev]);
            setUnreadCount(prevCount => prevCount + 1);

            // Check if the notification is about credit addition
            const creditAddedMatch = newNotification.message.match(/(\d+) credits added to account!/);
            if (creditAddedMatch) {
                const addedCredits = parseInt(creditAddedMatch[1], 10);
                setCredits(prevCredits => {
                    setIsUpdating(true);
                    setTimeout(() => setIsUpdating(false), 500);
                    return prevCredits + addedCredits;
                });
            }
        };

        const handleCreditUpdate = (data: any) => {
            if (data.username === user.username) {
                setCredits(prevCredits => prevCredits - 1);
            }
        };

        socket.on('newNotification', handleNewNotification);
        socket.on('bidPlaced', handleCreditUpdate);

        return () => {
            socket.off('newNotification', handleNewNotification);
            socket.off('bidPlaced', handleCreditUpdate);
            socket.disconnect();
        };
    }, [user.id]);

    // Fetch account details and notifications
    useEffect(() => {
        const fetchData = async () => {
            setIsLoggedIn(!!token);
            if (token) {
                try {
                    await fetchAcc(token);
                    await fetchNotifications(token);
                } catch (error) {
                    console.error('Failed to fetch user data', error);
                    setIsLoggedIn(false);
                    localStorage.removeItem('token');
                }
            }
        };
        fetchData();
    }, [token]);

    useEffect(() => {
        const count = notifications.filter(notification => !notification.is_read).length;
        setUnreadCount(count);
    }, [notifications]);

    const fetchAcc = useCallback(async (token: string) => {
        try {
            const response = await getAccountDetails(token);
            setUser(response.data);
            setCredits(response.data.credits || 0);
        } catch (error) {
            console.error('Failed to fetch account details', error);
            throw error; // Propagate the error
        }
    }, []);

    const fetchNotifications = useCallback(async (token: string) => {
        try {
            const response = await getUserNotifs(token);
            setNotifications(response);
        } catch (error) {
            console.error('Failed to fetch notifications', error);
        }
    }, []);
    
    // Modify the handleLogout function
    const handleLogout = useCallback(async () => {
        localStorage.removeItem('token');
        setIsLoggedIn(false);
        setUser({});
        setCredits(0);
        navigate('/');
    }, [navigate]);

    const toggleNotificationDrawer = useCallback(() => {
        setIsNotificationDrawerOpen(prevState => !prevState);
    }, []);

    // Add this new effect to check token expiration and set up auto-logout
    useEffect(() => {
        const checkTokenStatus = () => {
            if (token) {
                const { isExpired, expirationTime } = checkTokenExpiration(token);
                if (isExpired) {
                    handleLogout();
                } else {
                    const timeUntilExpiration = expirationTime - Date.now();
                    
                    // Only set 1-hour timeout for non-PWA
                    if (!isPWA()) {
                        const oneHour = 60 * 60 * 1000;
                        const timeUntilAutoLogout = Math.min(timeUntilExpiration, oneHour);
                        setTimeout(() => {
                            handleLogout();
                        }, timeUntilAutoLogout);
                    }
                }
            }
        };

        checkTokenStatus();
    }, [token, handleLogout]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const handleMarkAsRead = useCallback(async (token: string, notificationId: number) => {
        try {
            await readNotification(token, notificationId);
            setNotifications(prevNotifications =>
                prevNotifications.map(notification =>
                    notification.id === notificationId
                        ? { ...notification, is_read: true }
                        : notification
                )
            );
            setUnreadCount(prevCount => prevCount - 1);
            setNewNotifications(prevNotifications =>
                prevNotifications.filter(notification => notification.id !== notificationId)
            );
        } catch (error) {
            console.error('Failed to mark notification as read', error);
        }
    }, []);

    const handleDeleteNotification = useCallback(async (token: string, notificationId: number) => {
        try {
            await deleteNotification(token, notificationId);
            setNotifications(prevNotifications =>
                prevNotifications.filter(notification => notification.id !== notificationId)
            );
            if (!notifications.find(n => n.id === notificationId)?.is_read) {
                setUnreadCount(prevCount => prevCount - 1);
            }
            setNewNotifications(prevNotifications =>
                prevNotifications.filter(notification => notification.id !== notificationId)
            );
        } catch (error) {
            console.error('Failed to delete notification', error);
        }
    }, [notifications]);

    const handleDismissPopup = useCallback(async (notificationId: number) => {
        await handleMarkAsRead(token as string, notificationId);
    }, [handleMarkAsRead, token]);

    const filteredNotifications = useMemo(() => {
        return notifications.filter(notification => {
            if (notificationFilter === 'all') return true;
            if (notificationFilter === 'read') return notification.is_read;
            if (notificationFilter === 'unread') return !notification.is_read;
            return true;
        });
    }, [notifications, notificationFilter]);

    const publicMenuItems = [
        { name: 'Home', path: '/' },
        { name: 'Categories', path: '/categories' },
        { name: 'Upcoming', path: '/upcoming' },
        { name: 'Winners', path: '/winners' },
        { name: 'Market', path: '/market' },
        { name: 'Charity', path: '/charity' },
        { name: 'FAQ', path: '/faq' },
        { name: 'Contact', path: '/contact' },
    ];

    const adminMenuItems = [
        { name: 'Admin', path: '/admin' },
        // Add more admin menu items here as needed
    ];

    // Combine menu items based on user role
    const menuItems = useMemo(() => {
        if (user.type == 0) {
            return [...publicMenuItems, ...adminMenuItems];
        }
        return publicMenuItems;
    }, [user.type]);

    const navigateToCredits = () => {
        navigate('/account', { state: { section: 'credits' } });
    };

    useEffect(() => {
        if (user.id) {
            subscribeToPushNotifications();
        }
    }, [user.id]);

    useEffect(() => {
        const handleScroll = () => {
            // Get the first element with a class that includes 'topBanner'
            const topBannerEl = document.querySelector('[class*="topBanner"]');
            const topBannerHeight = topBannerEl?.clientHeight || 0;
            setIsScrolled(window.scrollY > topBannerHeight+25);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    // Update document padding when menu opens/closes
    useEffect(() => {
        if (navbarRef.current) {
            const height = isMenuOpen ? navbarRef.current.scrollHeight : 0;
            setMenuHeight(height);
            document.body.style.paddingTop = `${64 + height}px`; // 64px is approx header height
        }
    }, [isMenuOpen]);

    // Handle window resize
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Close menu on route change only for mobile
    useEffect(() => {
        if (isMobile) {
            setIsMenuOpen(false);
        }
    }, [pathname, isMobile]);

    // Store menu state in localStorage only for desktop
    useEffect(() => {
        if (!isMobile) {
            const storedMenuState = localStorage.getItem('menuState');
            if (storedMenuState) {
                setIsMenuOpen(storedMenuState === 'open');
            }
        }
    }, [isMobile]);

    const toggleMenu = useCallback(() => {
        const newState = !isMenuOpen;
        setIsMenuOpen(newState);
        if (!isMobile) {
            localStorage.setItem('menuState', newState ? 'open' : 'closed');
        }
    }, [isMenuOpen, isMobile]);

    return (
        <header className={styles.header}>
            <div className={styles.topBanner}>
                <div className={styles.leftSection}>
                    <button onClick={toggleNotificationDrawer} className={styles.logoButton}>
                        <img src='https://ushop.bid/uploads/logo.png' alt="UShop Logo" className={styles.logo} />
                        {unreadCount > 0 && <span className={styles.notificationCounter}>{unreadCount}</span>}
                    </button>
                    <h1 className={styles.title}>USHOP.BID</h1>
                </div>

                <div className={styles.rightSection}>
                    {isLoggedIn ? (
                        <>
                            <div className={styles.creditsContainer} onClick={navigateToCredits}>
                                <Coins size={20} />
                                <span className={`${styles.creditsCount} ${isUpdating ? styles.updating : ''}`}>
                                    {credits}
                                </span>
                            </div>
                            <div className={styles.authButtons}>
                                <Link to="/account" className={styles.accountButton}>
                                    <User size={20} />
                                    <span className={styles.buttonText}>Account</span>
                                </Link>
                                <button onClick={handleLogout} className={styles.logoutButton}>
                                    <LogOut size={20} />
                                    <span className={styles.buttonText}>Logout</span>
                                </button>
                            </div>
                        </>
                    ) : (
                        <div className={styles.authButtons}>
                            <Link to="/register" className={styles.registerButton}>
                                <span className={styles.buttonText}>Register</span>
                            </Link>
                            <Link to="/login" className={styles.loginButton}>
                                <User size={20} />
                                <span className={styles.buttonText}>Login</span>
                            </Link>
                        </div>
                    )}
                    <button 
                        className={`${styles.menuToggle} ${isMenuOpen ? styles.active : ''}`}
                        onClick={toggleMenu}
                        aria-label="Toggle menu"
                    >
                        <span></span>
                        <span></span>
                        <span></span>
                    </button>
                </div>
            </div>

            <nav 
                ref={navbarRef}
                className={`${styles.navbar} ${isMenuOpen ? styles.open : ''}`}
                style={{ '--menu-height': `${menuHeight}px` } as React.CSSProperties}
            >
                <ul className={styles.navList}>
                    {menuItems.map((item) => (
                        <li key={item.path} className={styles.navItem}>
                            <Link 
                                to={item.path} 
                                className={styles.navLink}
                            >
                                {item.name}
                            </Link>
                        </li>
                    ))}
                    {/* Add register link to mobile menu when not logged in */}
                    {!isLoggedIn && (
                        <li className={`${styles.navItem} ${styles.mobileRegister}`}>
                            <Link to="/register" className={styles.navLink}>
                                Register
                            </Link>
                        </li>
                    )}
                </ul>
            </nav>

            <div className={`${styles.notificationDrawer} ${isNotificationDrawerOpen ? styles.open : ''}`}>
                <button onClick={toggleNotificationDrawer} className={styles.closeButton}>
                    <X size={34} />
                </button>
                <h2 className={styles.notificationTitle}>Notifications</h2>
                <div className={styles.filterButtons}>
                    {['all', 'read', 'unread'].map((filter) => (
                        <button
                            key={filter}
                            onClick={() => setNotificationFilter(filter as 'all' | 'read' | 'unread')}
                            className={`${styles.filterButton} ${notificationFilter === filter ? styles.active : ''}`}
                        >
                            {filter.charAt(0).toUpperCase() + filter.slice(1)}
                        </button>
                    ))}
                </div>

                {filteredNotifications.length === 0 ? (
                    <p>No notifications</p>
                ) : (
                    <ul className={styles.notificationList}>
                        {filteredNotifications.map((notification) => (
                            <li key={notification.id} className={styles.notificationItem}>
                                <div className={styles.notificationContent}>
                                    <p className={styles.notificationMessage}>{notification.message}</p>
                                    <small className={styles.notificationTime}>
                                        {new Date(notification.created_at).toLocaleString()}
                                    </small>
                                </div>
                                <div className={styles.notificationActions}>
                                    {!notification.is_read && (
                                        <button
                                            onClick={() => handleMarkAsRead(token as string, notification.id)}
                                            className={styles.actionButton}
                                            title="Mark as read"
                                        >
                                            <Check size={16} />
                                        </button>
                                    )}
                                    <button
                                        onClick={() => handleDeleteNotification(token as string, notification.id)}
                                        className={styles.actionButton}
                                        title="Delete notification"
                                    >
                                        <X size={16} />
                                    </button>
                                </div>
                            </li>
                        ))}
                    </ul>
                )}
            </div>

            {isNotificationDrawerOpen && <div className={styles.overlay} onClick={toggleNotificationDrawer} />}

            <div className={styles.newNotificationsContainer}>
                {newNotifications.map((notification, index) => (
                    <div
                        key={notification.id}
                        className={styles.newNotification}
                        style={{ top: `${20 + index * 80}px` }}
                    >
                        <p className={styles.newNotificationMessage}>{notification.message}</p>
                        <button
                            onClick={() => handleDismissPopup(notification.id)}
                            className={styles.dismissButton}
                            title="Dismiss"
                        >
                            <X size={16} />
                        </button>
                    </div>
                ))}
            </div>
        </header>
    );
};

export default Navbar;
