import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';

interface PolicySection {
  title: string;
  content: React.ReactNode[];
}

const policyData: PolicySection[] = [
  {
    title: "Terms of Service",
    content: [
      <>
        <h3>1. Platform Nature and Acceptance</h3>
        <ul>
          <li>UShop.bid is a skill-based auction platform where users can bid on retail products.</li>
          <li>Each bid costs 50p (1 credit) and increases the auction price by £0.01.</li>
          <li>This is not a gambling service; outcomes depend on user actions, strategy, and timing.</li>
          <li>UShop.bid is strictly for users aged 18 and over.</li>
          <li>We reserve the right to terminate accounts found to belong to underage users and refund any unused credits.</li>
          <li>By using our services, you confirm that you are at least 18 years old and legally able to enter into contracts.</li>
          <li>You acknowledge that purchasing credits does not guarantee winning any auction.</li>
        </ul>
      </>,
      <>
        <h3>2. User Accounts and Responsibilities</h3>
        <ul>
          <li>Users must provide accurate, current, and complete account information.</li>
          <li>Account sharing or creating multiple accounts is prohibited.</li>
          <li>Users are responsible for maintaining account security.</li>
          <li>We monitor for suspicious activity and reserve the right to suspend accounts for violations.</li>
          <li>Automated bidding or using bots is strictly prohibited and may result in account suspension.</li>
        </ul>
      </>,
      <>
        <h3>3. Bidding System and Rules</h3>
        <ul>
          <li>Each bid requires one credit (which must be purchased in advance).</li>
          <li>Each bid increases the auction price by £0.01 and extends the countdown timer.</li>
          <li>Bids are non-refundable once placed.</li>
          <li>The last bidder when the auction timer expires wins the right to purchase the item at the final auction price.</li>
          <li>Winners must complete the purchase within 24 hours.</li>
          <li>We reserve the right to cancel auctions due to technical issues or errors.</li>
        </ul>
      </>,
      <>
        <h3>4. Credits and Payments</h3>
        <ul>
          <li>Credits must be purchased before bidding and are non-refundable once used.</li>
          <li>Unused credits may be refunded within 14 days as part of the cooling-off period under UK Consumer Law.</li>
          <li>Credits have no monetary value outside the platform and cannot be transferred between users.</li>
          <li>All prices are inclusive of VAT where applicable.</li>
        </ul>
      </>,
      <>
        <h3>5. Consumer Rights and Protections</h3>
        <ul>
          <li>UK statutory rights under the Consumer Rights Act 2015 apply to items won in auctions.</li>
          <li>Users have the right to return faulty goods within 30 days for a full refund, including shipping costs.</li>
          <li>Items are presumed to be faulty if they are defective within six months of receipt.</li>
          <li>Distance Selling Regulations apply to auctions won, meaning users can cancel within 14 days of receiving the product.</li>
        </ul>
      </>,
      <>
        <h3>6. Limitation of Liability and Disclaimers</h3>
        <ul>
          <li>We do not guarantee the continuous availability of the platform or error-free operation.</li>
          <li>We are not responsible for technical issues, delays, or errors that prevent participation in auctions.</li>
          <li>Our liability is limited to the amount of credits purchased by the user.</li>
        </ul>
      </>
    ]
  },
  {
    title: "Privacy Policy",
    content: [
      <>
        <h3>1. Data Collection and GDPR Compliance</h3>
        <ul>
          <li>We collect personal data including name, email, phone number, and payment details for account creation and transaction processing.</li>
          <li>All payment data is securely processed by third-party payment providers in compliance with PCI DSS standards.</li>
          <li>We collect usage data, such as bidding history and device information, for platform improvement and fraud prevention.</li>
          <li>Our legal bases for processing your data under GDPR include contract performance, legitimate interests, legal obligations, and consent where required.</li>
        </ul>
      </>,
      <>
        <h3>2. Data Usage and Protection</h3>
        <ul>
          <li>Your data is used for account management, transaction processing, service improvement, and legal compliance.</li>
          <li>We employ encryption in transit and at rest, access control mechanisms, regular security audits, and staff training to ensure data security.</li>
        </ul>
      </>,
      <>
        <h3>3. User Rights and Controls</h3>
        <ul>
          <li>Under GDPR, you have the right to access, correct, or delete your personal data, as well as the right to data portability and to withdraw consent where applicable.</li>
          <li>Users can manage their cookie preferences and opt out of marketing communications through their account settings.</li>
        </ul>
      </>
    ]
  },
  {
    title: "Refund and Returns Policy",
    content: [
      <>
        <h3>1. Credit Purchases</h3>
        <ul>
          <li>Credits are refundable within a 14-day cooling-off period if they remain unused.</li>
          <li>Once credits are used in an auction, they are non-refundable as they represent participation in a completed auction event.</li>
        </ul>
      </>,
      <>
        <h3>2. Won Auction Items</h3>
        <ul>
          <li>Items won in auctions are subject to a 30-day return period if they are unused and in their original packaging.</li>
          <li>Defective items can be returned for a full refund, including shipping costs, or replaced at no extra charge.</li>
        </ul>
      </>,
      <>
        <h3>3. Refund Processing</h3>
        <ul>
          <li>Refunds are processed back to the original payment method within 3-5 business days of approval.</li>
          <li>Bid costs are non-refundable, and shipping costs are refundable only if the product is defective.</li>
        </ul>
      </>
    ]
  },
  {
    title: "Responsible Usage Guidelines",
    content: [
      <>
        <h3>1. Personal Limits</h3>
        <ul>
          <li>We encourage users to set personal spending limits and monitor their time spent on the platform.</li>
          <li>Use the bid tracking tools available to help you manage your participation effectively.</li>
          <li>Take regular breaks and avoid excessive bidding to ensure responsible usage.</li>
        </ul>
      </>,
      <>
        <h3>2. Fair Play Rules</h3>
        <ul>
          <li>Only one account is allowed per user, and any form of collusion or automated bidding is strictly prohibited.</li>
          <li>Violating fair play rules may result in suspension or permanent account closure.</li>
        </ul>
      </>,
      <>
        <h3>3. Support Services</h3>
        <ul>
          <li>We provide 24/7 customer support for issues related to bidding, payments, or account management.</li>
          <li>Users can access tools for spending control and activity monitoring via their account dashboard.</li>
          <li>A dedicated dispute resolution process is available for any conflicts related to platform usage.</li>
        </ul>
      </>
    ]
  }
];

// FAQ Component
interface FAQCategory {
  category: string;
  items: FAQItem[];
}

interface FAQItem {
  question: string;
  answer: React.ReactNode;
}

const faqData: FAQCategory[] = [
  {
    category: "How UShop.bid Works",
    items: [
      {
        question: "What is UShop.bid and how does it work?",
        answer: (
          <>
            UShop.bid is an online auction platform where users can bid on retail products. Each bid:
            <ul>
              <li>Costs a fixed amount (paid using pre-purchased credits).</li>
              <li>Increases the auction price by £0.01.</li>
              <li>Extends the countdown timer.</li>
            </ul>
            The last bidder when the timer reaches zero wins the right to purchase the item at the final auction price.
            This is a competition of skill and timing.
          </>
        )
      },
      {
        question: "Is this gambling?",
        answer: (
          <>
            No, UShop.bid is not a gambling platform because:
            <ul>
              <li>Each bid is a conscious decision, not a random event.</li>
              <li>Success depends on strategy, timing, and skill, not chance.</li>
              <li>Users know exactly what they're bidding on, and the outcome is determined by their actions.</li>
            </ul>
            However, there is no guarantee of winning or saving money. Please bid responsibly.
          </>
        )
      }
    ]
  },
  {
    category: "Bidding and Winning",
    items: [
      {
        question: "How can I increase my chances of winning?",
        answer: (
          <>
            Success on UShop.bid requires:
            <ul>
              <li>Understanding how the timer and bidding work.</li>
              <li>Careful timing of your bids.</li>
              <li>Monitoring auction patterns and setting spending limits.</li>
            </ul>
            Please remember, there is no guarantee of winning. Bid responsibly.
          </>
        )
      },
      {
        question: "What happens if I win an auction?",
        answer: (
          <>
            If you win:
            <ul>
              <li>You will receive an immediate notification.</li>
              <li>You must complete the purchase within 24 hours.</li>
              <li>Review the total cost, including any applicable shipping fees.</li>
            </ul>
            If you do not complete the purchase within the allotted time, you may be restricted from future auctions.
          </>
        )
      }
    ]
  },
  {
    category: "Legal Rights and Consumer Protection",
    items: [
      {
        question: "What are my consumer rights?",
        answer: (
          <>
            Your statutory rights are protected under UK and EU consumer law:
            <ul>
              <li>14-day right to cancel credit purchases (unused).</li>
              <li>Distance Selling Regulations protect won auction items.</li>
              <li>Right to return faulty or misrepresented items within 30 days.</li>
            </ul>
          </>
        )
      },
      {
        question: "What is your returns policy?",
        answer: (
          <>
            For won auction items:
            <ul>
              <li>30-day returns for unused items in original packaging.</li>
              <li>Refund of the purchase price (excluding bid costs).</li>
            </ul>
            Used credits are non-refundable.
          </>
        )
      }
    ]
  }
];

// Main LegalInfoPage Component
const LegalInfoPage: React.FC = () => {
  const [openSection, setOpenSection] = useState<number | null>(null);

  const toggleSection = (index: number) => {
    setOpenSection(openSection === index ? null : index);
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Legal Information</h1>
      <div style={styles.policyList}>
        {policyData.map((policy, index) => (
          <div key={index} style={styles.policySection}>
            <div
              style={styles.policyHeader}
              onClick={() => toggleSection(index)}
            >
              <h2 style={styles.policyTitle}>{policy.title}</h2>
              {openSection === index ? <ChevronUp /> : <ChevronDown />}
            </div>
            {openSection === index && (
              <div style={styles.policyContent}>
                {policy.content.map((content, contentIndex) => (
                  <div key={contentIndex} style={styles.paragraph}>
                    {content}
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
      <div style={styles.disclaimer}>
        <p>Last updated: {new Date().toLocaleDateString()}. Please review regularly for updates.</p>
      </div>
    </div>
  );
};

// Styles Object
const styles = {
  container: {
    maxWidth: '1200px',
    margin: '0 auto',
    padding: '40px 20px',
    fontFamily: 'Roboto, sans-serif',
  } as React.CSSProperties,
  title: {
    fontSize: '36px',
    color: '#e53e3e',
    textAlign: 'center',
    marginBottom: '40px',
  } as React.CSSProperties,
  policyList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  } as React.CSSProperties,
  policySection: {
    borderRadius: '8px',
    overflow: 'hidden',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
  } as React.CSSProperties,
  policyHeader: {
    backgroundColor: '#f8f8f8',
    padding: '20px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    transition: 'background-color 0.3s ease',
  } as React.CSSProperties,
  policyTitle: {
    margin: 0,
    fontSize: '24px',
    color: '#e53e3e',
  } as React.CSSProperties,
  policyContent: {
    padding: '20px',
    backgroundColor: '#ffffff',
  } as React.CSSProperties,
  paragraph: {
    marginBottom: '15px',
    lineHeight: '1.6',
  } as React.CSSProperties,
  disclaimer: {
    marginTop: '40px',
    padding: '20px',
    backgroundColor: '#f8f8f8',
    borderRadius: '8px',
    fontSize: '14px',
    color: '#666',
    textAlign: 'center',
  } as React.CSSProperties,
};

export default LegalInfoPage;
