import React, { useState, useMemo } from 'react';
import { Invoice } from '../../../../types';
import { deleteInvoice } from '../../utils/adminAPI';
import './inv.css'; // Make sure to create this CSS file

interface InvoiceListProps {
  invoices: Invoice[];
  onEdit: (invoice: Invoice) => void;
  fetchInvoices: () => void;
}

export const InvoiceList: React.FC<InvoiceListProps> = ({ invoices, onEdit, fetchInvoices }) => {
  const [searchText, setSearchText] = useState('');
  const [sortColumn, setSortColumn] = useState<keyof Invoice | null>(null);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [statusFilter, setStatusFilter] = useState<string | null>(null);
  const token = localStorage.getItem('token'); // or however you're storing the token

  const handleDelete = async (id: number) => {
    if(token)
    await deleteInvoice(token,id);
    fetchInvoices();
  };

  const handleSort = (column: keyof Invoice) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  const filteredAndSortedInvoices = useMemo(() => {
    return invoices
      .filter(invoice => 
        Object.values(invoice).some(value => 
          value?.toString().toLowerCase().includes(searchText.toLowerCase())
        ) && (statusFilter ? invoice.status === statusFilter : true)
      )
      .sort((a, b) => {
        if (!sortColumn) return 0;
        if (! a) return 0;
        if (! b[sortColumn]) return 0;

        if ( a[sortColumn] || 0 < Number(b[sortColumn]) || 0) return sortDirection === 'asc' ? -1 : 1;
        if ( a[sortColumn]||0  >Number(b[sortColumn]) || 0 ) return sortDirection === 'asc' ? 1 : -1;
        return 0;
      });
  }, [invoices, searchText, statusFilter, sortColumn, sortDirection]);

  const clearAll = () => {
    setSearchText('');
    setSortColumn(null);
    setSortDirection('asc');
    setStatusFilter(null);
  };

  return (
    <div className="invoice-list">
      <h2>Invoice List</h2>
      <div className="invoice-list__controls">
        <input
          type="text"
          placeholder="Search invoices"
          value={searchText}
          onChange={e => setSearchText(e.target.value)}
          className="invoice-list__search"
        />
        <select 
          value={statusFilter || ''} 
          onChange={e => setStatusFilter(e.target.value || null)}
          className="invoice-list__filter"
        >
          <option value="">All Statuses</option>
          <option value="awaiting payment">Awaiting Payment</option>
          <option value="paid">Paid</option>
          <option value="shipped">Shipped</option>
          <option value="delivered">Delivered</option>
          <option value="completed">Completed</option>
          <option value="cancelled">Cancelled</option>
        </select>
        <button onClick={clearAll} className="invoice-list__clear-btn">Clear filters and sorters</button>
      </div>
      <table className="invoice-list__table">
        <thead>
          <tr>
            <th onClick={() => handleSort('id')}>
              ID {sortColumn === 'id' && (sortDirection === 'asc' ? '▲' : '▼')}
            </th>
            <th onClick={() => handleSort('amount')}>
              Amount {sortColumn === 'amount' && (sortDirection === 'asc' ? '▲' : '▼')}
            </th>
            <th onClick={() => handleSort('credits')}>
              Credits {sortColumn === 'credits' && (sortDirection === 'asc' ? '▲' : '▼')}
            </th>
            <th>Status</th>
            <th onClick={() => handleSort('date')}>
              Date {sortColumn === 'date' && (sortDirection === 'asc' ? '▲' : '▼')}
            </th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredAndSortedInvoices.map((invoice) => (
            <tr key={invoice.id}>
              <td>{invoice.id}</td>
              <td>{invoice.amount}</td>
              <td>{invoice.credits}</td>
              <td>{invoice.status}</td>
              <td>{invoice.date}</td>
              <td>
                <button onClick={() => onEdit(invoice)} className="invoice-list__edit-btn">Edit</button>
                <button onClick={() => handleDelete(invoice.id)} className="invoice-list__delete-btn">Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default InvoiceList;