import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
// import { FiSearch, FiPackage, FiClock } from 'react-icons/fi';
import { getCategories } from '../utils/coreAPI';
import { ProductCategory } from '../../../types';
import styles from './CategoriesPage.module.css';

interface CategoryWithCounts extends ProductCategory {
  item_count: number;
  active_auctions: number;
}

const CategoriesPage = () => {
  const [categories, setCategories] = useState<CategoryWithCounts[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await getCategories();
        setCategories(response.data);
      } catch (error) {
        console.error('Error fetching categories:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCategories();
  }, []);

  const filteredCategories = categories.filter(category =>
    category.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleCategoryClick = (categoryId: number) => {
    navigate(`/?category=${categoryId}`);
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const cardVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: "easeOut"
      }
    }
  };

  if (isLoading) {
    return (
      <div className={styles.loadingContainer}>
        <div className={styles.loader}></div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <motion.div
        className={styles.searchContainer}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        {/* <FiSearch className={styles.searchIcon} /> */}
        <input
          type="text"
          placeholder="Search categories"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className={styles.searchInput}
        />
      </motion.div>
      <motion.div
        className={styles.categoriesContainer}
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        {filteredCategories.map(category => (
          <motion.div
            key={category.id}
            className={styles.categoryCard}
            variants={cardVariants}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => handleCategoryClick(category.id)}
          >
            <img src={category.image_url} alt={category.name} className={styles.categoryImage} />
            <h3 className={styles.categoryName}>{category.name}</h3>
            <p className={styles.categoryDescription}>{category.description}</p>
            <div className={styles.categoryDetails}>
              <div className={styles.categoryItemCount}>
                {/* <FiPackage className={styles.categoryIcon} /> */}
                <span>{category.item_count} items</span>
              </div>
              <div className={styles.categoryActiveAuctions}>
                {/* <FiClock className={styles.categoryIcon} /> */}
                <span>{category.active_auctions} active auctions</span>
              </div>
            </div>
          </motion.div>
        ))}
      </motion.div>
    </div>
  );
};

export default CategoriesPage;
