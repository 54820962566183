import React, { useEffect, useState, useCallback, useMemo } from 'react';
import {  getGovernorData } from '../../utils/coreAPI';
import { Auction, Product, Bid } from '../../../../types';
import { createBid, syncAuctionTimes, verifyAuction, startAuction,completeAuction } from '../../utils/coreAPI';
import io from 'socket.io-client';
import styles from './AuctionGovernor.module.css';
import { motion, AnimatePresence } from 'framer-motion';
// import { toast } from 'react-toastify';

const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:4000';
const socketUrl = apiUrl.endsWith('/api') ? apiUrl.slice(0, -4) : apiUrl;
const socket = io(socketUrl);

const useAuctionData = () => {
  const [auctions, setAuctions] = useState<Auction[]>([]);
  const [products, setProducts] = useState<Product[]>([]);
  const [bids, setBids] = useState<Bid[]>([]);
  const [timers, setTimers] = useState<Map<number, number>>(new Map());
  const [prevUsernames, setPrevUsernames] = useState<Map<number, string>>(new Map());
  const [nextVerifyUids, setNextVerifyUids] = useState<Map<number, number>>(new Map());
  const [verifyTimes, setVerifyTimes] = useState<Map<number, number>>(new Map());
  const [subsetSizes, setSubsetSizes] = useState<Record<number, number>>({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const governorResponse = await getGovernorData();
        const { 
          auctions: fetchedAuctions = [], 
          bids: fetchedBids = [], 
          remainingTime = {}, 
          products: fetchedProducts = [],
          randomUids = {},
          lastBidders = {},
          subsetSizes = {}
        } = governorResponse.data || {};

        setAuctions(fetchedAuctions);
        setProducts(fetchedProducts);
        setBids(fetchedBids);
        setSubsetSizes(subsetSizes);

        // Initialize all Maps
        const initialTimers = new Map<number, number>();
        const initialUsernames = new Map<number, string>();
        const initialVerifyUids = new Map<number, number>();
        const initialVerifyTimes = new Map<number, number>();

        fetchedAuctions.forEach(auction => {
          const auctionId = auction.id;
          
          // Set timer
          initialTimers.set(auctionId, remainingTime[auctionId as keyof typeof remainingTime] || auction.countdown_timer);
          
          if (auction.status === 'active') {
            // Set previous bidder
            const lastBidder = lastBidders[auctionId as keyof typeof lastBidders];
            if (lastBidder) {
              initialUsernames.set(auctionId, lastBidder.username);
            }

            // Set verify UID
            const nextUid = randomUids[auctionId as keyof typeof randomUids];
            if (nextUid) {
              initialVerifyUids.set(auctionId, nextUid);
            }

            // Set verify time if there's a current bid
            if (auction.current_bid > 0) {
              const countdown = auction.countdown_timer;
              const minTime = Math.floor(countdown * 0.25);
              const maxTime = Math.floor(countdown * 0.85);
              const randomVerifyTime = Math.floor(Math.random() * (maxTime - minTime + 1)) + minTime;
              initialVerifyTimes.set(auctionId, randomVerifyTime);
            }
          }
        });

        setTimers(initialTimers);
        setPrevUsernames(initialUsernames);
        setNextVerifyUids(initialVerifyUids);
        setVerifyTimes(initialVerifyTimes);

      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };

    fetchData();
  }, []);

  return { 
    auctions, 
    setAuctions, 
    products, 
    bids, 
    setBids, 
    timers, 
    setTimers, 
    prevUsernames, 
    setPrevUsernames,
    nextVerifyUids,
    setNextVerifyUids,
    verifyTimes,
    setVerifyTimes,
    subsetSizes,
    setSubsetSizes
  };
};

// Add new interfaces
interface LoadingState {
  [key: number]: boolean;
}

const AuctionGovernor: React.FC = () => {
  const { 
    auctions, 
    setAuctions, 
    products, 
    bids, 
    setBids, 
    timers, 
    setTimers,
    prevUsernames,
    setPrevUsernames,
    nextVerifyUids,
    setNextVerifyUids,
    verifyTimes,
    setVerifyTimes,
    subsetSizes,
    setSubsetSizes
  } = useAuctionData();
  
  const token = localStorage.getItem('token');
  const [auctionsSynced, setAuctionsSynced] = useState<boolean>(false);
  const [loading, setLoading] = useState<LoadingState>({});
  const [selectedAuction, setSelectedAuction] = useState<number | null>(null);
  const [filterStatus, setFilterStatus] = useState<'all' | 'active' | 'upcoming'>('all');

  const handleAuctionDelete = useCallback((auctionId: number) => {
    setAuctions(prevAuctions => prevAuctions.filter(auction => auction.id !== auctionId));
    setTimers(prevTimers => {
      const newTimers = new Map(prevTimers);
      newTimers.delete(auctionId);
      return newTimers;
    });
    setPrevUsernames(prev => {
      const newUsernames = new Map(prev);
      newUsernames.delete(auctionId);
      return newUsernames;
    });
    setNextVerifyUids(prev => {
      const newVerifyUids = new Map(prev);
      newVerifyUids.delete(auctionId);
      return newVerifyUids;
    });
    setVerifyTimes(prev => {
      const newVerifyTimes = new Map(prev);
      newVerifyTimes.delete(auctionId);
      return newVerifyTimes;
    });
    setSubsetSizes(prev => {
      const newSubsetSizes = {...prev};
      delete newSubsetSizes[auctionId];
      return newSubsetSizes;
    });
  }, []);

  const handleAuctionUpdate = useCallback((updatedAuction: Auction) => {
    setAuctions((prevAuctions) => {
         // Check if auction already exists
         const existingAuction = prevAuctions.find((auction) => auction.id === updatedAuction.id);
      
         if (existingAuction) {
           return prevAuctions.map((auction) => 
             auction.id === updatedAuction.id ? updatedAuction : auction
           );
      // Remove auctions that are completed or deleted
      if (updatedAuction.status === 'completed' || updatedAuction.status === 'deleted') {
        return prevAuctions.filter(auction => auction.id !== updatedAuction.id);
      }

      // Only include upcoming or active auctions
      if (updatedAuction.status !== 'upcoming' && updatedAuction.status !== 'active') {
        return prevAuctions.filter(auction => auction.id !== updatedAuction.id);
      }
      
   
      } else {
        return [...prevAuctions, updatedAuction];
      }
    });
  }, []);

  useEffect(() => {
    socket.on('auctionUpdate', handleAuctionUpdate);
    socket.on('auctionDelete', handleAuctionDelete);
    
    return () => {
      socket.off('auctionUpdate');
      socket.off('auctionDelete');
    };
  }, [handleAuctionUpdate, handleAuctionDelete]);

  useEffect(() => {
    const currentTime = new Date().getTime();
    auctions.forEach(async (auction) => {
      const auctionStartTime = new Date(auction.start_time).getTime();
      const elapsed = Math.floor((currentTime - auctionStartTime) / 1000);
      if (elapsed >= 0 && auction.status === 'upcoming') {
        await startAuction(auction.id);
        setAuctions((prevAuctions) =>
          prevAuctions.map((a) => (a.id === auction.id ? { ...a, status: 'active' } : a))
        );
      }
    });
  }, [auctions]);

  const handleTimerTick = useCallback(() => {
    setTimers((prevTimers) => {
      const newTimers = new Map(prevTimers);
      prevTimers.forEach((time, auctionId) => {
        const auction = auctions.find((a) => a.id === auctionId);
        if (!auction || auction.current_bid === 0) return;

        // Only process active auctions with bids
        if (auction.status === 'active') {
          const newTime = time - 1;
          newTimers.set(auctionId, newTime);

          // Check if it's time to verify based on verifyTimes
          const verifyTime = verifyTimes.get(auctionId);
          if (verifyTime && newTime <= verifyTime) {
            const uidToVerify = nextVerifyUids.get(auctionId);
            if (uidToVerify) {
              verifyAuction(auctionId, uidToVerify);
              setVerifyTimes(prev => {
                const newVerifyTimes = new Map(prev);
                newVerifyTimes.delete(auctionId);
                return newVerifyTimes;
              });
            }
          }

          // Then attempt to complete if bid meets minimum and timer is expired
          if (newTime <= -1 ) {
            const uidToVerify = nextVerifyUids.get(auctionId);
            if (uidToVerify) {
              verifyAuction(auctionId, uidToVerify);
            }
            completeAuction(auctionId);
          }
        }
      });
      return newTimers;
    });
  }, [auctions, nextVerifyUids, verifyTimes, auctionsSynced]);

  useEffect(() => {
    const interval = setInterval(handleTimerTick, 1000);
    return () => clearInterval(interval);
  }, [handleTimerTick]);

  const handleBidPlaced = useCallback((data: { auction: Auction; bid: Bid; uid: number; username: string }) => {
    const { auction, bid, uid, username } = data;
    setAuctions((prevAuctions) =>
      prevAuctions.map((a) => a.id === auction.id ? { ...a, current_bid: auction.current_bid } : a)
    );
    setBids((prevBids) => [...prevBids, bid]);
    setTimers((prevTimers) => new Map(prevTimers).set(auction.id, auction.countdown_timer));
    setPrevUsernames((prev) => new Map(prev).set(auction.id, username));
    setNextVerifyUids((prev) => new Map(prev).set(auction.id, uid));
    setVerifyTimes((prev) => {
      const newVerifyTimes = new Map(prev);
      const countdown = auction.countdown_timer;
      const minTime = Math.floor(countdown * 0.25);
      const maxTime = Math.floor(countdown * 0.85);
      const randomVerifyTime = Math.floor(Math.random() * (maxTime - minTime + 1)) + minTime;
      newVerifyTimes.set(auction.id, randomVerifyTime);
      return newVerifyTimes;
    });
    syncAuctionTimes();

  }, []);

  useEffect(() => {
    socket.on('bidPlaced', handleBidPlaced);
    return () => {
      socket.off('bidPlaced');
    };
  }, [handleBidPlaced]);

  useEffect(() => {
    socket.on('auctionSync', (data) => {
      if (Array.isArray(data)) {
        setTimers((prevTimers) => {
          const newTimers = new Map(prevTimers);
          data.forEach(({ auction_id, remainingTime }) => {
            newTimers.set(auction_id, remainingTime);
          });
          setAuctionsSynced(true);

          return newTimers;
        });
      }
    });
    return () => {
      socket.off('auctionSync');
    };
  }, []);

  const handleBid = useCallback(async (auctionId: number) => {
    try {
      setLoading(prev => ({ ...prev, [auctionId]: true }));
      
      const auction = auctions.find((a) => a.id === auctionId);
      if (!auction || auction.status !== 'active') {
        // toast.error('This auction is not active');
        return;
      }

      await createBid(token as string, {
        auction_id: auctionId,
        bid_time: new Date().toISOString(),
        seconds_left: 0
      });

      // toast.success('Bid placed successfully!');
    } catch (error) {
      // toast.error('Failed to place bid. Please try again.');
      console.error('Failed to place bid', error);
    } finally {
      setLoading(prev => ({ ...prev, [auctionId]: false }));
    }
  }, [auctions, token]);

  const countBidsByType = useCallback((auctionId: number) => {
    const auctionBids = bids.filter(bid => bid.auction_id === auctionId);
    const VTypeCount = auctionBids.filter(bid => bid.type === 'V').length;
    const UTypeCount = auctionBids.filter(bid => bid.type === 'U').length;
    return { VTypeCount, UTypeCount };
  }, [bids]);

  const productMap = useMemo(() => {
    const map = new Map<number, Product>();
    products.forEach((product) => map.set(product.id, product));
    return map;
  }, [products]);

  const sortAuctions = (auctions: Auction[], verifyTimes: Map<number, number>) => {
    return [...auctions].sort((a, b) => {
      // First sort by status (active before upcoming)
      if (a.status !== b.status) {
        return a.status === 'active' ? -1 : 1;
      }
      
      // For active auctions, sort by verify time
      if (a.status === 'active') {
        const verifyTimeA = verifyTimes.get(a.id) || Infinity;
        const verifyTimeB = verifyTimes.get(b.id) || Infinity;
        return verifyTimeA - verifyTimeB;
      }
      
      // For upcoming auctions, sort by start time
      return new Date(a.start_time).getTime() - new Date(b.start_time).getTime();
    });
  };

  const filteredAuctions = useMemo(() => {
    let filtered = auctions;
    if (filterStatus !== 'all') {
      filtered = auctions.filter(a => a.status === filterStatus);
    }
    return sortAuctions(filtered, verifyTimes);
  }, [auctions, filterStatus, verifyTimes]);

  const AuctionDetailsModal = ({ auctionId }: { auctionId: number }) => {
    const auction = auctions.find(a => a.id === auctionId);
    if (!auction) return null;

    return (
      <motion.div 
        className={styles.modal}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 20 }}
      >
        {/* Add detailed auction information */}
      </motion.div>
    );
  };

  const getFirstImage = (product: Product | undefined) => {
    if (product?.media && product.media.length > 0) {
      const sortedMedia = [...product.media].sort((a, b) => a.order - b.order);
      return sortedMedia[0].url;
    }
    return '/placeholder-image.jpg'; // Add a default placeholder image
  };

  return (
    <div className={styles.container}>
      <div className={styles.controls}>
        <div className={styles.filters}>
          <button 
            className={`${styles.filterButton} ${filterStatus === 'all' ? styles.active : ''}`}
            onClick={() => setFilterStatus('all')}
          >
            All
          </button>
          <button 
            className={`${styles.filterButton} ${filterStatus === 'active' ? styles.active : ''}`}
            onClick={() => setFilterStatus('active')}
          >
            Active
          </button>
          <button 
            className={`${styles.filterButton} ${filterStatus === 'upcoming' ? styles.active : ''}`}
            onClick={() => setFilterStatus('upcoming')}
          >
            Upcoming
          </button>
        </div>
      </div>

      <motion.div 
        className={styles.auctionGrid}
        layout
      >
        <AnimatePresence>
          {filteredAuctions.map((auction) => {
            const product = productMap.get(auction.product_id);
            
            return (
              <motion.div
                key={auction.id}
                layout
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.9 }}
                transition={{ duration: 0.3 }}
              >
                <div className={`${styles.auctionCard} ${auction.status === 'active' ? styles.activeCard : ''}`}>
                  <div className={styles.statusBadge}>
                    {auction.status === 'active' ? 'Active' : 'Upcoming'}
                  </div>
                  
                  <img 
                    src={getFirstImage(product)}
                    alt={product?.title || 'Product Image'} 
                    className={styles.auctionImage}
                    onError={(e) => {
                      const target = e.target as HTMLImageElement;
                      target.src = '/placeholder-image.jpg'; // Fallback image on error
                    }}
                  />
                  
                  <div className={styles.auctionDetails}>
                    <h3 className={styles.productTitle}>{product?.title}</h3>
                    {/* <p className={styles.productDescription}>{product?.description}</p> */}
                    
                    <div className={styles.statsContainer}>
                      {/* Bid Information Group */}
                      <div className={styles.statsGroup}>
                        <div className={styles.statsHeader}>Bid Information</div>
                        <div className={styles.statsContent}>
                          <div className={styles.statItem}>
                            <span className={styles.label}>Current Bid:</span>
                            <span className={styles.value}>£{auction.current_bid || 0}</span>
                          </div>
                          <div className={styles.bidLimits}>
                            <div className={styles.statItem}>
                              <span className={styles.label}>V-Bids / UMIN:</span>
                              <span className={styles.value}>
                                {countBidsByType(auction.id).VTypeCount} / {auction.umin}
                              </span>
                            </div>
                            <div className={styles.statItem}>
                              <span className={styles.label}>U-Bids / BMAX:</span>
                              <span className={styles.value}>
                                {countBidsByType(auction.id).UTypeCount} / {auction.bmax}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Timer Information Group */}
                      <div className={styles.statsGroup}>
                        <div className={styles.statsHeader}>Timer Status</div>
                        <div className={styles.statsContent}>
                          {auction.status === 'active' ? (
                            <>
                              <div className={styles.timerGrid}>
                                <div className={styles.statItem}>
                                  <span className={styles.label}>Time Remaining:</span>
                                  <span className={styles.value}>{timers.get(auction.id) || 0}s</span>
                                </div>
                                <div className={styles.statItem}>
                                  <span className={styles.label}>Max Timer:</span>
                                  <span className={styles.value}>{auction.countdown_timer}s</span>
                                </div>
                              </div>
                              <div className={styles.bidderInfo}>
                                <div className={styles.statItem}>
                                  <span className={styles.label}>Previous Bidder:</span>
                                  <span className={styles.value}>{prevUsernames.get(auction.id) || 'None'}</span>
                                </div>
                                <div className={styles.statItem}>
                                  <span className={styles.label}>Next Verify:</span>
                                  <span className={styles.value}>ID {nextVerifyUids.get(auction.id) } at {verifyTimes.get(auction.id)}s</span>
                                </div>
                              </div>
                              <button 
                                onClick={() => handleBid(auction.id)} 
                                className={styles.bidButton}
                              >
                                Place Bid
                              </button>
                            </>
                          ) : (
                            <div className={styles.countdown}>Starts in: {Math.max(Math.floor((new Date(auction.start_time).getTime() - new Date().getTime()) / 1000), 0)}s</div>
                          )}
                        </div>
                      </div>

                      {/* Bot Pool Information Group */}
                      <div className={styles.statsGroup}>
                        <div className={styles.statsHeader}>Bot Pool Information</div>
                        <div className={styles.statsContent}>
                          <div className={styles.statItem}>
                            <span className={styles.label}>Bot Pool Size:</span>
                            <span className={styles.value}>
                              {subsetSizes[auction.id] || 'Loading...'}
                            </span>
                          </div>
                          <div className={styles.statItem}>
                            <span className={styles.label}>Next Verify:</span>
                            <span className={styles.value}>
                              ID {nextVerifyUids.get(auction.id)} at {verifyTimes.get(auction.id)}s
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {loading[auction.id] && (
                  <div className={styles.loadingOverlay}>
                    <div className={styles.spinner} />
                  </div>
                )}
              </motion.div>
            );
          })}
        </AnimatePresence>
      </motion.div>

      <AnimatePresence>
        {selectedAuction && (
          <AuctionDetailsModal auctionId={selectedAuction} />
        )}
      </AnimatePresence>
    </div>
  );
};

export default AuctionGovernor;
