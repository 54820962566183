import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { Auction, Bid } from '../../../../types';
import { deleteAuction, getProducts } from '../../utils/adminAPI';
import { getBids } from '../../utils/coreAPI';
import styles from './AuctionList.module.css'; // Ensure you have a corresponding CSS file
import { Product } from '../../../../types';
interface AuctionListProps {
  auctions: Auction[];
  onEdit: (auction: Auction) => void;
  fetchAuctions: () => void;
}

const AuctionList: React.FC<AuctionListProps> = ({ auctions, onEdit, fetchAuctions }) => {
  const [searchText, setSearchText] = useState('');
  const [sortColumn, setSortColumn] = useState<keyof Auction | null>(null);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [statusFilter, setStatusFilter] = useState<string | null>(null);
  const token = localStorage.getItem('token'); // or however you're storing the token
  const [bids, setBids] = useState<Bid[]>([]);
  const [products, setProducts] = useState<Product[]>([]);

  useEffect(() => {
    const fetchBids = async () => {
      try {
        const response = await getBids();
        setBids(response.data);
      } catch (error) {
        console.error('Failed to fetch bids', error);
      }
    };
    fetchBids();
  }, []);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await getProducts(token||'');
        setProducts(response.data as Product[]);
      } catch (error) {
        console.error('Failed to fetch products', error);
      }
    };
    fetchProducts();
  }, []);

  const handleDelete = async (id: number) => {
    await deleteAuction(token||'',id);
    fetchAuctions();
  };

  const filteredAndSortedAuctions = useMemo(() => {
    return auctions
      .filter(auction => 
        Object.values(auction).some(value => 
          value?.toString().toLowerCase().includes(searchText.toLowerCase())
        )
      )
      .sort((a, b) => {
        if (!sortColumn) return 0;
        if (a[sortColumn] < b[sortColumn]) return sortDirection === 'asc' ? -1 : 1;
        if (a[sortColumn] > b[sortColumn]) return sortDirection === 'asc' ? 1 : -1;
        return 0;
      });
  }, [auctions, searchText, sortColumn, sortDirection]);

  const handleSort = (column: keyof Auction) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  const countBidsByType = useCallback((auctionId: number) => {
    const auctionBids = bids.filter(bid => bid.auction_id === auctionId);
    const VTypeCount = auctionBids.filter(bid => bid.type === 'V').length;
    const UTypeCount = auctionBids.filter(bid => bid.type === 'U').length;
    return { VTypeCount, UTypeCount };
  }, [bids]);

  const getProductName = useCallback((productId: number) => {
    const product = products.find(p => p.id === productId);
    return product?.title || 'Unknown Product';
  }, [products]);

  return (
    <div className={styles.auctionList}>
      <h2>Auction List</h2>
      <input
        type="text"
        placeholder="Search auctions"
        value={searchText}
        onChange={e => setSearchText(e.target.value)}
        className={styles.searchInput}
      />
      <table className={styles.auctionTable}>
        <thead>
          <tr>
            <th onClick={() => handleSort('id')}>ID</th>
            <th onClick={() => handleSort('product_id')}>Product ID</th>
            <th>Product Name</th>
            <th onClick={() => handleSort('start_time')}>Start Time</th>
            <th onClick={() => handleSort('countdown_timer')}>Countdown Timer</th>
            <th onClick={() => handleSort('status')}>Status</th>
            <th onClick={() => handleSort('current_bid')}>Current Bid</th>
            <th onClick={() => handleSort('umin')}>Minimum Bid</th>
            <th onClick={() => handleSort('bmax')}>Maximum Bid</th>
            <th>V Type Bids</th>
            <th>U Type Bids</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredAndSortedAuctions.map(auction => {
            const { VTypeCount, UTypeCount } = countBidsByType(auction.id);
            return (
              <tr key={auction.id}>
                <td>{auction.id}</td>
                <td>{auction.product_id}</td>
                <td>{getProductName(auction.product_id)}</td>
                <td>{new Date(auction.start_time).toLocaleString()}</td>
                <td>{auction.countdown_timer} seconds</td>
                <td>{auction.status}</td>
                <td>£{auction.current_bid/100}</td>
                <td>£{auction.umin/100}</td>
                <td>£{auction.bmax/100}</td>
                <td>{VTypeCount}</td>
                <td>{UTypeCount}</td>
                <td>
                  <button onClick={() => onEdit(auction)} className={styles.editButton}>Edit</button>
                  <button onClick={() => handleDelete(auction.id)} className={styles.deleteButton}>Delete</button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default AuctionList;
