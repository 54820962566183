// src/pages/IndexPage.tsx

import React, { useEffect, useState } from 'react';
import { getProducts, getBids, getGovernorData, getCategories } from '../utils/coreAPI';
import { Auction, Product, Bid, ProductCategory } from '../../../types';
import { createBid, syncAuctionTimes, startAuction } from '../utils/coreAPI';
import '../styles/mobile.css';
import io from 'socket.io-client';
import PromoAuction from '../auction/components/PromoAuction';
import { useError } from '../components/ErrorContext';
import Banner from '../components/Banner';
import FilteredGrid from '../auction/components/FilteredGrid';
import styles from '../accounts/components/BiddingHistory.module.css';
import Loading from '../components/loading';

const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:4000';
const socketUrl = apiUrl.endsWith('/api') ? apiUrl.slice(0, -4) : apiUrl;
const socket = io(socketUrl);

const IndexPage: React.FC = () => {
    const { showError } = useError();
    const [auctions, setAuctions] = useState<Auction[]>([]);
    const [products, setProducts] = useState<Product[]>([]);
    const [bids, setBids] = useState<Bid[]>([]);
    const [timers, setTimers] = useState<Map<number, number>>(new Map());
    const [featuredType, setFeaturedType] = useState<'hottest'|'lowest' | 'highest' | 'nextLive' | 'endingSoon'>(() => {
        const saved = localStorage.getItem('featuredType');
        return (saved as 'hottest' | 'lowest' |  'highest' | 'nextLive' | 'endingSoon') || 'lowest';
    });
    const [prevBidders, setPrevBidders] = useState<Map<number, { username: string; uid: number }>>(new Map());
    const [categories, setCategories] = useState<ProductCategory[]>([]);
    const token = localStorage.getItem('token');

    // Pagination state
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 12; // Adjust this number as needed

    const bannerSlides = [
        {
            image: `${process.env.REACT_APP_API_URL?.replace('/api', '') || 'http://127.0.0.1:4000'}/uploads/banner1-2.jpg`,
            title: 'Welcome to Our Auction House',
            description: 'Discover unique items and amazing deals',
            link: '/faq'
        }
    ];

    // Get the current URL parameters
    const urlParams = new URLSearchParams(window.location.search);
    const currentCategory = urlParams.get('category');

    // Show promo only on home page with no category selected
    const shouldShowPromo = window.location.pathname === '/' && !currentCategory;

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Move fetchData outside of useEffect to avoid re-creation on each render
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const governorResponse = await getGovernorData();
                const { 
                    auctions: fetchedAuctions = [], 
                    bids: fetchedBids = [], 
                    remainingTime = {}, 
                    products: fetchedProducts = [],
                    lastBidders = {}
                } = governorResponse.data || {};

                // Filter out completed auctions
                const filteredAuctions = fetchedAuctions.filter(
                    (auction: Auction) => auction.status === 'upcoming' || auction.status === 'active'
                );

                // Make sure products have their media
                const productsWithMedia = fetchedProducts.map(product => ({
                    ...product,
                    media: product.media || []
                }));

                setAuctions(filteredAuctions);
                setProducts(productsWithMedia);
                setBids(fetchedBids);

                // Set up timers
                const initialTimers = new Map<number, number>();
                filteredAuctions.forEach((auction: Auction) => {
                    initialTimers.set(auction.id, remainingTime[auction.id] || auction.countdown_timer);
                });
                setTimers(initialTimers);

                // Set up previous bidders
                const initialBidders = new Map<number, { username: string; uid: number }>();
                Object.entries(lastBidders).forEach(([auctionId, bidder]: [string, any]) => {
                    initialBidders.set(Number(auctionId), { 
                        username: bidder.username, 
                        uid: bidder.uid || 0 
                    });
                });
                setPrevBidders(initialBidders);

                const categoriesResponse = await getCategories();
                setCategories(categoriesResponse.data);
                setIsLoading(false);

            } catch (error) {
                console.error('Failed to fetch data:', error);
                showError('Failed to fetch auction data. Please refresh the page.', 'error');
            } finally {
                setIsLoading(false);
            }
        };

        // Initial data fetch
        fetchData();

        // Socket setup
        socket.on('auctionDelete', handleAuctionDelete);
        socket.on('auctionUpdate', handleAuctionUpdate);
        socket.on('bidPlaced', handleBidPlaced);
        socket.on('auctionSync', handleAuctionSync);

        // Cleanup socket listeners
        return () => {
            socket.off('auctionDelete', handleAuctionDelete);
            socket.off('auctionUpdate', handleAuctionUpdate);
            socket.off('bidPlaced', handleBidPlaced);
            socket.off('auctionSync', handleAuctionSync);
        };
    }, []); // Empty dependency array means this only runs once on mount

    useEffect(() => {
        localStorage.setItem('featuredType', featuredType);
        socket.emit('updateFeaturedType', featuredType);
    }, [featuredType]);

    useEffect(() => {
        const interval = setInterval(() => {
            setTimers((prevTimers) => {
                const newTimers = new Map<number, number>();
                prevTimers.forEach((time, auctionId) => {
                    if (time > 0) {
                        newTimers.set(auctionId, time - 1);
                    }
                });
                return newTimers;
            });
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const handleAuctionDelete = (auctionId: number) => {
        setAuctions(prevAuctions => prevAuctions.filter(auction => auction.id !== auctionId));
        setTimers(prevTimers => {
            const newTimers = new Map(prevTimers);
            newTimers.delete(auctionId);
            return newTimers;
        });
    };

    const handleAuctionUpdate = (updatedAuction: Auction) => {
        setAuctions((prevAuctions) => {
            if (updatedAuction.status === 'active') {
                const existingAuction = prevAuctions.find(auction => auction.id === updatedAuction.id);
                if (existingAuction?.status === 'upcoming') {
                    setTimers(prevTimers => {
                        const newTimers = new Map(prevTimers);
                        newTimers.set(updatedAuction.id, updatedAuction.countdown_timer);
                        return newTimers;
                    });
                }
            }

            if (updatedAuction.status === 'completed' || updatedAuction.status === 'deleted') {
                return prevAuctions.filter(auction => auction.id !== updatedAuction.id);
            }

            if (updatedAuction.status !== 'upcoming' && updatedAuction.status !== 'active') {
                return prevAuctions.filter(auction => auction.id !== updatedAuction.id);
            }

            const existingAuction = prevAuctions.find(auction => auction.id === updatedAuction.id);
            
            if (existingAuction) {
                return prevAuctions.map(auction => 
                    auction.id === updatedAuction.id ? updatedAuction : auction
                );
            } else {
                return [...prevAuctions, updatedAuction];
            }
        });
    };

    const handleBidPlaced = (data: { auction: Auction; bid: Bid; uid: number; username: string }) => {
        const { auction, bid, uid, username } = data;

        setPrevBidders((prevMap) => {
            const newMap = new Map(prevMap);
            newMap.set(auction.id, { username, uid });
            return newMap;
        });

        setAuctions((prevAuctions) =>
            prevAuctions.map((a) => (a.id === auction.id ? { ...a, current_bid: auction.current_bid } : a))
        );

        setBids((prevBids) => [...prevBids, bid]);

        setTimers((prevTimers) => {
            const newTimers = new Map(prevTimers);
            newTimers.set(auction.id, auction.countdown_timer);
            return newTimers;
        });
    };

    const handleAuctionSync = (syncData: { auction_id: number; remainingTime: number }[]) => {
        setTimers((prevTimers) => {
            const newTimers = new Map(prevTimers);
            syncData.forEach(({ auction_id, remainingTime }) => {
                newTimers.set(auction_id, remainingTime);
            });
            return newTimers;
        });
    };

    const handleBid = async (auctionId: number) => {
        try {
            const auction = auctions.find((auction) => auction.id === auctionId);
            if (!auction) {
                showError('Auction not found. Please refresh the page.', 'error');
                return;
            }

            if (auction.status !== 'active') {
                showError('This auction is not active. You cannot place a bid at this time.', 'warning');
                return;
            }

            if (!token) {
                showError('You must be logged in to place a bid.', 'info');
                return;
            }

            await createBid(token, {
                auction_id: auctionId,
                bid_time: new Date().toISOString(),
                seconds_left: 0
            });
        } catch (error) {
            console.error('Failed to place bid', error);
            showError('Failed to place bid. Please try again.', 'error');
        }
    };

    return (
        <div className="container">
            {isLoading ? (
                <Loading 
                    message="Loading auctions..." 
                    subMessage="Fetching latest auction data" 
                />
            ) : (
                <>
                    <Banner slides={bannerSlides} />
                

                    <FilteredGrid
                        auctions={auctions}
                        products={products}
                        timers={timers}
                        prevBidders={prevBidders}
                        handleBid={handleBid}
                        currentPage={currentPage}
                        itemsPerPage={itemsPerPage}
                        onPageChange={setCurrentPage}
                        totalItems={auctions.length}
                        categories={categories}
                    />
                </>
            )}
        </div>
    );
};

export default IndexPage;
