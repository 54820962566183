import React, { useState, useEffect } from 'react';
import { Review } from '../../../../types';
import { getReviews, createReview, updateReview, deleteReview } from '../../utils/adminAPI';
const token = localStorage.getItem('token'); // or however you're storing the token

const AdminReviewComponent: React.FC = () => {
  const [reviews, setReviews] = useState<Review[]>([]);
  const [selectedReview, setSelectedReview] = useState<Review | null>(null);
  const [formData, setFormData] = useState<Partial<Review>>({
    user_id: undefined,
    auction_id: undefined,
    rating: 5,
    review_text: '',
    media:[]
  });

  useEffect(() => {
    fetchReviews();
  }, []);

  const fetchReviews = async () => {
    try {
      if(token){
      const response = await getReviews(token);
      setReviews(response.data);}
    } catch (error) {
      console.error('Failed to fetch reviews:', error);
    }
  };

  useEffect(() => {
    if (selectedReview) {
      setFormData({ ...selectedReview });
    } else {
      setFormData({
        user_id: undefined,
        auction_id: undefined,
        rating: 5,
        review_text: '',
        media:[],
      });
    }
  }, [selectedReview]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if(token){
      if (selectedReview) {
        await updateReview(token,selectedReview.id, formData as Review);
      } else {
        await createReview(token,formData as Review);
      }}
      fetchReviews();
      setSelectedReview(null);
      setFormData({
        user_id: undefined,
        auction_id: undefined,
        rating: 5,
        review_text: '',
        media:[],
      });
    } catch (error) {
      console.error('Failed to submit review:', error);
    }
  };

  const handleEdit = (review: Review) => {
    setSelectedReview(review);
  };

  const handleDelete = async (id: number) => {
    try {
      if(token)
      await deleteReview(token,id);
      fetchReviews();
    } catch (error) {
      console.error('Failed to delete review:', error);
    }
  };

  return (
    <div className="admin-review-component">
      <h2>Review Management</h2>
      
      <form onSubmit={handleSubmit} className="review-form">
        <input
          type="number"
          name="user_id"
          value={formData.user_id || ''}
          onChange={handleInputChange}
          placeholder="User ID"
        />
        <input
          type="number"
          name="auction_id"
          value={formData.auction_id || ''}
          onChange={handleInputChange}
          placeholder="Auction ID"
        />
        <input
          type="number"
          name="rating"
          value={formData.rating}
          onChange={handleInputChange}
          min="1"
          max="5"
          placeholder="Rating (1-5)"
        />
        <textarea
          name="review_text"
          value={formData.review_text}
          onChange={handleInputChange}
          placeholder="Review Text"
        />
        <input
          type="text"
          name="image_url"
          // value={formData.image_url}
          onChange={handleInputChange}
          placeholder="Image URL"
        />
        <button type="submit">
          {selectedReview ? 'Update Review' : 'Create Review'}
        </button>
      </form>

      <table className="review-list">
        <thead>
          <tr>
            <th>ID</th>
            <th>User ID</th>
            <th>Auction ID</th>
            <th>Rating</th>
            <th>Review Text</th>
            <th>Image</th>
            <th>Created At</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {reviews.map((review) => (
            <tr key={review.id}>
              <td>{review.id}</td>
              <td>{review.user_id}</td>
              <td>{review.auction_id}</td>
              <td>{review.rating}</td>
              <td>{review.review_text}</td>
              <td>
                {/* {review.image_url && (
                  <img src={review.image_url} alt="Review" width="50" height="50" />
                )} */}
              </td>
              <td>{review.created_at}</td>
              <td>
                <button onClick={() => handleEdit(review)}>Edit</button>
                <button onClick={() => handleDelete(review.id)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AdminReviewComponent;