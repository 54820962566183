// src/services/api.ts
import axios, { AxiosResponse } from 'axios';
import {
  User,
  Product,
  Auction,
  Bid,
  Invoice,
  Notification,
  Address,
  Review,
  GovernorData,
  PublicAuctionData,
  ProductCategory
} from '../../../types';

const API_URL = process.env.REACT_APP_API_URL|| 'http://localhost:4000'; // Replace with your backend URL



export const getAuctions = async (): Promise<AxiosResponse<Auction[]>> => {
  return axios.get(`${API_URL}/core/auctions`);
};

//emits a sync time event across all clients 
export const syncAuctionTimes = async (): Promise<any> => {
  try {
    const response = await axios.get(`${API_URL}/auctions/sync`);
    return response; // Return the data from the API response
  } catch (error) {
    console.error('Error synchronizing auction times:', error);
    throw error; // Propagate the error for handling in the component
  }
};
//verifies the time event with server boba
export const verifyAuction = async (id: number,uid:number): Promise<any> => {
  try {
    const response = await axios.get(`${API_URL}/auctions/verify/${id}/${uid}`);
    return response; // Return the data from the API response
  } catch (error) {
    console.error('Error synchronizing auction times:', error);
    throw error; // Propagate the error for handling in the component
  }
};

export const createBid = async (token:string, bidData: Omit<Bid, 'id'|'user_id'|'type'>): Promise<AxiosResponse<Bid>> => {
  return axios.post(`${API_URL}/auctions/bid`, bidData,{headers: {
    Authorization: `Bearer ${token}`
  }});
};



export const startAuction = async (id: number): Promise<AxiosResponse<Auction>> => {
  return axios.put(`${API_URL}/auctions/start/${id}`);
};
export const completeAuction = async (id: number): Promise<AxiosResponse<Auction[]>> => {
  return axios.put(`${API_URL}/auctions/completed/${id}`);
};

// Get auction by ID without auth
export const getAuctionById = async (id: number) => {
  return axios.get(`${API_URL}/core/auctions/${id}`);
};

// Fetch all products
export const getProducts = async (): Promise<AxiosResponse<Product[]>> => {
  return axios.get(`${API_URL}/core/products`);
};

// Get product by ID without auth
export const getProductById = async (id: number) => {
  return axios.get(`${API_URL}/core/products/${id}`);
};

// Fetch all reviews without auth
export const getReviews = async () => {
  return axios.get(`${API_URL}/core/reviews`);
};

export const getBids = async () => {
  return axios.get(`${API_URL}/core/bids`);
};
export const getBidsbyAuction = async (id: number): Promise<any> => {
  return axios.get(`${API_URL}/core/bids/${id}`);
};


export const postContact = async (name:string,email:string, subject:string,message:string):Promise<any> => {
  return axios.post(`${API_URL}/core/contact`, {name,email,subject,message});
};

export const getCompletedAuctions = async (): Promise<AxiosResponse<Auction[]>> => {
  return axios.get(`${API_URL}/core/completed-auctions`);
};


export const getWinners = async (): Promise<AxiosResponse<PublicAuctionData[]>> => {
  return axios.get(`${API_URL}/core/winners`);
};

// Add this new interface
// Add this new function
export const getGovernorData = async (): Promise<AxiosResponse<GovernorData>> => {
  return axios.get(`${API_URL}/auctions/governor`);
};

// Add these new API functions using the existing ProductCategory type
export const getCategories = async (): Promise<AxiosResponse<(ProductCategory & {
    item_count: number;
    active_auctions: number;
})[]>> => {
    return axios.get(`${API_URL}/core/categories`);
};

export const getCategoryById = async (id: number): Promise<AxiosResponse<{
    category: ProductCategory & {
        item_count: number;
        active_auctions: number;
    };
    auctions: (Auction & {
        product_title: string;
        product_description: string;
        product_image: string;
        product_rrp: number;
    })[];
}>> => {
    return axios.get(`${API_URL}/core/categories/${id}`);
};

// Optional: Add function to get auctions by category
export const getAuctionsByCategory = async (categoryId: number): Promise<AxiosResponse<{
    auctions: Auction[];
    products: Product[];
    total: number;
}>> => {
    return axios.get(`${API_URL}/core/auctions/category/${categoryId}`);
};

export const getReviewsByProduct = async (id: number) => {
    return axios.get(`${API_URL}/core/reviews/${id}`);
};
