import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getWinners, getBids } from '../../utils/coreAPI';
import { Star, User as UserIcon } from 'lucide-react';
import styles from './Winners.module.css';
import '../../styles/mobile.css';
import Banner from '../../components/Banner';
import {  Bid, Media, PublicAuctionData } from '../../../../types';
import Pagination from '../../components/pagination';
import Loading from '../../components/loading';

interface BannerSlide {
  image: string;
  title: string;
  description: string;
  link: string;
}

interface AuctionWithBidders extends PublicAuctionData {
    uniqueBidders?: number;
    review_media?: Media[];
    product_media?: Media[];
}

const WinnersPage: React.FC = () => {
    const [completedAuctions, setCompletedAuctions] = useState<AuctionWithBidders[]>([]);
    const [filter, setFilter] = useState<'all' | 'reviewed' | 'non-reviewed'>('all');
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10; // Adjust this number as needed
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const winnersResponse = await getWinners();
                const bidsResponse = await getBids();
                
                // Group bids by auction ID and count unique bidders
                const biddersByAuction = (bidsResponse.data as Bid[]).reduce((acc, bid) => {
                    if (!acc[bid.auction_id]) {
                        acc[bid.auction_id] = new Set();
                    }
                    acc[bid.auction_id].add(bid.user_id);
                    return acc;
                }, {} as Record<number, Set<number>>);

                // Combine winner data with unique bidder counts
                const auctionsWithBidders = winnersResponse.data.map(auction => ({
                    ...auction,
                    uniqueBidders: biddersByAuction[auction.id]?.size || 0
                }));

                setCompletedAuctions(auctionsWithBidders);
                setLoading(false);
            } catch (error) {
                console.log('Failed to fetch data', error);
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const renderStars = (rating: number) => {
        return Array(5).fill(0).map((_, i) => (
            <Star key={i} fill={i < rating ? "#FFD700" : "none"} stroke="#FFD700" />
        ));
    };

    const handleFilterChange = (filterType: 'all' | 'reviewed' | 'non-reviewed') => {
        setFilter(filterType);
    };

    const filteredAuctions = completedAuctions.filter((auction) => {
        const hasReview = Boolean(auction.rating);
        if (filter === 'reviewed') return hasReview;
        if (filter === 'non-reviewed') return !hasReview;
        return true;
    });

    // Calculate pagination
    const totalPages = Math.ceil(filteredAuctions.length / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const paginatedAuctions = filteredAuctions.slice(startIndex, startIndex + itemsPerPage);

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    const getDisplayImage = (auction: AuctionWithBidders) => {
        // First try to get review media
        if (auction.review_media && auction.review_media.length > 0) {
            const sortedReviewMedia = [...auction.review_media].sort((a, b) => a.order - b.order);
            return sortedReviewMedia[0].url;
        }
        
        // Fall back to product media
        if (auction.product_media && auction.product_media.length > 0) {
            const sortedProductMedia = [...auction.product_media].sort((a, b) => a.order - b.order);
            return sortedProductMedia[0].url;
        }
        
        return undefined;
    };

    const createBannerSlides = (): BannerSlide[] => {
        return completedAuctions
            .filter(auction => auction.rating && (auction.review_media?.length || auction.product_media?.length))
            .slice(0, 5)
            .map(auction => {
                const imageUrl = getDisplayImage(auction);
                if (!imageUrl) return null;

                return {
                    image: imageUrl,
                    title: auction.product_title,
                    description: `${auction.winner_username} saved £${(auction.recommended_retail_price - auction.current_bid/100).toFixed(2)} • ${auction.rating}★ rating`,
                    link: `/auction/${auction.id}`
                };
            })
            .filter((slide): slide is BannerSlide => slide !== null);
    };

    if (loading) return <Loading message="Loading winners..." subMessage="Fetching recent auction winners" />;

    return (
        <div className='container'>
            {completedAuctions.length > 0 && (
                <Banner 
                    slides={createBannerSlides()} 
                    autoPlayInterval={6000} 
                />
            )}
            <div className={styles.winnersPage}>

            <h1 className='sectionTitle'>Recent Winners</h1>
            <div className={styles.filterButtons}>
                <button
                    className={`${styles.filterButton} ${filter === 'all' ? styles.active : ''}`}
                    onClick={() => handleFilterChange('all')}
                >
                    All
                </button>
                <button
                    className={`${styles.filterButton} ${filter === 'reviewed' ? styles.active : ''}`}
                    onClick={() => handleFilterChange('reviewed')}
                >
                    Reviewed
                </button>
                <button
                    className={`${styles.filterButton} ${filter === 'non-reviewed' ? styles.active : ''}`}
                    onClick={() => handleFilterChange('non-reviewed')}
                >
                    Non-reviewed
                </button>
            </div>
            <div className={styles.auctionGrid} id="winners-grid">
                {paginatedAuctions.map((auction) => {
                    const imageUrl = getDisplayImage(auction);
                    const productImageUrl = auction.product_media?.[0]?.url;

                    return (
                        <Link to={`/auction/${auction.id}`} key={auction.id} className={styles.cardLink}>
                            <div className={styles.card}>
                                <div className={styles.imageContainer}>
                                    {imageUrl && (
                                        <img 
                                            src={imageUrl} 
                                            alt={auction.product_title} 
                                            className={styles.image} 
                                        />
                                    )}
                                </div>
                                <div className={styles.details}>
                                    <div className={styles.header}>
                                        {productImageUrl && (
                                            <img 
                                                src={productImageUrl} 
                                                alt={auction.product_title} 
                                                className={styles.thumbnail} 
                                            />
                                        )}
                                        {auction.rating && (
                                            <div className={styles.reviewSection}>
                                                <div className={styles.reviewRating}>
                                                    {renderStars(auction.rating)}
                                                </div>
                                                <p className={styles.reviewText}>
                                                    {auction.review_text?.slice(0, 75)}
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                    <h3 className={styles.productTitle}>{auction.product_title}</h3>
                                    <div className={styles.winnerInfo}>
                                        <UserIcon size={18} />
                                        <span>{auction.winner_username}</span>
                                    </div>
                                    <table className={styles.auctionInfo}>
                                        <tbody>
                                            <tr>
                                                <td><strong>Final Bid:</strong></td>
                                                <td>£{(auction.current_bid / 100).toFixed(2)}</td>
                                            </tr>
                                            <tr>
                                                <td><strong>Retail Price:</strong></td>
                                                <td><s>£{auction.recommended_retail_price}</s></td>
                                            </tr>
                                            <tr>
                                                <td><strong>Savings:</strong></td>
                                                <td>£{(auction.recommended_retail_price - auction.current_bid/100).toFixed(2)}</td>
                                            </tr>
                                            <tr>
                                                <td><strong>End Date:</strong></td>
                                                <td>{new Date(auction.start_time).toLocaleDateString()}</td>
                                            </tr>
                                            <tr>
                                                <td><strong>Unique Bidders:</strong></td>
                                                <td>{auction.uniqueBidders || 0}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </Link>
                    );
                })}
            </div>

            {totalPages > 1 && (
                <Pagination
                    currentPage={currentPage}
                    totalItems={filteredAuctions.length}
                    pageSize={itemsPerPage}
                    onPageChange={handlePageChange}
                    scrollToTop={true}
                    containerRef="#winners-grid"
                />
            )}
        </div></div>
    );
};

export default WinnersPage;