// WonAuctionsInvoiceHistory.tsx
import React, { useState, useEffect } from 'react';
import { Invoice, Auction, Product, Review, User, Address, Media } from '../../../../types';
import { getAuctions, getProducts, getReviews  } from '../../utils/coreAPI';
import { getUserInvoices, submitReview,getAddressDetails, updateInvoiceAddresses } from '../../utils/accountAPI';
import generateInvoice from '../../utils/accountAPI';
import styles from './WonOrderForm.module.css'; // Import your CSS module
import { useNavigate } from 'react-router-dom';
import { PayPalButtons } from "@paypal/react-paypal-js";
import { createPayPalOrder, capturePayPalOrder } from '../../utils/accountAPI';
import { getProductImage } from '../../utils/imageHelpers';

interface WonAuctionsInvoiceHistoryProps {
  token: string;
  userId: number;
  user: Partial<User>; // Add user prop
}

interface EnrichedInvoice extends Invoice {
  auction?: Auction;
  product?: Product;
  review?: Review | null;
  shipping_cost?: number; // Add shipping cost
  productImageUrl?: string; // Add this line
}

interface ReviewFormData {
  rating: number;
  comment: string;
  media: File[];
}

interface AddressSelectorModalProps {
  addresses: Address[];
  onSelectAddress: (address: Address) => void;
  onAddNewAddress: () => void;
  onClose: () => void;
}


const AddressSelectorModal: React.FC<AddressSelectorModalProps> = ({
  addresses,
  onSelectAddress,
  onAddNewAddress,
  onClose
}) => {
  const shippingAddresses = addresses.filter(addr => addr.address_type === 'SHIPPING');

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modal}>
        <h3>Select Shipping Address</h3>
        {shippingAddresses.length > 0 ? (
          <div className={styles.addressList}>
            {shippingAddresses.map((address) => (
              <div key={address.id} className={styles.addressCard}>
                <div className={styles.addressDetails}>
                  <p>{address.street_address}</p>
                  <p>{address.city}, {address.state_or_province}</p>
                  <p>{address.postal_code}</p>
                  <p>{address.country}</p>
                </div>
                <button
                  onClick={() => onSelectAddress(address)}
                  className={styles.selectAddressButton}
                >
                  Use This Address
                </button>
              </div>
            ))}
          </div>
        ) : (
          <p>No shipping addresses found.</p>
        )}
        <div className={styles.modalButtons}>
          <button onClick={onAddNewAddress} className={styles.submitButton}>
            Add New Address
          </button>
          <button onClick={onClose} className={styles.cancelButton}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

const PaymentForm: React.FC<{ invoice: EnrichedInvoice; onPaymentSuccess: () => void }> = ({ invoice, onPaymentSuccess }) => {
  const navigate = useNavigate();
  const [isProcessing, setIsProcessing] = useState(false);
  const [paymentError, setPaymentError] = useState('');
  const [showAddressSelector, setShowAddressSelector] = useState(true);
  const [selectedAddress, setSelectedAddress] = useState<Address | null>(null);
  const [addresses, setAddresses] = useState<Address[]>([]);
  const [currentStep, setCurrentStep] = useState<'initial' | 'shipping' | 'billing' | 'payment'>('initial');
  const [selectedShippingAddress, setSelectedShippingAddress] = useState<Address | null>(null);
  const [selectedBillingAddress, setSelectedBillingAddress] = useState<Address | null>(null);

  useEffect(() => {
    fetchAddresses();
  }, []);

  const fetchAddresses = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) return;
      
      const response = await getAddressDetails(token);
      setAddresses(response.data);
      
      const hasShipping = response.data.some(addr => addr.address_type === 'SHIPPING');
      if (!hasShipping) {
        // If no shipping address exists, keep modal open
        setShowAddressSelector(true);
      } else {
        // If shipping address exists, show selector
        setShowAddressSelector(true);
      }
    } catch (error) {
      console.error('Error fetching addresses:', error);
      setPaymentError('Error loading shipping addresses. Please try again.');
    }
  };

  const handleSelectAddress = (address: Address) => {
    setSelectedAddress(address);
    setShowAddressSelector(false);
  };

  const handleAddNewAddress = (addressType: 'shipping' | 'billing') => {
    navigate('/account', {
      state: {
        section: 'account',
        subsection: addressType === 'shipping' ? 'shippingAddress' : 'billingAddress',
        returnTo: '/account/wonAuctions'
      }
    });
  };

  const handleContinue = () => {
    setCurrentStep('shipping');
  };

  const handleSelectShippingAddress = async (address: Address) => {
    setSelectedShippingAddress(address);
    setCurrentStep('billing');
  };

  const handleSelectBillingAddress = async (address: Address) => {
    setSelectedBillingAddress(address);
    try {
      await updateInvoiceAddresses(
        localStorage.getItem('token') || '',
        invoice.id,
        selectedShippingAddress!.id,
        address.id
      );
      setCurrentStep('payment');
    } catch (error) {
      console.error('Error updating invoice addresses:', error);
      setPaymentError('Failed to update addresses. Please try again.');
    }
  };

  const handlePayPalCreateOrder = async () => {
    try {
      // Calculate total amount including postage
      const totalAmount = (invoice.amount || 0) + ((invoice.product?.postage || 0) / 100);
      
      const order = await createPayPalOrder(
        totalAmount,
        'GBP',
        invoice.id,
        undefined,
        undefined,
        invoice.type
      );
      return order.id;
    } catch (error) {
      console.error('PayPal create order error:', error);
      setPaymentError('Failed to create PayPal order');
      throw error;
    }
  };

  const handlePayPalApprove = async (data: any) => {
    try {
      const captureResult = await capturePayPalOrder(data.orderID);
      if (captureResult.status === 'COMPLETED') {
        onPaymentSuccess();
      } else {
        setPaymentError('Payment was not completed successfully');
      }
    } catch (error) {
      console.error('PayPal capture error:', error);
      setPaymentError('Failed to process PayPal payment');
    }
  };

  return (
    <div className={styles.paymentCard}>
      {currentStep === 'initial' && (
        <div className={styles.paymentCard}>
          {invoice.product && (
            <div className={styles.productImage}>
              <img 
                src={getProductImage(invoice.product)} 
                alt={invoice.product.title}
                className={styles.productThumbnail}
              />
            </div>
          )}
          <div className={styles.priceBreakdown}>
            <div className={styles.priceRow}>
              <span>Final Bid Price:</span>
              <span>£{(invoice.auction?.current_bid! / 100)}</span>
            </div>
            <div className={styles.priceRow}>
              <span>Postage & Packaging:</span>
              <span>£{(invoice.product?.postage! / 100)}</span>
            </div>
            <div className={styles.totalRow}>
              <span>Total:</span>
              <span>£{invoice.amount}</span>
            </div>
          </div>
          
          <button
            onClick={handleContinue}
            className={styles.continueButton}
          >
            Continue to Checkout
          </button>
        </div>
      )}

      {currentStep === 'shipping' && (
        <div className={styles.addressSection}>
          <h3 className={styles.sectionTitle}>Select Shipping Address</h3>
          <div className={styles.addressListContainer}>
            <div className={styles.addressList}>
              {addresses
                .filter(addr => addr.address_type === 'SHIPPING')
                .map((address) => (
                  <div key={address.id} className={styles.addressCard}>
                    <div className={styles.addressDetails}>
                      <p>{address.street_address}</p>
                      <p>{address.city}, {address.state_or_province}</p>
                      <p>{address.postal_code}</p>
                      <p>{address.country}</p>
                    </div>
                    <button
                      onClick={() => handleSelectShippingAddress(address)}
                      className={styles.selectAddressButton}
                    >
                      Use This Address
                    </button>
                  </div>
                ))}
            </div>
          </div>
          <button 
            onClick={() => handleAddNewAddress('shipping')} 
            className={styles.addAddressButton}
          >
            Add New Address
          </button>
        </div>
      )}

      {currentStep === 'billing' && (
        <div className={styles.addressSection}>
          <h3 className={styles.sectionTitle}>Select Billing Address</h3>
          <div className={styles.addressListContainer}>
            <div className={styles.addressList}>
              {addresses
                .filter(addr => addr.address_type === 'BILLING')
                .map((address) => (
                  <div key={address.id} className={styles.addressCard}>
                    <div className={styles.addressDetails}>
                      <p>{address.street_address}</p>
                      <p>{address.city}, {address.state_or_province}</p>
                      <p>{address.postal_code}</p>
                      <p>{address.country}</p>
                    </div>
                    <button
                      onClick={() => handleSelectBillingAddress(address)}
                      className={styles.selectAddressButton}
                    >
                      Use This Address
                    </button>
                  </div>
                ))}
            </div>
          </div>
          <button 
            onClick={() => handleAddNewAddress('billing')} 
            className={styles.addAddressButton}
          >
            Add New Address
          </button>
        </div>
      )}

      {currentStep === 'payment' && (
        <div className={styles.paymentSection}>
          <div className={styles.paymentSectionHeader}>
            <div className={styles.priceBreakdown}>
              <div className={styles.priceRow}>
                <span>Final Bid Price:</span>
                <span>£{(invoice.auction?.current_bid! / 100)}</span>
              </div>
              <div className={styles.priceRow}>
                <span>Postage & Packaging:</span>
                <span>£{(invoice.product?.postage! / 100)}</span>
              </div>
              <div className={styles.totalRow}>
                <span>Total:</span>
                <span>£{invoice.amount}</span>
              </div>
            </div>
            <div className={styles.paymentDescription}>
              Please enter your card details below to complete the purchase
            </div>
          </div>

          <div className={styles.addressesContainer}>
            <div className={styles.addressBox}>
              <h4>Shipping Address</h4>
              <div className={styles.addressDetails}>
                <p>{selectedShippingAddress?.street_address}</p>
                <p>{selectedShippingAddress?.city}, {selectedShippingAddress?.state_or_province}</p>
                <p>{selectedShippingAddress?.postal_code}</p>
                <p>{selectedShippingAddress?.country}</p>
              </div>
              <button
                onClick={() => setCurrentStep('shipping')}
                className={styles.changeAddressButton}
              >
                Change Shipping Address
              </button>
            </div>

            <div className={styles.addressBox}>
              <h4>Billing Address</h4>
              <div className={styles.addressDetails}>
                <p>{selectedBillingAddress?.street_address}</p>
                <p>{selectedBillingAddress?.city}, {selectedBillingAddress?.state_or_province}</p>
                <p>{selectedBillingAddress?.postal_code}</p>
                <p>{selectedBillingAddress?.country}</p>
              </div>
              <button
                onClick={() => setCurrentStep('billing')}
                className={styles.changeAddressButton}
              >
                Change Billing Address
              </button>
            </div>
          </div>
          
          <div className={styles.paymentOptions}>
            <div className={styles.paypalContainer}>
              <PayPalButtons
                createOrder={handlePayPalCreateOrder}
                onApprove={handlePayPalApprove}
                style={{ layout: "horizontal" }}
              />
            </div>

          </div>
        </div>
      )}
    </div>
  );
};

const WonAuctionsInvoiceHistory: React.FC<WonAuctionsInvoiceHistoryProps> = ({ token, userId, user }) => {
  const [invoices, setInvoices] = useState<EnrichedInvoice[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [reviewData, setReviewData] = useState<{ [key: number]: ReviewFormData }>({});
  const [shippingAddress, setShippingAddress] = useState<Address | null>(null);
  const [billingAddress, setBillingAddress] = useState<Address | null>(null);
  const [dropdowns, setDropdowns] = useState({
    new: false,
    toBeShipped: false,
    shipped: false,
    waitingReview: false,
    completed: false,
  });
  const [selectedInvoice, setSelectedInvoice] = useState<EnrichedInvoice | null>(null);
  const [reviewForm, setReviewForm] = useState<ReviewFormData>({
    rating: 0,
    comment: '',
    media: []
  });
  const [reviewImages, setReviewImages] = useState<Media[]>([]);

  useEffect(() => {
    fetchData();
  }, [userId, token]);

  const fetchData = async () => {
    setLoading(true);
    setError('');
    try {
      const [invoicesResponse, auctionsResponse, productsResponse, reviewsResponse, addressResponse] = await Promise.all([
        getUserInvoices(token),
        getAuctions(),
        getProducts(),
        getReviews(),
        getAddressDetails(token)
      ]);

      const enrichedInvoices = invoicesResponse
        .filter((invoice: Invoice) => invoice.user_id === userId)
        .map((invoice: Invoice) => {
          const auction = auctionsResponse.data.find((a: Auction) => a.id === invoice.auction_id);
          const product = productsResponse.data.find((p: Product) => p.id === auction?.product_id);
          const review = reviewsResponse.data.find((r: Review) => r.auction_id === invoice.auction_id);
          return { ...invoice, auction, product, review };
        })
        .filter((invoice: EnrichedInvoice) => invoice.auction && invoice.product);
      setInvoices(enrichedInvoices);
      setShippingAddress(addressResponse.data.find((a: Address) => a.user_id === userId && a.address_type === 'SHIPPING') || null);
      setBillingAddress(addressResponse.data.find((a: Address) => a.user_id === userId && a.address_type === 'BILLING') || null);
    } catch (err) {
      setError('Failed to fetch won auctions');
    } finally {
      setLoading(false);
    }
  };

  const toggleDropdown = (section: keyof typeof dropdowns) => {
    setDropdowns(prev => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  const handleReviewChange = (auctionId: number, field: keyof ReviewFormData, value: any) => {
    setReviewData(prevData => ({
      ...prevData,
      [auctionId]: {
        ...prevData[auctionId] || { rating: 0, comment: '', media: [] },
        [field]: field === 'media' ? Array.from(value) : value,
      },
    }));
  };

  const handleSubmitReview = async (auctionId: number) => {
    try {
      const review = reviewData[auctionId];
      if (!review) return;

      const formData = new FormData();
      formData.append('rating', review.rating.toString());
      formData.append('comment', review.comment);
      review.media.forEach((file) => {
        formData.append('media', file);
      });

      await submitReview(token, auctionId, formData);
      alert('Review submitted successfully!');
      fetchData();
    } catch (err) {
      alert('Failed to submit review. Please try again.');
    }
  };

  const handleGenerateInvoice = async (invoice: EnrichedInvoice) => {
    if (!invoice.auction || !invoice.product || !user) {
      alert('Missing required information to generate invoice.');
      return;
    }

    try {
      // Fetch all addresses for this user
      const addressResponse = await getAddressDetails(token);
      const addresses = addressResponse.data;
      
      // Find the specific shipping and billing addresses for this invoice
      const shippingAddress = addresses.find((addr: Address) => addr.id === invoice.shipping_address_id);
      const billingAddress = addresses.find((addr: Address) => addr.id === invoice.billing_address_id);

      if (!shippingAddress || !billingAddress) {
        console.error('Address IDs:', {
          shipping: invoice.shipping_address_id,
          billing: invoice.billing_address_id,
          availableAddresses: addresses.map(a => ({ id: a.id, type: a.address_type }))
        });
        alert('Could not find the correct shipping or billing address for this order.');
        return;
      }

      const invoiceData = {
        invoice,
        customer: user,
        product: invoice.product,
        auction: invoice.auction,
        shippingAddress,
        billingAddress
      };

      console.log('Generating invoice with data:', invoiceData);
      await generateInvoice(
        invoice,
        user,
        invoice.product,
        invoice.auction,
        shippingAddress,
        billingAddress
      );
    } catch (error) {
      console.error('Error generating invoice:', error);
      alert('Failed to generate invoice. Please try again.');
    }
  };

  const handlePaymentSuccess = (invoiceId: number) => {
    setInvoices(prevInvoices =>
      prevInvoices.map(invoice =>
        invoice.id === invoiceId ? { ...invoice, status: 'paid' } : invoice
      )
    );
  };

  if (loading) return <div>Loading won auctions...</div>;
  if (error) return <div>{error}</div>;

  const renderInvoiceList = (invoices: EnrichedInvoice[], section: string) => (
    <ul className={section === 'completed' ? styles.completedAuctionsList : styles.wonAuctionsList}>
      {invoices.map(invoice => (
        <li key={invoice.id} className={section === 'completed' ? styles.completedAuctionsItem : styles.wonAuctionsItem}>
          {section === 'completed' ? (
            <>
              <div className={styles.completedItemLeft}>
                {/* <img
                  src={invoice.product?.media_url || '/default-image.jpg'}
                  alt={invoice.product?.title}
                  className={styles.completedProductImage}
                /> */}
                <div className={styles.completedDetails}>
                  <p className={styles.completedItemDate}>
                    Completed on: {new Date(invoice.date).toLocaleDateString()}
                  </p>
                  <p className={styles.completedItemPrice}>Price: £{invoice.amount}</p>
                  <button
                    onClick={() => handleGenerateInvoice(invoice)}
                    className={styles.completedGenerateInvoice}
                  >
                    Generate Invoice
                  </button>
                </div>
              </div>
              <div className={styles.completedItemRight}>
                {invoice.review ? (
                  <div className={styles.completedReview}>
                    <h3 className={styles.completedReviewTitle}>Your Review</h3>
                    {/* {invoice.review.image_url && (
                      <img
                        src={`${process.env.REACT_APP_API_URL || 'http://localhost:4000'}${invoice.review.image_url}`}
                        alt="Review"
                        className={styles.completedReviewImage}
                      />
                    )} */}
                    <p className={styles.completedReviewRating}>
                      Rating: {invoice.review.rating} / 5
                    </p>
                    <p className={styles.completedReviewComment}>{invoice.review.review_text}</p>
                  </div>
                ) : (
                  <p>No review available.</p>
                )}
              </div>
            </>
          ) : (
            <>
              <div className={styles.wonItemHeader}>
                <img
                  // src={invoice.product?.media_url || '/default-image.jpg'}
                  alt={invoice.product?.title}
                  className={styles.wonItemImage}
                />
                <div className={styles.wonItemDetails}>
                  <strong>{invoice.product?.title || 'Unknown Product'}</strong>
                  <span className={styles.wonItemDate}>
                    {section === 'new'
                      ? 'Invoice Date: '
                      : section === 'toBeShipped'
                        ? 'Paid on: '
                        : section === 'shipped'
                          ? 'Shipped on: '
                          : section === 'waitingReview'
                            ? 'Delivered on: '
                            : 'Completed on: '}
                    {new Date(invoice.date).toLocaleDateString()}
                  </span>
                  <div className={styles.wonItemPrice}>Total: £{invoice.amount}</div>

                  {section !== 'new' && (
                    <button
                      onClick={() => handleGenerateInvoice(invoice)}
                      className={styles.bidButton}
                    >
                      Generate Invoice
                    </button>
                  )}
                </div>
              </div>

              {section === 'new' && (
                  <PaymentForm
                    invoice={invoice}
                    onPaymentSuccess={() => handlePaymentSuccess(invoice.id)}
                  />
              )}

              {section === 'waitingReview' && (
                <div className={styles.wonReviewForm}>
                  <h4 className={styles.sectionTitle}>Leave a Review</h4>
                  <div className={styles.creditsForm}>
                    <select
                      value={reviewData[invoice.auction_id!]?.rating || ''}
                      onChange={(e) => handleReviewChange(invoice.auction_id!, 'rating', Number(e.target.value))}
                      className={styles.creditsSelect}
                    >
                      <option value="">Rating</option>
                      {[1, 2, 3, 4, 5].map(num => (
                        <option key={num} value={num}>{num} Star{num !== 1 ? 's' : ''}</option>
                      ))}
                    </select>
                    <textarea
                      value={reviewData[invoice.auction_id!]?.comment || ''}
                      onChange={(e) => handleReviewChange(invoice.auction_id!, 'comment', e.target.value)}
                      placeholder="Write your review here..."
                      className={styles.creditsSelect}
                    />
                    <input
                      type="file"
                      accept="image/*"
                      multiple
                      onChange={(e) => handleReviewChange(invoice.auction_id!, 'media', e.target.files)}
                      className={styles.creditsSelect}
                    />
                    <button
                      onClick={() => handleSubmitReview(invoice.auction_id!)}
                      className={styles.bidButton}
                      disabled={!reviewData[invoice.auction_id!]?.rating || !reviewData[invoice.auction_id!]?.comment}
                    >
                      Submit Review
                    </button>
                  </div>
                </div>
              )}
            </>
          )}
        </li>
      ))}
    </ul>
  );

  return (
    <div className={styles.creditsSection}>
      <h3 className={styles.sectionTitle}>Won Auctions</h3>

      {['new', 'toBeShipped', 'shipped', 'waitingReview', 'completed'].map((section) => (
        <div key={section} className={styles.historySection}>
          <button onClick={() => toggleDropdown(section as keyof typeof dropdowns)} className={styles.historyToggle}>
            {section === 'new' ? 'Newly Won Auctions' :
              section === 'toBeShipped' ? 'To Be Shipped' :
                section === 'shipped' ? 'Shipped' :
                  section === 'waitingReview' ? 'Waiting for Review' :
                    'Completed Auctions'} ({
              invoices.filter(invoice => {
                // If there's a review, it's always completed
                if (invoice.review) return section === 'completed';
                
                // Otherwise, check the status
                if (section === 'new') return invoice.status === 'awaiting payment';
                if (section === 'toBeShipped') return invoice.status === 'paid';
                if (section === 'shipped') return invoice.status === 'shipped';
                if (section === 'waitingReview') return invoice.status === 'delivered' && !invoice.review;
                if (section === 'completed') return invoice.status === 'completed' || invoice.review !== null;
                return false;
              }).length
            })
            <span className={styles.toggleIcon}>{dropdowns[section as keyof typeof dropdowns] ? '▲' : '▼'}</span>
          </button>
          {dropdowns[section as keyof typeof dropdowns] && (
            <div className={styles.invoiceHistory}>
              {renderInvoiceList(
                invoices.filter(invoice => {
                  // If there's a review, it's always completed
                  if (invoice.review) return section === 'completed';
                  
                  // Otherwise, check the status
                  if (section === 'new') return invoice.status === 'awaiting payment';
                  if (section === 'toBeShipped') return invoice.status === 'paid';
                  if (section === 'shipped') return invoice.status === 'shipped';
                  if (section === 'waitingReview') return invoice.status === 'delivered' && !invoice.review;
                  if (section === 'completed') return invoice.status === 'completed' || invoice.review !== null;
                  return false;
                }),
                section
              )}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default WonAuctionsInvoiceHistory;
