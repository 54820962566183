// src/context/ErrorContext.tsx

import React, { createContext, useState, useContext, useCallback } from 'react';
import ErrorPopup from './ErrorPopup';
// import logger from '../utils/logger'; // Assume we have a logger utility

interface ErrorContextType {
  showError: (message: string, type?: 'error' | 'warning' | 'info', details?: any) => void;
}

const ErrorContext = createContext<ErrorContextType | undefined>(undefined);

export const ErrorProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [error, setError] = useState<{ message: string; type: 'error' | 'warning' | 'info'; details?: any } | null>(null);

  const showError = useCallback((message: string, type: 'error' | 'warning' | 'info' = 'error', details?: any) => {
    setError({ message, type, details });
    

  }, []);

  const closeError = useCallback(() => {
    setError(null);
  }, []);

  return (
    <ErrorContext.Provider value={{ showError }}>
      {children}
      {error && <ErrorPopup message={error.message} type={error.type} onClose={closeError} />}
    </ErrorContext.Provider>
  );
};

export const useError = () => {
  const context = useContext(ErrorContext);
  if (context === undefined) {
    throw new Error('useError must be used within an ErrorProvider');
  }
  return context;
};