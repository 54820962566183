import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { getAuctionById, getProductById, getReviews, getBids, getBidsbyAuction, createBid, syncAuctionTimes, getAuctions, getReviewsByProduct } from '../../utils/coreAPI';
import { Auction, Product, Review, Bid } from '../../../../types';
import { Clock, PoundSterlingIcon, Package, Star, Truck, Users, Eye } from 'lucide-react';
import io from 'socket.io-client';
import styles from './details.module.css'; // Adjust according to your style setup
import loadingStyles from '../../accounts/components/BiddingHistory.module.css';
import { useError } from '../../components/ErrorContext';
import { PayPalButtons } from "@paypal/react-paypal-js";
import { createPayPalOrder, capturePayPalOrder } from '../../utils/accountAPI';

const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:4000';
const socketUrl = apiUrl.endsWith('/api') ? apiUrl.slice(0, -4) : apiUrl; // Remove '/api' if it exists
const imageBaseUrl = process.env.REACT_APP_IMAGE_URL || 'https://ushop.bid';
const socket = io(socketUrl);

// Add this interface after the existing imports
interface BidWithUsername extends Bid {
  username: string;
}

// Add this helper function near the top of the component
const truncateUsername = (username: string): string => {
    return username.length > 10 ? username.slice(0, 10) + '...' : username;
};

const AuctionDetailsPage: React.FC = () => {
    const { showError } = useError();
    const { id } = useParams<{ id: string }>();
    const [searchParams] = useSearchParams();
    const [auction, setAuction] = useState<Auction | null>(null);
    const [product, setProduct] = useState<Product | null>(null);
    const [reviews, setReviews] = useState<Review[]>([]);
    const [bids, setBids] = useState<BidWithUsername[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [timers, setTimers] = useState<Map<number, number>>(new Map());
    const [secondsUntilLive, setSecondsUntilLive] = useState<number | null>(null);
    const [uniqueBidders, setUniqueBidders] = useState<number>(0);
    const [activeViewers, setActiveViewers] = useState<number>(0);
    const [totalViews, setTotalViews] = useState<number>(0);
    const [isMarket, setIsMarket] = useState<boolean>(false);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const viewMode = searchParams.get('mode');
                const isMarketView = viewMode === 'market';
                setIsMarket(isMarketView);

                if (isMarketView) {
                    // For market view
                    const [productData, reviewsData, auctionsData] = await Promise.all([
                        getProductById(parseInt(id!, 10)),
                        getReviewsByProduct(parseInt(id!, 10)),
                        getAuctions()
                    ]);

                    // Get all auctions for this product
                    const productAuctions = auctionsData.data.filter(
                        (auction: Auction) => auction.product_id === productData.data.id
                    );

                    // Get all reviews for auctions of this product
                    // const productReviews = reviewsData.data.filter((review: Review) => 
                    //     productAuctions.some(auction => auction.id === review.auction_id)
                    // );

                    setProduct(productData.data);
                    setReviews(reviewsData.data);
                } else {
                    // Existing auction fetch logic
                    const auctionData = await getAuctionById(parseInt(id!, 10));
                    setTotalViews(auctionData.data.views || 0);

                    const [productData, allReviewsData, allBidsData, syncTimesResponse] = await Promise.all([
                        getProductById(auctionData.data.product_id),
                        getReviewsByProduct(auctionData.data.product_id),
                        getBidsbyAuction(auctionData.data.id),
                        syncAuctionTimes(),
                    ]);

                    setAuction(auctionData.data);
                    setProduct(productData.data);
                    setBids(allBidsData.data.slice(0, 5));
                    setUniqueBidders(new Set(allBidsData.data.map((bid: Bid) => bid.user_id)).size);
                    setReviews(allReviewsData.data);
                    if (auctionData.data.status === 'upcoming') {
                        const startTime = new Date(auctionData.data.start_time).getTime();
                        const now = Date.now();
                        setSecondsUntilLive(Math.max(0, Math.floor((startTime - now) / 1000)));
                    }
                }

          
                setLoading(false);
            } catch (err) {
                console.error('Error fetching data:', err);
                setError('Failed to fetch details');
                setLoading(false);
            }
        };

        fetchData();
        
        // Only set up socket listeners for auction view
        if (!searchParams.get('mode')) {
            socket.on('auctionUpdate', handleAuctionUpdate);
            socket.on('bidPlaced', handleBidPlaced);
            socket.on('auctionSync', handleAuctionSync);

            if (auction?.id) {
                socket.emit('viewAuction', auction.id.toString());
            }

            return () => {
                socket.off('auctionUpdate', handleAuctionUpdate);
                socket.off('bidPlaced', handleBidPlaced);
                socket.off('auctionSync', handleAuctionSync);
                socket.off('viewerUpdate');
            };
        }
    }, [id, searchParams]);

    // Countdown timer effect for active auctions
    useEffect(() => {
        if (timers.size === 0) return;

        const interval = setInterval(() => {
            setTimers(prevTimers => {
                const newTimers = new Map(prevTimers);
                if (auction) {
                    const currentTime = newTimers.get(auction.id);
                    if (currentTime !== undefined && currentTime > 0) {
                        newTimers.set(auction.id, currentTime - 1);
                    } else {
                        newTimers.delete(auction.id);
                    }
                }
                return newTimers;
            });
        }, 1000);

        return () => clearInterval(interval);
    }, [auction]);

    // New effect for upcoming auction countdown
    useEffect(() => {
        if (secondsUntilLive === null || auction?.status !== 'upcoming') return;

        const interval = setInterval(() => {
            setSecondsUntilLive(prev => {
                if (prev === null || prev <= 0) {
                    clearInterval(interval);
                    return 0;
                }
                return prev - 1;
            });
        }, 1000);

        return () => clearInterval(interval);
    }, [secondsUntilLive, auction?.status]);

    const handleAuctionUpdate = (updatedAuction: Auction) => {
        setAuction(updatedAuction);
        if (updatedAuction.status === 'upcoming') {
            const startTime = new Date(updatedAuction.start_time).getTime();
            const now = Date.now();
            setSecondsUntilLive(Math.max(0, Math.floor((startTime - now) / 1000)));
        } else if (updatedAuction.status === 'active') {
            setTimers(prevTimers => {
                const newTimers = new Map(prevTimers);
                newTimers.set(updatedAuction.id, updatedAuction.countdown_timer);
                return newTimers;
            });
            setSecondsUntilLive(null);
        } else if (updatedAuction.status === 'completed') {
            setTimers(prevTimers => {
                const newTimers = new Map(prevTimers);
                newTimers.delete(updatedAuction.id);
                return newTimers;
            });
            setSecondsUntilLive(null);
        }
    };

    const handleBidPlaced = useCallback((data: { auction: Auction; bid: Bid; uid: number; username: string }) => {
        const { auction, bid, uid, username } = data;
        
        if (!id || auction.id !== parseInt(id, 10)) return;
        if (auction.status !== 'active') return;

        setAuction(prevAuction => 
            prevAuction && prevAuction.id === auction.id
                ? { ...prevAuction, current_bid: auction.current_bid }
                : prevAuction
        );

        // Update displayed bids (last 5)
        setBids(prevBids => {
            const newBids = [...prevBids, {...bid, username}]
                .sort((a, b) => new Date(b.bid_time).getTime() - new Date(a.bid_time).getTime())
                .slice(0, 5);
            return newBids;
        });

        // Fetch all bids and recalculate unique bidders
        getBidsbyAuction(auction.id).then(response => {
            const allBids = response.data;
            const uniqueBidderCount = new Set(allBids.map((b: Bid) => b.user_id)).size;
            setUniqueBidders(uniqueBidderCount);
        }).catch(error => {
            console.error('Error fetching bids:', error);
        });

        setTimers(prevTimers => {
            const newTimers = new Map(prevTimers);
            newTimers.set(auction.id, auction.countdown_timer);
            return newTimers;
        });
    }, [id]);

    const handleAuctionSync = (syncData: { auction_id: number; remainingTime: number }[]) => {
        setTimers((prevTimers) => {
            const newTimers = new Map(prevTimers);
            syncData.forEach(({ auction_id, remainingTime }) => {
                newTimers.set(auction_id, remainingTime);
            });
            return newTimers;
        });
    };


    const handleBid = async () => {
        try {
            if (auction?.status !== 'active') {
                showError('This auction is not active. You cannot place a bid at this time.', 'warning');
                return;
            }

            const bidData = {
                auction_id: auction.id,
                bid_time: new Date().toISOString(),
                seconds_left: auction.countdown_timer
            };

            const token = localStorage.getItem('token');
            if (!token) {
                showError('You must be logged in to place a bid.', 'error');
                return;
            }

            await createBid(token, bidData);
            socket.emit('placeBid', { auction_id: auction.id, bid: bidData });
        } catch (error) {
            console.error('Failed to place bid', error);
            showError('Failed to place bid. Please try again.', 'error');
        }
    };

    // // Calculate total pages
    // const totalPages = Math.ceil(bids.length / bidsPerPage);

    // // Get current bids for the page
    // const indexOfLastBid = currentPage * bidsPerPage;
    // const indexOfFirstBid = indexOfLastBid - bidsPerPage;
    // Sort bids in reverse chronological order before slicing
    const sortedBids = [...bids].sort((a, b) => 
        new Date(b.bid_time).getTime() - new Date(a.bid_time).getTime()
    );
    // const currentBids = sortedBids.slice(indexOfFirstBid, indexOfLastBid);

    // // Function to handle page change
    // const handlePageChange = (pageNumber: number) => {
    //     setCurrentPage(pageNumber);
    // };

    // Update PayPal handling
    const handlePayPalPurchase = async () => {
        try {
            // Calculate total amount including postage
            // Convert to number and fix to 2 decimal places to ensure proper formatting
            const totalAmount = Number(product?.rrp || 0) + Number((product?.postage || 0) / 100);
            const formattedTotal = totalAmount.toFixed(2);
            
            const response = await createPayPalOrder(
                Number(formattedTotal),
                'GBP',
                undefined,
                product?.id,
                undefined,
                'market'
            );
            return response.id;
        } catch (err) {
            console.error('Error creating PayPal order:', err);
            showError('Failed to create order. Please try again.', 'error');
            throw err;
        }
    };

    // Update viewer count based on auction status
    useEffect(() => {
        if (!auction) return;

        const getStatusBoundaries = (status: string) => {
            switch (status) {
                case 'active':
                    return { min: 4, max: 78 };
                case 'upcoming':
                    return { min: 14, max: 48 };
                case 'completed':
                    return { min: 7, max: 21 };
                default:
                    return { min: 0, max: 0 };
            }
        };

        const boundaries = getStatusBoundaries(auction.status);
        
        // Set initial active viewers
        const initialViewerCount = Math.floor(Math.random() * 
            (boundaries.max - boundaries.min + 1)) + boundaries.min;
        setActiveViewers(initialViewerCount);

        // Set total views as the maximum of actual views or status boundary
        const actualViews = auction.views || 0;
        const minimumTotalViews = boundaries.max;
        setTotalViews(Math.max(actualViews, minimumTotalViews));

        // Update periodically with variations based on status
        const interval = setInterval(() => {
            setActiveViewers(prev => {
                const variation = Math.floor(Math.random() * 5) - 2; // -2 to +2
                return Math.min(boundaries.max, Math.max(boundaries.min, prev + variation));
            });
        }, 2000);

        return () => clearInterval(interval);
    }, [auction?.status]);

    if (loading) return (
        <div className={loadingStyles.loading}>
            <div className={loadingStyles.loadingContainer}>
                <div className={loadingStyles.loadingRing}></div>
                <div className={loadingStyles.loadingRing}></div>
                <div className={loadingStyles.loadingRing}></div>
            </div>
            <p className="mt-6 text-gray-700 font-medium animate-pulse">
                Loading auction details...
            </p>
            <div className="mt-2 text-sm text-gray-500 animate-fade-in">
                Fetching latest information
            </div>
        </div>
    );
    if (error) return <div>{error}</div>;
    if (!product) return <div>Product not found</div>;
    if (!searchParams.get('mode') && !auction) return <div>Auction not found</div>;

    const formatTimeLeft = (seconds: number) => {
        const days = Math.floor(seconds / 86400);
        const hours = Math.floor((seconds % 86400) / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;

        if (days > 0) {
            return `${days}d ${hours}h ${minutes}m ${remainingSeconds}s`;
        } else if (hours > 0) {
            return `${hours}h ${minutes}m ${remainingSeconds}s`;
        } else {
            return `${minutes}m ${remainingSeconds}s`;
        }
    };

    return (
        <div className={styles.auctionDetailsPage}>
            <div className={styles.auctionDetailsHeader}>
                <h1>{product.title}</h1>
                {!searchParams.get('mode') && (
                    <div className={styles.auctionDetailsStatus}>{auction?.status}</div>
                )}
            </div>
            
            <div className={styles.auctionDetailsContent}>
                <div className={styles.auctionDetailsMain}>
                    {/* Product Media Gallery */}
                    {product?.media && product.media.length > 0 && (
                        <div className={`${styles.mediaGallery} ${product.media.length === 1 ? styles.oneImage : product.media.length === 2 ? styles.twoImages : styles.threeImages}`}>
                            {product.media
                                .sort((a, b) => a.order - b.order)
                                .map((media, index) => (
                                    <div key={media.id} className={styles.mediaItem}>
                                        <img 
                                            src={`${media.url}`}
                                            alt={`${product.title} - Image ${index + 1}`}
                                            className={styles.productImage}
                                        />
                                    </div>
                                ))
                            }
                        </div>
                    )}
                    
                    <div className={styles.auctionDetailsInfo}>
                        {searchParams.get('mode') === 'market' ? (
                            // Market View
                            <>
                                <div className={styles.marketPrice}>
                                    <PoundSterlingIcon size={24} />
                                    <span>Price: £{(product.rrp )}</span>
                                </div>
                                <div className={styles.paypalContainer}>
                                    <PayPalButtons
                                        createOrder={handlePayPalPurchase}
                                        onApprove={(data, actions) => {
                                            return handlePayPalPurchase().then(() => {
                                                return;
                                            });
                                        }}
                                        onError={(err) => {
                                            console.error('PayPal error:', err);
                                            showError('Payment failed. Please try again.', 'error');
                                        }}
                                    />
                                </div>
                            </>
                        ) : (
                            // Auction View - your existing auction logic
                            <>
                                {auction?.status === 'upcoming' && secondsUntilLive !== null && (
                                    <div className={styles.auctionDetailsTimeLeft}>
                                        <Clock size={24} />
                                        <span>Time Until Live: {formatTimeLeft(secondsUntilLive)}</span>
                                    </div>
                                )}
                                {auction?.status === 'active' && (
                                    <div className={styles.auctionDetailsTimeLeft}>
                                        <Clock size={24} />
                                        <span>Time Left: {formatTimeLeft(timers.get(auction.id) || 0)}</span>
                                    </div>
                                )}
                                {auction?.status === 'completed' && (
                                    <div className={styles.auctionDetailsStartDate}>
                                        <span>Started On: {new Date(auction.start_time).toLocaleString()}</span>
                                    </div>
                                )}

                                <div className={styles.auctionDetailsPrice}>
                                    <PoundSterlingIcon size={24} />
                                    <span>Current Bid: £{((auction?.current_bid || 0 )/100).toFixed(2)}</span>
                                </div>

                                <div className={styles.auctionDetailsBidders}>
                                    <Users size={24} />
                                    <span>Bidders: {uniqueBidders}</span>
                                </div>
                                
                                {auction?.status === 'active' && (
                                    <button className={styles.auctionDetailsBidButton} onClick={handleBid}>Place Bid</button>
                                )}
                                <div className={styles.auctionDetailsViews}>
                                    <Eye size={24} />
                                    <span>{activeViewers} watching now • {totalViews} total views</span>
                                </div>
                            </>
                        )}
                    </div>
                    
                    <div className={styles.auctionDetailsDescription}>
                        <h2>Product Description</h2>
                        <p>{product.description}</p>
                    </div>
                    
                    <div className={styles.auctionDetailsProductInfo}>
                        <h2>Product Details</h2>
                        <div className={styles.auctionDetailsProductItem}>
                            <Package size={18} />
                            <span>RRP: £{(product.rrp)}</span>
                        </div>
                        <div className={styles.auctionDetailsProductItem}>
                            <Truck size={18} />
                            <span>Postage: £{(product.postage / 100)}</span>
                        </div>
                    </div>
                </div>
                
                <div className={styles.auctionDetailsSidebar}>
                    <div className={styles.auctionDetailsReviews}>
                        <h2>Product Reviews</h2>
                        {reviews.length > 0 ? (
                            reviews.map(review => (
                                <div key={review.id} className={styles.auctionDetailsReviewItem}>
                                    <div className={styles.auctionDetailsReviewRating}>
                                        {Array(5).fill(0).map((_, i) => (
                                            <Star key={i} fill={i < review.rating ? "#FFD700" : "none"} stroke="#FFD700" size={18} />
                                        ))}
                                    </div>
                                    <p className={styles.auctionDetailsReviewText}>{review.review_text}</p>
                                    
                                    {/* Review Media Gallery */}
                                    {review.media && review.media.length > 0 && (
                                        <div className={styles.reviewMediaGallery}>
                                            {review.media
                                                .sort((a, b) => a.order - b.order)
                                                .map((media, index) => (
                                                    <div key={media.id} className={styles.reviewMediaItem}>
                                                        <img 
                                                            src={`${imageBaseUrl}${media.url}`}
                                                            alt={`Review Image ${index + 1}`}
                                                            className={styles.reviewImage}
                                                        />
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    )}
                                </div>
                            ))
                        ) : (
                            <p>No reviews yet for this product.</p>
                        )}
                    </div>

                    {!searchParams.get('mode') && (
                        <div className={styles.auctionDetailsBidHistory}>
                            <h2>Recent Bids</h2>
                            <ul>
                                {[...bids]
                                    .sort((a, b) => new Date(b.bid_time).getTime() - new Date(a.bid_time).getTime())
                                    .slice(0, 5)
                                    .map((bid) => (
                                        <li key={bid.id} className={styles.auctionDetailsBidItem}>
                                            <span>{truncateUsername(bid.username)}</span>
                                            <span>{new Date(bid.bid_time).toLocaleString()}</span>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AuctionDetailsPage;
