import React, { useState, useEffect } from 'react';
import styles from './CookiePopup.module.css';

const CookiePopup: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const hasAcceptedCookies = localStorage.getItem('cookiesAccepted');
    if (!hasAcceptedCookies) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookiesAccepted', 'true');
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className={styles.cookiePopup}>
      <div className={styles.content}>
        <h2>Cookie Policy</h2>
        <p>
          We use cookies to enhance your experience on our website. By continuing to use this site, you agree to our use of cookies.
        </p>
        <p>
          For more information, please read our <a href="/info">Privacy Policy</a>.
        </p>
        <button className={styles.acceptButton} onClick={handleAccept}>
          Accept
        </button>
      </div>
    </div>
  );
};

export default CookiePopup;
