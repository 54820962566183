import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Auction, Product } from '../../../../types';
import { getProducts } from '../../utils/coreAPI';
import { getAuctions } from '../../utils/coreAPI';
import { Calendar, Grid, List, Clock, PoundSterlingIcon, Eye } from 'lucide-react';
import styles from './upcoming.module.css';
import Banner from '../../components/Banner';
import Pagination from '../../components/pagination';
import Loading from '../../components/loading';

type ViewType = 'grid' | 'calendar' | 'list';

interface BannerSlide {
  image: string;
  title: string;
  description: string;
  link: string;
}

const UpcomingAuctions: React.FC = () => {
  const [auctions, setAuctions] = useState<Auction[]>([]);
  const [products, setProducts] = useState<Product[]>([]);
  const [view, setView] = useState<ViewType>('grid');
  const [currentDate, setCurrentDate] = useState(new Date());
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const token = localStorage.getItem('token');

  // Add pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize);
    setCurrentPage(1);
  };

  // Reset page when view changes
  useEffect(() => {
    setCurrentPage(1);
  }, [view]);

  // Calculate pagination
  const startIndex = (currentPage - 1) * pageSize;
  const paginatedAuctions = auctions.slice(startIndex, startIndex + pageSize);
  const totalPages = Math.ceil(auctions.length / pageSize);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
        const [auctionsResponse, productsResponse] = await Promise.all([
          getAuctions(),
          getProducts()
        ]);
        
        const sortedAndFilteredAuctions = auctionsResponse.data
          .filter(auction => new Date(auction.start_time) > new Date())
          .sort((a, b) => new Date(a.start_time).getTime() - new Date(b.start_time).getTime());

        setAuctions(sortedAndFilteredAuctions);
        setProducts(productsResponse.data);
        setLoading(false);
          } catch (err) {
      setError('Failed to fetch upcoming auctions');
      setLoading(false);
    }
  };

  // Memoize the product map and image URLs
  const productMap = useMemo(() => 
    new Map(products.map(product => [product.id, product])),
    [products]
  );

  // Memoize the getProductImage function
  const getProductImage = useCallback((product: Product) => {
    if (product?.media && product.media.length > 0) {
      const sortedMedia = [...product.media].sort((a, b) => a.order - b.order);
      return sortedMedia[0].url;
    }
    return undefined;
  }, []);

  // Memoize the image URLs for all products
  const productImageUrls = useMemo(() => 
    new Map(products.map(product => [product.id, getProductImage(product)])),
    [products, getProductImage]
  );

  const formatDate = (date: Date): string => {
    return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
  };

  const formatTime = (date: Date): string => {
    return date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
  };

  const getCountdown = (startTime: string): string => {
    const now = new Date();
    const start = new Date(startTime);
    const diff = start.getTime() - now.getTime();
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    return `${days}d ${hours}h ${minutes}m`;
  };

  // Memoized AuctionCard component
  const AuctionCard = React.memo(({ auction }: { auction: Auction }) => {
    const product = productMap.get(auction.product_id);
    if (!product) return null;
    const startTime = new Date(auction.start_time);
    const imageUrl = productImageUrls.get(product.id);

    return (
      <Link to={`/auction/${auction.id}`} className={styles.card}>
        <div className={styles.imageContainer}>
          {imageUrl && (
            <img 
              src={imageUrl} 
              alt={product.title} 
              className={styles.image}
            />
          )}
          <span className={`${styles.status} ${styles.statusUpcoming}`}>Upcoming</span>
        </div>
        <div className={styles.cardDetails}>
          <h3 className={styles.cardTitle}>{product.title}</h3>
          <p className={styles.cardInfo}><PoundSterlingIcon size={16} /> Starting Bid: £{(auction.current_bid/100).toFixed(2)}</p>
          <p className={styles.cardInfo}><Clock size={16} /> Starts: {formatDate(startTime)} at {formatTime(startTime)}</p>
          <p className={styles.cardInfo}><Eye size={16} /> Countdown: {getCountdown(auction.start_time)}</p>
        </div>
      </Link>
    );
  });

  const handleViewChange = (newView: ViewType) => {
    setView(newView);
    setCurrentPage(1);
  };

  const GridView = () => (
    <>
      <div className={styles.grid}>
        {paginatedAuctions.map(auction => (
          <AuctionCard key={auction.id} auction={auction} />
        ))}
      </div>
      
      {totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          totalItems={auctions.length}
          pageSize={pageSize}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          pageSizeOptions={[10, 20, 50]}
          scrollToTop={true}
          containerRef={`.${styles.viewToggleContainer}`}
        />
      )}
    </>
  );

  const CalendarView = () => {
    const daysInMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).getDay();
    const days = Array.from({ length: daysInMonth }, (_, i) => i + 1);

    return (
      <div className={styles.calendar}>
        <div className={styles.calendarHeader}>
          <button onClick={() => setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1))} className={styles.calendarButton}>
            Previous
          </button>
          <h3 className={styles.calendarTitle}>{currentDate.toLocaleString('default', { month: 'long', year: 'numeric' })}</h3>
          <button onClick={() => setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1))} className={styles.calendarButton}>
            Next
          </button>
        </div>
        <div className={styles.calendarGrid}>
          {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
            <div key={day} className={styles.calendarDayHeader}>{day}</div>
          ))}
          {Array(firstDayOfMonth).fill(null).map((_, index) => (
            <div key={`empty-${index}`} className={`${styles.calendarDay} ${styles.calendarDayEmpty}`}></div>
          ))}
          {days.map(day => {
            const date = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);
            const auctionsOnDay = auctions.filter(auction => {
              const auctionDate = new Date(auction.start_time);
              return auctionDate.getDate() === day && 
                     auctionDate.getMonth() === currentDate.getMonth() && 
                     auctionDate.getFullYear() === currentDate.getFullYear();
            });
            return (
              <div key={day} className={`${styles.calendarDay} ${auctionsOnDay.length > 0 ? styles.calendarDayHasAuctions : ''}`}>
                <span className={styles.dayNumber}>{day}</span>
                {auctionsOnDay.map(auction => (
                  <Link key={auction.id} to={`/auction/${auction.id}`} className={styles.calendarAuctionItem}>
                    {/* <img src={productMap.get(auction.product_id)?.media_url || 'default-image-url'} alt={productMap.get(auction.product_id)?.title} className={styles.calendarAuctionItemImage} /> */}
                    <span>{productMap.get(auction.product_id)?.title}</span>
                  </Link>
                ))}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  // Memoized ListView component
  const ListView = React.memo(() => (
    <>
      <div className={styles.list}>
        {paginatedAuctions.map(auction => {
          const product = productMap.get(auction.product_id);
          if (!product) return null;
          const startTime = new Date(auction.start_time);
          const imageUrl = productImageUrls.get(product.id);

          return (
            <Link key={auction.id} to={`/auction/${auction.id}`} className={styles.listItem}>
              <div className={styles.imageContainer}>
                {imageUrl && (
                  <img 
                    src={imageUrl}
                    alt={product.title} 
                    className={styles.listItemImage}
                  />
                )}
                <span className={`${styles.status} ${styles.statusUpcoming}`}>Upcoming</span>
              </div>
              <div className={styles.listItemDetails}>
                <h3 className={styles.listItemTitle}>{product.title}</h3>
                <p className={styles.listItemInfo}><PoundSterlingIcon size={16} /> Starting Bid: £{(auction.current_bid/100).toFixed(2)}</p>
                <p className={styles.listItemInfo}><Clock size={16} /> Starts: {formatDate(startTime)} at {formatTime(startTime)}</p>
                <p className={styles.listItemInfo}><Eye size={16} /> Countdown: {getCountdown(auction.start_time)}</p>
              </div>
            </Link>
          );
        })}
      </div>

      {totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          totalItems={auctions.length}
          pageSize={pageSize}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          pageSizeOptions={[10, 20, 50]}
          scrollToTop={true}
          containerRef={`.${styles.viewToggleContainer}`}
        />
      )}
    </>
  ));

  const createBannerSlides = (): BannerSlide[] => {
    return auctions.slice(0, 5).map(auction => {
      const product = productMap.get(auction.product_id);
      if (!product) return null;
      const imageUrl = productImageUrls.get(product.id);
      if (!imageUrl) return null;
      
      const startTime = new Date(auction.start_time);
      return {
        image: imageUrl,
        title: product.title,
        description: `Starting Bid: £${(auction.current_bid/100).toFixed(2)} • Starts: ${formatDate(startTime)} at ${formatTime(startTime)}`,
        link: `/auction/${auction.id}`
      };
    }).filter((slide): slide is BannerSlide => slide !== null);
  };

  if (loading) return <Loading message="Loading upcoming auctions..." subMessage="Fetching auction data" />;
  if (error) return <div className={styles.error}>{error}</div>;

  return (
    <div className='container'>
      {auctions.length > 0 && (
        <Banner slides={createBannerSlides()||[]} autoPlayInterval={6000} />
      )}
      <div className={styles.upcomingAuctions}>
        <h2 className="sectionTitle">Upcoming Auctions</h2>
        <div className={styles.viewToggleContainer}>
          <button 
            onClick={() => handlePageChange(currentPage - 1)}
            className={`${styles.navButton}`}
            disabled={currentPage === 1}
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15 18L9 12L15 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </button>

          <div className={styles.viewToggle}>
            <button 
              onClick={() => handleViewChange('grid')}
              className={`${styles.viewToggleButton} ${view === 'grid' ? styles.active : ''}`}
            >
              <Grid />
            </button>
            <button 
              onClick={() => handleViewChange('calendar')}
              className={`${styles.viewToggleButton} ${view === 'calendar' ? styles.active : ''}`}
            >
              <Calendar />
            </button>
            <button 
              onClick={() => handleViewChange('list')}
              className={`${styles.viewToggleButton} ${view === 'list' ? styles.active : ''}`}
            >
              <List />
            </button>
          </div>

          <button 
            onClick={() => handlePageChange(currentPage + 1)}
            className={`${styles.navButton}`}
            disabled={currentPage === totalPages}
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 6L15 12L9 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </button>
        </div>
        {view === 'grid' && <GridView />}
        {view === 'calendar' && <CalendarView />}
        {view === 'list' && <ListView />}
      </div>
    </div>
  );
};

export default UpcomingAuctions;