// src/components/ProductForm.tsx
import React, { useState, useEffect } from 'react';
import { Media, Product, ProductCategory } from '../../../../types';
import { createProduct, updateProduct, getCategories } from '../../utils/adminAPI';
import Papa from 'papaparse';

interface ProductFormProps {
  selectedProduct: Product | null;
  onSubmit: () => void;
}
const token = localStorage.getItem('token'); // or however you're storing the token

const ProductForm: React.FC<ProductFormProps> = ({ selectedProduct, onSubmit }) => {
  const [formData, setFormData] = useState<Omit<Product, 'id' | 'media'>>({
    title: '',
    description: '',
    rrp: 0,
    postage: 0,
    stock: 0,
    category_id: 0,
  });

  const [mediaPreview, setMediaPreview] = useState<Media[]>([]);
  const [categories, setCategories] = useState<ProductCategory[]>([]);

  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  useEffect(() => {
    if (selectedProduct) {
      const { id, media, ...productWithoutIdAndMedia } = selectedProduct;
      setFormData(productWithoutIdAndMedia);
      setMediaPreview(media || []);
    } else {
      setFormData({
        title: '',
        description: '',
        rrp: 0,
        postage: 0,
        stock: 0,
        category_id: 0,
      });
      setMediaPreview([]);
    }
  }, [selectedProduct]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        if (token) {
          const response = await getCategories(token);
          setCategories(response.data);
        }
      } catch (error) {
        console.error('Error fetching categories', error);
      }
    };
    fetchCategories();
  }, [token]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: name === 'rrp' || name === 'postage' || name === 'stock' ? Number(value) : value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if(token){
      if (selectedProduct) {
        await updateProduct(token,selectedProduct.id, formData);
      } else {
        await createProduct(token,formData);
      }
      onSubmit();}
    } catch (error) {
      console.error('Error saving product', error);
    }
  };

  const handleCSVImport = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    setSelectedFile(file || null);
  };

  const handleCSVSubmit = async () => {
    if (!selectedFile || !token) return;

    Papa.parse(selectedFile, {
      header: true,
      complete: async (results) => {
        for (const row of results.data as Array<any>) {
          // Create a cleaned row object by removing spaces from keys
          const cleanedRow = Object.entries(row).reduce((acc, [key, value]) => {
            acc[key.trim()] = value;
            return acc;
          }, {} as any);

          const product = {
            title: String(cleanedRow.title || '').trim(),
            description: String(cleanedRow.description || '').trim(),
            rrp: Number(cleanedRow.rrp || 0),
            postage: Number(cleanedRow.postage || 0),
            stock: Number(cleanedRow.stock || 0),
            category_id: Number(cleanedRow.category_id || 0),
          };
          
          console.log('Cleaned product:', product);
          await createProduct(token, product as Omit<Product, 'id' | 'media'>);
        }
        onSubmit();
      }
    });
  };

  const MediaPreview = () => (
    <div className="media-preview">
      <h3>Product Images</h3>
      <div className="media-grid">
        {mediaPreview.sort((a, b) => a.order - b.order).map((media, index) => (
          <div key={media.id || index} className="media-item">
            <img src={media.url} alt={`Product ${index + 1}`} />
            <div className="media-order">
              Order: {media.order}
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <form onSubmit={handleSubmit}>
      <div className="csv-import">
        <label htmlFor="csvImport">Import from CSV:</label>
        <input
          type="file"
          id="csvImport"
          accept=".csv"
          onChange={handleCSVImport}
        />
        <button type="button" onClick={handleCSVSubmit} disabled={!selectedFile}>
          Import Products from CSV
        </button>
        <small>CSV should include: title, description, rrp, postage, stock, media_url, category_id</small>
      </div>
      <label htmlFor="title">Title:</label>
      <input 
        type="text" 
        name="title" 
        value={formData.title} 
        onChange={handleChange} 
        placeholder="Title" 
        required 
      />
      
      <label htmlFor="description">Description:</label>
      <textarea 
        name="description" 
        value={formData.description} 
        onChange={handleChange} 
        placeholder="Description" 
        required 
      />
      
      <label htmlFor="rrp">RRP(pounds):</label>
      <input 
        type="number" 
        name="rrp" 
        value={formData.rrp} 
        onChange={handleChange} 
        placeholder="Price (RRP)" 
        step="0.01"
        required 
      />
      
      <label htmlFor="postage">P&P(pennies):</label>
      <input 
        type="number" 
        name="postage" 
        value={formData.postage} 
        onChange={handleChange} 
        placeholder="Postage Cost" 
        step="0.01"
        required 
      />
      
      <label htmlFor="stock">Stock:</label>
      <input 
        type="number" 
        name="stock" 
        value={formData.stock} 
        onChange={handleChange} 
        placeholder="Stock" 
        required 
      />
      
      <label htmlFor="category_id">Category:</label>
      <select
        name="category_id"
        value={formData.category_id}
        onChange={handleChange}
        required
      >
        <option value="">Select a category</option>
        {categories.map((category) => (
          <option key={category.id} value={category.id}>
            {category.name}
          </option>
        ))}
      </select>
      
      {mediaPreview.length > 0 && <MediaPreview />}

      <div className="form-group">
        <label>Product Images</label>
        <p>Use the Media Manager to add or edit product images after creating the product</p>
      </div>

      <button type="submit">
        {selectedProduct ? 'Update Product' : 'Create Product'}
      </button>
    </form>
  );
};

export default ProductForm;