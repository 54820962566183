import React, { useEffect, useState, useCallback } from 'react';
import { getAccountDetails, updateAccountDetails, updateAddressDetails, getAddressDetails, removeSubscription, ReferralResponse } from '../../utils/accountAPI';
import { User, Address } from '../../../../types';
import CreditsSection from '../components/CreditForm';
import BiddingHistory from '../components/BiddingHistory';
import { useNavigate, useLocation } from 'react-router-dom';
import WonAuctions from '../components/WonOrderForm';
import { deleteUser } from '../../utils/accountAPI';
import styles from './AccountPage.module.css';
import { subscribeToPushNotifications } from '../../utils/pushNotifications';
import AddressForm from '../components/AddressForm';
import ReferralInfo from '../components/ReferralInfo';
import MarketOrders from '../components/MarketOrders';
import PersonalInfoForm from '../components/PersonalInfoForm';

const AccountPage: React.FC = () => {
  const [user, setUser] = useState<Partial<ReferralResponse>>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');
  const token = localStorage.getItem('token') || '';
  const location = useLocation();
  const initialSection = location.state?.section || 'account';
  const [activeSection, setActiveSection] = useState<string>(initialSection);
  const [activeSubSection, setActiveSubSection] = useState<string | null>(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteConfirmationStep, setDeleteConfirmationStep] = useState(0);
  const navigate = useNavigate();
  const [pushNotificationsEnabled, setPushNotificationsEnabled] = useState<boolean>(false);

  useEffect(() => {
    if (token) {
      fetchAccountDetails();
    }
  }, [token]);

  useEffect(() => {
    if (location.state?.section) {
      setActiveSection(location.state.section);
      if (location.state?.subsection) {
        setActiveSubSection(location.state.subsection);
      }
    }
  }, [location.state]);

  const fetchAccountDetails = async () => {
    setLoading(true);
    try {
      const response = await getAccountDetails(token);
      setUser(response.data);
      setPushNotificationsEnabled(response.data.push_notifications);
      setLoading(false);
      setError('');
    } catch (err) {
      setError('Failed to fetch account details');
      setLoading(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUser(prevUser => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const handleAccSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccessMessage('');
    try {
      const response = await updateAccountDetails(token, user);
      setUser(response.data);
      setSuccessMessage('Account updated successfully');
    } catch (err) {
      setError('Failed to update account');
    } finally {
      setLoading(false);
    }
  };

  const handleSectionChange = (section: string) => {
    setActiveSection(section);
    setActiveSubSection(null);
  };

  const handleSubSectionChange = (subSection: string) => {
    setActiveSubSection(activeSubSection === subSection ? null : subSection);
  };

  const handleLogout = useCallback(() => {
    localStorage.removeItem('token');
    navigate('/');
  }, [navigate]);

  const handleDeleteAccount = async () => {
    if (deleteConfirmationStep < 2) {
      setDeleteConfirmationStep(prevStep => prevStep + 1);
    } else {
      try {
        if(token)
        await deleteUser(token);
        localStorage.removeItem('token');
        navigate('/');
      } catch (err) {
        setError('Failed to delete account');
      }
    }
  };

  const resetDeleteConfirmation = () => {
    setShowDeleteConfirmation(false);
    setDeleteConfirmationStep(0);
  };

  const togglePushNotifications = async () => {
    try {
      if (pushNotificationsEnabled) {
        const updatedUser = await updateAccountDetails(token, { 
          ...user, 
          push_notifications: !pushNotificationsEnabled 
        });
        
        setUser(updatedUser.data);
        // Disable push notifications
        const registration = await navigator.serviceWorker.ready;
        const subscription = await registration.pushManager.getSubscription();
        if (subscription) {
          await removeSubscription(subscription);
          await subscription.unsubscribe();
        }
      } else {
        // Enable push notifications
        const subscription = await subscribeToPushNotifications();
        if (!subscription) {
          throw new Error('Failed to subscribe to push notifications');
        }
      }
            // Update user preferences on the server
      
      setPushNotificationsEnabled(!pushNotificationsEnabled);
      setSuccessMessage(`Push notifications ${!pushNotificationsEnabled ? 'enabled' : 'disabled'} successfully`);
    } catch (error) {
      console.error('Error toggling push notifications:', error);
      setError('Failed to update push notification settings');
    }
  };

  const handleAddressFormComplete = useCallback(() => {
    // Check if we need to return to credits section
    if (location.state?.returnTo === '/account/credits') {
      setActiveSection('credits');
      setActiveSubSection(null);
      // Clear the location state
      navigate('.', { replace: true, state: {} });
    }
  }, [navigate, location.state]);

  if (loading) return <div className="loading">Loading...</div>;
  return (
    <div className={styles.accountPage}>
      <h1 className={styles.pageTitle}>Your UShop.bid Account</h1>
      {successMessage && <p className={styles.successMessage}>{successMessage}</p>}
      {error && <p className={styles.errorMessage}>{error}</p>}

      <div className={styles.accountSections}>
        <div className={styles.sectionTabs}>
          {['account', 'credits', 'wonAuctions', 'marketOrders', 'biddingHistory'].map((section) => (
            <button
              key={section}
              className={`${styles.sectionTab} ${activeSection === section ? styles.active : ''}`}
              onClick={() => handleSectionChange(section)}
            >
              {section.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}
            </button>
          ))}
        </div>

        <div className={styles.sectionContent}>
          {activeSection === 'account' && (
            <div>
              {/* <h2 className={styles.title}>Account</h2> */}
              <div className={styles.subsectionTabs}>
                {['personalInfo', 'shippingAddress', 'billingAddress', 'accountActions'].map((subSection) => (
                  <button
                    key={subSection}
                    className={`${styles.subsectionTab} ${activeSubSection === subSection ? styles.active : ''}`}
                    onClick={() => handleSubSectionChange(subSection)}
                  >
                    {subSection.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}
                  </button>
                ))}
              </div>

              {activeSubSection === 'personalInfo' && (
                <div className={styles.formContainer}>
                  <PersonalInfoForm
                    user={user}
                    token={token}
                    onUpdate={(updatedUser) => setUser(prev => ({ ...prev, ...updatedUser }))}
                    onSuccess={(message) => setSuccessMessage(message)}
                  />
                </div>
              )}
              {activeSubSection === 'shippingAddress' && (
                <AddressForm token={token} type="SHIPPING" />
              )}

              {activeSubSection === 'billingAddress' && (
                <AddressForm token={token} type="BILLING" />
              )}

              {activeSubSection === 'accountActions' && (
                <div className={styles.accountActionsContainer}>
                  <h2 className={styles.formTitle}>Account Actions</h2>

                  <div className={styles.actionsGrid}>
                    <div className={styles.actionCard}>
                      <h3 className={styles.actionTitle}>Push Notifications</h3>
                      <button
                        className={`${styles.actionButton} ${pushNotificationsEnabled ? styles.enabled : styles.disabled}`}
                        onClick={togglePushNotifications}
                      >
                        {pushNotificationsEnabled ? 'Disable' : 'Enable'}
                      </button>
                    </div>


            

                    <div className={styles.actionCard}>
                      <h3 className={styles.actionTitle}>Account Security Settings</h3>
                      <button
                        className={`${styles.actionButton} ${styles.placeholderButton}`}
                        onClick={() => alert('Feature coming soon!')}
                      >
                        Coming Soon
                      </button>
                    </div>


                    <div className={styles.actionCard}>
                      <h3 className={styles.actionTitle}>Logout</h3>
                      <button
                        className={`${styles.actionButton} ${styles.logoutButton}`}
                        onClick={handleLogout}
                      >
                        Logout
                      </button>
                    </div>


                    <div className={styles.actionCard}>
                      <h3 className={styles.actionTitle}>Delete Account</h3>
                      <button
                        className={`${styles.actionButton} ${styles.deleteButton}`}
                        onClick={() => setShowDeleteConfirmation(true)}
                      >
                        Delete Account
                      </button>
                    </div>


                  </div>
                </div>
              )}
            </div>
          )}

          {activeSection === 'credits' && (
            <div>
           
              <CreditsSection
                token={token}
                currentCredits={user.credits || 0}
                onCreditsAdded={(newCredits) => setUser(prev => ({ ...prev, credits: newCredits }))}
              />
                 <ReferralInfo
                 currentUsername={user.username}
                referrer={user.referrer_username ? {
                  username: user.referrer_username,
                  first_name: user.referrer_first_name || '',
                  last_name: user.referrer_last_name || ''
                } : undefined}
                referrals={user.referrals || []}
              />
            </div>
          )}

          {activeSection === 'wonAuctions' && (
            <WonAuctions token={token} userId={user.id || 0} user={user} />
          )}
          {activeSection === 'marketOrders' && (
            <MarketOrders token={token} userId={user.id || 0} user={user} />
          )}

          {activeSection === 'biddingHistory' && (
            <BiddingHistory token={token} />
          )}
        </div>
      </div>

      {showDeleteConfirmation && (
        <div className={styles.confirmationModal}>
          <h3>Are you sure you want to delete your account?</h3>
          <p>This action cannot be undone. All your data will be permanently deleted.</p>
          <p>Confirmation step: {deleteConfirmationStep + 1} of 3</p>
          <button
            className={styles.deleteButton}
            onClick={handleDeleteAccount}
          >
            {deleteConfirmationStep === 0 ? 'Yes, I want to delete my account' :
              deleteConfirmationStep === 1 ? 'Yes, I confirm account deletion' :
                'Delete my account permanently'}
          </button>
          <button
            className={styles.cancelButton}
            onClick={resetDeleteConfirmation}
          >
            Cancel
          </button>
        </div>
      )}
    </div>
  );
};

export default AccountPage;
