import React, { useState, useEffect } from 'react';
import { User } from '../../../../types';
import { createUser, updateUser } from '../../utils/adminAPI';
import styles from './UserForm.module.css';

interface UserFormProps {
  selectedUser: User | null;
  onSubmit: () => void;

}
const token = localStorage.getItem('token'); // or however you're storing the token


const UserForm: React.FC<UserFormProps> = ({ selectedUser, onSubmit }) => {
  const [formData, setFormData] = useState<Omit<User, 'id'>>({
    username: '',
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
    password: '',
    credits: 0,
    reg_date: new Date().toISOString(),
    last_login:  new Date().toISOString(),
    is_active: true,
    type: 0,
    push_notifications: false,
  });
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (selectedUser) {
      setFormData({
        ...selectedUser,
        password: '', // Clear password when editing
      });
    } else {
      setFormData({
        username: '',
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        password: '',
        credits: 0,
        reg_date: new Date().toISOString(),
        last_login:  new Date().toISOString(),
        is_active: true,
        type: 0,
        push_notifications: false,
      });
    }
  }, [selectedUser]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: type === 'number' ? Number(value) : 
              type === 'checkbox' ? (e.target as HTMLInputElement).checked : 
              value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    try {
      if(token){
        if (selectedUser) {
          await updateUser(token, selectedUser.id, formData);
        } else {
          await createUser(token, formData);
        }
        onSubmit();
      }
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.detail) {
        // Use the specific error message from the backend
        setError(err.response.data.detail);
      } else if (err.response && err.response.status === 409) {
        setError('A user with this username or email already exists. Please choose a different one.');
      } else {
        setError('An error occurred while saving the user. Please try again.');
      }
      console.error('Error saving user', err);
    }
  };

  const userTypes = [
    { value: 0, label: 'Admin' },
    { value: 1, label: 'New User' },
    { value: 2, label: 'Topped Up' },
    { value: 3, label: 'Auction Winner' },
    { value: 4, label: 'VIP' },
    { value: 5, label: 'Suspicious User' },
    { value: 6, label: 'Banned' },
    { value: 7, label: 'Deleted' }
  ];

  const formatDateForInput = (dateString: string | undefined) => {
    if (!dateString) return '';
    return new Date(dateString).toISOString().slice(0, 16);
  };

  return (
    <form onSubmit={handleSubmit} className={styles.form}>
      {error && <div className={styles.errorMessage}>{error}</div>}
      <div className={styles.formGroup}>
        <label htmlFor="username" className={styles.formLabel}>Username</label>
        <input
          type="text"
          id="username"
          name="username"
          value={formData.username}
          onChange={handleChange}
          required
          className={styles.formInput}
        />
      </div>
      <div className={styles.formGroup}>
        <label htmlFor="first_name" className={styles.formLabel}>First Name</label>
        <input
          type="text"
          id="first_name"
          name="first_name"
          value={formData.first_name}
          onChange={handleChange}
          required
          className={styles.formInput}
        />
      </div>
      <div className={styles.formGroup}>
        <label htmlFor="last_name" className={styles.formLabel}>Last Name</label>
        <input
          type="text"
          id="last_name"
          name="last_name"
          value={formData.last_name}
          onChange={handleChange}
          required
          className={styles.formInput}
        />
      </div>
      <div className={styles.formGroup}>
        <label htmlFor="phone" className={styles.formLabel}>Phone</label>
        <input
          type="tel"
          id="phone"
          name="phone"
          value={formData.phone || ''}
          onChange={handleChange}
          className={styles.formInput}
        />
      </div>
      <div className={styles.formGroup}>
        <label htmlFor="email" className={styles.formLabel}>Email</label>
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
          className={styles.formInput}
        />
      </div>
      <div className={styles.formGroup}>
        <label htmlFor="password" className={styles.formLabel}>Password</label>
        <input
          type="password"
          id="password"
          name="password"
          value={formData.password}
          onChange={handleChange}
          required={!selectedUser}
          className={styles.formInput}
        />
      </div>
      <div className={styles.formGroup}>
        <label htmlFor="credits" className={styles.formLabel}>Credits</label>
        <input
          type="number"
          id="credits"
          name="credits"
          value={formData.credits}
          onChange={handleChange}
          required
          className={styles.formInput}
        />
      </div>
      <div className={styles.formGroup}>
        <label htmlFor="reg_date" className={styles.formLabel}>Registration Date</label>
        <input
          type="datetime-local"
          id="reg_date"
          name="reg_date"
          value={formatDateForInput(formData.reg_date)}
          onChange={handleChange}
          className={styles.formInput}
        />
      </div>
      <div className={styles.formGroup}>
        <label htmlFor="last_login" className={styles.formLabel}>Last Login</label>
        <input
          type="datetime-local"
          id="last_login"
          name="last_login"
          value={formatDateForInput(formData.last_login)}
          onChange={handleChange}
          className={styles.formInput}
        />
      </div>
      <div className={styles.formGroup}>
        <label htmlFor="is_active" className={styles.formLabel}>Active</label>
        <input
          type="checkbox"
          id="is_active"
          name="is_active"
          checked={formData.is_active}
          onChange={handleChange}
          className={styles.formCheckbox}
        />
      </div>
      <div className={styles.formGroup}>
        <label htmlFor="type" className={styles.formLabel}>User Type</label>
        <select
          id="type"
          name="type"
          value={formData.type}
          onChange={handleChange}
          className={styles.formSelect}
        >
          {userTypes.map(type => (
            <option key={type.value} value={type.value}>
              {type.label}
            </option>
          ))}
        </select>
      </div>
      <div className={styles.formActions}>
        <button type="submit" className={styles.submitButton}>
          {selectedUser ? 'Update User' : 'Create User'}
        </button>
      </div>
    </form>
  );
};

export default UserForm;

