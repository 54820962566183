import React, { useState, useMemo } from 'react';
import { User, Address } from '../../../../types';
import { deleteAddress, deleteUser, updateUser, updateAddress } from '../../utils/adminAPI';
import { Mail, Phone, Calendar, Clock, CreditCard, UserCheck, UserX } from 'lucide-react';
import styles from './UserList.module.css';
import UserForm from './UserForm';
import AddressForm from './AddressForm';

interface UserListProps {
  users: User[];
  addresses: Address[];
  onEdit: (user: User) => void;
  onEditAddress: (address: Address) => void;
  onAddAddress: (userId: number) => void;
  fetchUsers: () => void;
  fetchAddresses: () => void;
}

const UserList: React.FC<UserListProps> = ({ users, addresses, onEdit, onEditAddress, onAddAddress, fetchUsers, fetchAddresses }) => {
  const [searchText, setSearchText] = useState('');
  const [selectedTypes, setSelectedTypes] = useState<string[]>(['0', '1', '2', '3', '4', '5']); // Initialize with all types except banned and deleted
  const [editingUser, setEditingUser] = useState<User | null>(null);
  const [editingAddress, setEditingAddress] = useState<Address | null>(null);
  const [editingUserId, setEditingUserId] = useState<number | null>(null);  // For adding new address
  const token = localStorage.getItem('token');

  const handleDelete = async (id: number) => {
    if (token) {
      await deleteUser(token, id);
      fetchUsers();
    }
  };

  const handleDeleteAddress = async (id: number) => {
    if (token) {
      await deleteAddress(token, id);
      fetchAddresses();
    }
  };

  const handleUpdateUser = async (updatedUser: User) => {
    if (token && updatedUser) {
      await updateUser(token, updatedUser.id, updatedUser);
      fetchUsers();
      setEditingUser(null);
    }
  };

  const handleUpdateAddress = async (updatedAddress: Address) => {
    if (token && updatedAddress) {
      await updateAddress(token, updatedAddress.id, updatedAddress);
      fetchAddresses();
      setEditingAddress(null);
    }
  };

  const filteredUsers = useMemo(() => {
    return users.filter(user => {
      const matchesSearch = Object.values(user).some(value => 
        value?.toString().toLowerCase().includes(searchText.toLowerCase())
      );
      
      const matchesType = selectedTypes.includes(user.type.toString());
      
      return matchesSearch && matchesType;
    });
  }, [users, searchText, selectedTypes]);

  const getUserTypeLabel = (type: number) => {
    switch (type) {
      case 0: return 'Admin';
      case 1: return 'New User';
      case 2: return 'Topped Up';
      case 3: return 'Auction Winner';
      case 4: return 'VIP';
      case 5: return 'Suspicious User';
      case 6: return 'Banned';
      case 7: return 'Deleted';
      default: return 'Unknown';
    }
  };

  const handleTypeChange = (type: string) => {
    setSelectedTypes(prev => 
      prev.includes(type) ? prev.filter(t => t !== type) : [...prev, type]
    );
  };

  const handleCancelEditUser = () => {
    setEditingUser(null);
  };

  const handleCancelEditAddress = () => {
    setEditingAddress(null);
    setEditingUserId(null);
  };

  return (
    <div className={styles.userList}>
      <h2>User List</h2>
      <div className={styles.filterControls}>
        <input
          type="text"
          placeholder="Search users"
          value={searchText}
          onChange={e => setSearchText(e.target.value)}
          className={styles.searchInput}
        />
        <div className={styles.typeCheckboxes}>
          {[0, 1, 2, 3, 4, 5, 6, 7].map(type => (
            <label key={type}>
              <input
                type="checkbox"
                checked={selectedTypes.includes(type.toString())}
                onChange={() => handleTypeChange(type.toString())}
              />
              {getUserTypeLabel(type)}
            </label>
          ))}
        </div>
      </div>
      {filteredUsers.map(user => (
        <div key={user.id} className={styles.userRow}>
          <div className={styles.userCard}>
            {editingUser && editingUser.id === user.id ? (
              <UserForm
                selectedUser={editingUser}
                onSubmit={() => {
                  fetchUsers();
                  handleCancelEditUser();
                } }  />
            ) : (
              <>
                <h3>{user.username}</h3>
                <p>{user.first_name} {user.last_name}</p>
                <div className={styles.userInfo}>
                  <span><Mail size={16} /> {user.email}</span>
                  <span><Phone size={16} /> {user.phone || 'N/A'}</span>
                  <span><Calendar size={16} /> Registered: {new Date(user.reg_date || '').toLocaleDateString()}</span>
                  <span><Clock size={16} /> Last Login: {user.last_login ? new Date(user.last_login).toLocaleDateString() : 'N/A'}</span>
                  <span><CreditCard size={16} /> Credits: {user.credits}</span>
                  <span>{user.is_active ? <UserCheck size={16} /> : <UserX size={16} />} {user.is_active ? 'Active' : 'Inactive'}</span>
                  <span>Type: {getUserTypeLabel(user.type)}</span>
                </div>
                <div className={styles.userActions}>
                  <button onClick={() => setEditingUser(user)} className={styles.editButton}>Edit</button>
                  <button onClick={() => handleDelete(user.id)} className={styles.deleteButton}>Delete</button>
                </div>
              </>
            )}
          </div>
          <div className={styles.addressManagement}>
            <h4>Addresses</h4>
            <button onClick={() => setEditingUserId(user.id)} className={styles.addAddressButton}>Add Address</button>
            {editingUserId === user.id && (
              <AddressForm
                selectedAddress={null}
                userId={user.id}
                onSubmit={() => {
                  fetchAddresses();
                  handleCancelEditAddress();
                } } onCancel={function (): void {
                  throw new Error('Function not implemented.');
                } }              />
            )}
            <table className={styles.addressTable}>
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Address</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {addresses.filter(address => address.user_id === user.id).map(address => (
                  <tr key={address.id}>
                    <td>{address.address_type}</td>
                    <td>{`${address.street_address}, ${address.city}, ${address.state_or_province}, ${address.postal_code}, ${address.country}`}</td>
                    <td>
                      {editingAddress && editingAddress.id === address.id ? (
                        <AddressForm
                          selectedAddress={editingAddress}
                          userId={user.id}
                          onSubmit={() => {
                            fetchAddresses();
                            handleCancelEditAddress();
                          }}
                          onCancel={handleCancelEditAddress}
                        />
                      ) : (
                        <>
                          <button onClick={() => setEditingAddress(address)} className={styles.editButton}>Edit</button>
                          <button onClick={() => handleDeleteAddress(address.id)} className={styles.deleteButton}>Delete</button>
                        </>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ))}
    </div>
  );
};

export default UserList;
