import React, { useState, useEffect } from 'react';
import AuctionList from '../components/AuctionList';
import AuctionForm from '../components/AuctionForm';
import BatchAuctionScheduler from '../components/BatchAuctionScheduler';
import { getAuctions, createBatchAuctions } from '../../utils/adminAPI';
import { Auction } from '../../../../types';

const AuctionsPage: React.FC = () => {
  const [auctions, setAuctions] = useState<Auction[]>([]);
  const [selectedAuction, setSelectedAuction] = useState<Auction | null>(null);
  const token = localStorage.getItem('token');

  useEffect(() => {
    fetchAuctions();
  }, []);

  const fetchAuctions = async () => {
    try {
      if (token) {
        const response = await getAuctions(token);
        setAuctions(response.data);
      }
    } catch (error) {
      console.error('Failed to fetch auctions', error);
    }
  };

  const handleEdit = (auction: Auction) => {
    setSelectedAuction(auction);
  };

  const handleFormSubmit = () => {
    fetchAuctions();
    setSelectedAuction(null);
  };

  const handleBatchSchedule = async (scheduledAuctions: Partial<Auction>[]) => {
    try {
      if (token) {
        const response = await createBatchAuctions(token, scheduledAuctions);
        console.log('Batch auctions created:', response.data);
        fetchAuctions();
      }
    } catch (error) {
      console.error('Failed to create batch auctions', error);
    }
  };

  return (
    <div>
      <h1>Manage Auctions</h1>
      <AuctionForm selectedAuction={selectedAuction} onSubmit={handleFormSubmit} /><br /><br /><br />
      <BatchAuctionScheduler onSubmit={handleBatchSchedule} /> <br /><br /><br />
      <AuctionList auctions={auctions} onEdit={handleEdit} fetchAuctions={fetchAuctions} />
    </div>
  );
};

export default AuctionsPage;