// src/pages/ProductsPage.tsx
import React, { useState, useEffect } from 'react';
import ProductList from '../components/ProductList';
import ProductForm from '../components/ProductForm';
import { getProducts } from '../../utils/adminAPI';
import { Product } from '../../../../types';

const ProductsPage: React.FC = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const token = localStorage.getItem('token'); // or however you're storing the token

  useEffect(() => {
    fetchProducts(); // Fetch products on component mount
  }, []);

  const fetchProducts = async () => {
    try {
      if(token){
      const response = await getProducts(token);
      setProducts(response.data);}
    } catch (error) {
      console.error('Failed to fetch products', error);
    }
  };

  const handleEdit = (product: Product) => {
    setSelectedProduct(product); // Set the product to be edited
  };

  const handleFormSubmit = () => {
    fetchProducts(); // Refresh product list after form submission
    setSelectedProduct(null); // Reset selected product
  };

  return (
    <div>
      <h1>Manage Products</h1>
      <ProductList products={products} onEdit={handleEdit} fetchProducts={fetchProducts} />
      <ProductForm selectedProduct={selectedProduct} onSubmit={handleFormSubmit} />
    </div>
  );
};

export default ProductsPage;
