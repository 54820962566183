import React, { useEffect, useState } from 'react';
import { Auction, Product } from '../../../../types';
import { Clock, User, Eye, ChevronDown } from 'lucide-react';
import styles from './AuctionGrid.module.css';
import { io } from 'socket.io-client';
const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:4000';
const socketUrl = apiUrl.endsWith('/api') ? apiUrl.slice(0, -4) : apiUrl; // Remove '/api' if it exists
const socket = io(socketUrl);

interface AuctionGridProps {
    auctions: Auction[];
    products: Product[];
    timers: Map<number, number>;
    prevBidders: Map<number, { username: string; uid: number }>;
    handleBid: (auctionId: number) => Promise<void>;
    showAuctionStatus?: boolean;
}

const AuctionGrid: React.FC<AuctionGridProps> = ({ 
    auctions, 
    products, 
    timers, 
    prevBidders, 
    handleBid,
    showAuctionStatus = false
}) => {
    const formatTime = (seconds: number): string => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    };

    const formatSTime = (date: Date): string => {
        return date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
    };

    const formatDate = (date: Date): string => {
        return date.toLocaleDateString('en-UK', { month: 'short', day: 'numeric', year: 'numeric' });
    };

    const getCountdown = (startTime: string): string => {
        const now = new Date();
        const start = new Date(startTime);
        const diff = start.getTime() - now.getTime();
        const days = Math.floor(diff / (1000 * 60 * 60 * 24));
        const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
        return `${days}d ${hours}h ${minutes}m`;
    };

    const productMap = new Map(products.map(product => [product.id, product]));

    const [activeViewers, setActiveViewers] = useState<Record<string, number>>({});

    useEffect(() => {
        socket.on('viewerUpdate', (data: { auctionId: string; activeViewers: number }) => {
            setActiveViewers(prev => ({
                ...prev,
                [data.auctionId]: data.activeViewers
            }));
        });

        return () => {
            socket.off('viewerUpdate');
        };
    }, []);

    const getFirstImage = (product: Product) => {
        if (product?.media && product.media.length > 0) {
            const sortedMedia = [...product.media].sort((a, b) => a.order - b.order);
            return sortedMedia[0].url;
        }
        return undefined;
    };

    return (
        <div className={styles.auctionGrid}>
            {auctions.map((auction) => {
                const product = productMap.get(auction.product_id);
                const remainingTime = timers.get(auction.id) || 0;
                const isActive = auction.status === 'active';
                const isCompleted = auction.status === 'completed';
                const isEndingSoon = remainingTime <= 900;
                const prevBidder = prevBidders.get(auction.id);
                const link = `/auction/${auction.id}`;
 
                if (!product) return null;

                return (
                    <div key={auction.id} className={`${styles.auctionCard} ${isEndingSoon ? styles.endingSoon : ''}`}>
                        {showAuctionStatus && (
                            <div className={`${styles.marketAuctionBadge} ${
                                isActive ? styles.badgeActive : 
                                isCompleted ? styles.badgeCompleted : 
                                styles.badgeUpcoming
                            }`}>
                                {isActive ? '🔴 Live Auction' : 
                                 isCompleted ? '✓ Auction Ended' : 
                                 '⏰ Auction Soon'}
                            </div>
                        )}
                        {prevBidder && (
                            <div 
                                key={`${prevBidder.username}-${auction.current_bid}`} 
                                className={styles.previousBidder}
                            >
                                <User size={16} />
                                <span>{prevBidder.username}</span>
                            </div>
                        )}
                        <a href={link}>
                            {product.media && product.media.length > 0 && (
                                <img 
                                    src={getFirstImage(product)} 
                                    alt={product.title} 
                                    className={styles.auctionImage}
                                />
                            )}
                        </a>

                        <div className={`${styles.auctionStatus} ${
                            isActive ? styles.statusActive : 
                            isCompleted ? styles.statusCompleted : 
                            styles.statusUpcoming
                        }`}>
                            {isActive ? 'Active' : isCompleted ? 'Completed' : 'Upcoming'}
                        </div>
                        <div className={styles.auctionDetails}>
                            <h3>{product.title}</h3>
                            <p className={styles.auctionPrice}>£{(auction.current_bid/100).toFixed(2) || 0}</p>
                            <div className={styles.auctionTimer}>
                                <Clock size={24} />
                                {isActive ? (
                                    auction.current_bid > 0
                                        ? formatTime(remainingTime)
                                        : formatTime(auction.countdown_timer)
                                ) : (
                                    `BID IN ${getCountdown(auction.start_time)}`
                                )}
                            </div>
                            {isActive ? (
                                <button
                                    className={styles.bidButton}
                                    onClick={() => handleBid(auction.id)}
                                >
                                    Place Bid Now
                                </button>
                            ) : (
                                <button className={styles.bidButton} disabled>
                                 {formatDate(new Date(auction.start_time))}  {formatSTime(new Date(auction.start_time))}
                                </button>
                            )}
                        </div>
                        <div className={styles.auctionInfo}>
                        <span>
                                <Eye size={16} /> 
                                {activeViewers[auction.id] || Math.floor(Math.random() * 26) + 25}
                            </span>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default AuctionGrid;