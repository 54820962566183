import React, { useState, useEffect } from 'react';
import { ProductCategory, Product } from '../../../../types';
import { getCategories, createCategory, updateCategory, deleteCategory } from '../../utils/adminAPI';
import styles from './CategoryManager.module.css';

interface CategoryFormData {
  name: string;
  image_url: string;
  description: string;
}

const CategoryManager: React.FC = () => {
  const [categories, setCategories] = useState<ProductCategory[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<ProductCategory | null>(null);
  const [formData, setFormData] = useState<CategoryFormData>({
    name: '',
    image_url: '',
    description: '',
  });
  const [expandedCategory, setExpandedCategory] = useState<number | null>(null);
  const token = localStorage.getItem('token');

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      if (token) {
        const response = await getCategories(token);
        setCategories(response.data);
      }
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (!token) return;

      if (selectedCategory) {
        await updateCategory(token, selectedCategory.id, { name: formData.name, image_url: formData.image_url, description: formData.description });
      } else {
        await createCategory(token, { name: formData.name ,image_url: formData.image_url, description: formData.description});
      }
      
      fetchCategories();
      resetForm();
    } catch (error) {
      console.error('Error saving category:', error);
    }
  };

  const handleDelete = async (categoryId: number) => {
    if (!window.confirm('Are you sure you want to delete this category?')) return;
    
    try {
      if (token) {
        await deleteCategory(token, categoryId);
        fetchCategories();
      }
    } catch (error) {
      console.error('Error deleting category:', error);
    }
  };

  const resetForm = () => {
    setFormData({ 
      name: '', 
      image_url: '',
      description: '',
    });
    setSelectedCategory(null);
  };

  const handleEdit = (category: ProductCategory) => {
    setSelectedCategory(category);
    setFormData({
      name: category.name,
      image_url: category.image_url,
      description: category.description,
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.formSection}>
        <h2>{selectedCategory ? 'Edit Category' : 'Add New Category'}</h2>
        <form onSubmit={handleSubmit} className={styles.form}>
          <div className={styles.formGroup}>
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              required
            />
          </div>

          <div className={styles.formGroup}>
            <label htmlFor="image_url">Image URL:</label>
            <input
              type="text"
              id="image_url"
              value={formData.image_url}
              onChange={(e) => setFormData({ ...formData, image_url: e.target.value })}
              required
            />
          </div>

          <div className={styles.buttonGroup}>
            <button type="submit">
              {selectedCategory ? 'Update Category' : 'Create Category'}
            </button>
            {selectedCategory && (
              <button type="button" onClick={resetForm}>
                Cancel
              </button>
            )}
          </div>
        </form>
      </div>

      <div className={styles.categoriesList}>
        <h2>Categories</h2>
        {categories.map((category) => (
          <div key={category.id} className={styles.categoryCard}>
            <div className={styles.categoryHeader}>
              {/* {category.image_url && (
                <img 
                  src={category.image_url} 
                  alt={category.name}
                  className={styles.categoryImage}
                />
              )} */}
              <h3>{category.name}</h3>
              <div className={styles.categoryActions}>
                <button 
                  className={styles.actionButton} 
                  onClick={() => handleEdit(category)}
                >
                  Edit
                </button>
                <button 
                  className={styles.actionButton} 
                  onClick={() => handleDelete(category.id)}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CategoryManager; 