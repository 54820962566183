import { sendPushNotificationToAllDevices, saveSubscription } from './accountAPI';

const urlBase64ToUint8Array = (base64String: string) => {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

export const subscribeToPushNotifications = async () => {
  if ('serviceWorker' in navigator && 'PushManager' in window) {
    try {
      const registration = await navigator.serviceWorker.ready;
      let subscription = await registration.pushManager.getSubscription();

      if (!subscription) {
        const vapidPublicKey = process.env.REACT_APP_VAPID_PUBLIC_KEY;
        if (!vapidPublicKey) {
          throw new Error('VAPID public key is not set');
        }

        subscription = await registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: urlBase64ToUint8Array(vapidPublicKey),
        });

        // Save the subscription to the server
        await saveSubscription(subscription);
        console.log('New push notification subscription saved to server');
      }

      return subscription;
    } catch (error) {
      console.error('Failed to subscribe to push notifications:', error);
      return null;
    }
  }
  return null;
};

export const sendLockScreenNotificationToAllDevices = async (notificationData: {
  title: string;
  body: string;
  icon?: string;
  badge?: string;
  data?: any;
  tag?: string;
}) => {
  console.log('sendLockScreenNotificationToAllDevices called with:', notificationData);
  try {
    console.log('Calling sendPushNotificationToAllDevices...');
    return await sendPushNotificationToAllDevices(notificationData);
  } catch (error) {
    console.error('Error in sendLockScreenNotificationToAllDevices:', error);
    throw error;
  }
};
