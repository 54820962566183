import React, { useEffect, useState } from 'react';
import { Address, Invoice, Product, User } from '../../../../types';
import generateInvoice, { getAddressDetails, getUserInvoices } from '../../utils/accountAPI';
import { getProducts } from '../../utils/coreAPI';
import styles from './MarketOrders.module.css';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import Loading from '../../components/loading';

interface MarketOrdersProps {
  token: string;
  userId: number;
  user: Partial<User>;
}

interface EnrichedMarketOrder extends Invoice {
  product?: Product;
  shipping_address_id?: number;
  billing_address_id?: number;
}

const ORDERS_PER_PAGE = 5;

const MarketOrders: React.FC<MarketOrdersProps> = ({ token, userId, user }) => {
  const [orders, setOrders] = useState<EnrichedMarketOrder[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [dropdowns, setDropdowns] = useState({
    'awaiting payment': false,
    paid: false,
    shipped: false,
    delivered: false,
    completed: false,
    cancelled: false
  });

  useEffect(() => {
    fetchMarketOrders();
  }, [token, userId]);

  const fetchMarketOrders = async () => {
    setLoading(true);
    setError('');
    try {
      const [invoicesResponse, productsResponse] = await Promise.all([
        getUserInvoices(token),
        getProducts()
      ]);

      console.log('Raw invoices:', invoicesResponse);

      const marketOrders = invoicesResponse
        .filter((invoice: Invoice) => 
          invoice.type === 'market' && 
          invoice.user_id === userId
        )
        .map((invoice: Invoice) => {
          const enrichedOrder = {
            ...invoice,
            product: productsResponse.data.find((p: Product) => p.id === invoice.product_id)
          };
          console.log('Enriched order:', enrichedOrder);
          return enrichedOrder;
        });

      setOrders(marketOrders);
    } catch (err) {
      console.error('Error fetching market orders:', err);
      setError('Failed to fetch market orders');
    } finally {
      setLoading(false);
    }
  };

  const toggleDropdown = (section: keyof typeof dropdowns) => {
    setDropdowns(prev => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  const getOrdersByStatus = (status: string) => {
    return orders.filter(order => order.status === status);
  };

  const handleGenerateInvoice = async (order: EnrichedMarketOrder) => {
    console.log('Attempting to generate invoice for order:', order);

    if (!order?.id) {
      console.error('Order ID is missing:', order);
      setError('Invalid order data: Missing order ID');
      return;
    }

    if (!user?.id) {
      console.error('User ID is missing:', user);
      setError('User information is missing. Please try logging in again.');
      return;
    }

    if (!order.product?.id) {
      console.error('Product information is missing:', order.product);
      setError('Product information is missing for this order.');
      return;
    }

    try {
      const addressResponse = await getAddressDetails(token);
      console.log('Address response:', addressResponse);

      if (!addressResponse?.data) {
        setError('Failed to fetch address details');
        return;
      }

      const addresses = addressResponse.data;
      
      const shippingAddress = addresses.find(
        (addr: Address) => addr.id === order.shipping_address_id
      );
      
      const billingAddress = addresses.find(
        (addr: Address) => addr.id === order.billing_address_id
      );

      if (!shippingAddress || !billingAddress) {
        console.error('Address validation failed:', {
          order_id: order.id,
          shipping_address_id: order.shipping_address_id,
          billing_address_id: order.billing_address_id,
        });
        setError('Missing required information to generate invoice.');
        return;
      }

      await generateInvoice(order, user, order.product, null, shippingAddress, billingAddress);
    } catch (error) {
      console.error('Error generating invoice:', error);
      alert('Failed to generate invoice. Please try again.');
    }
  };

  if (loading) {
    return (
      <Loading 
        message="Loading your market orders..." 
        subMessage="Fetching your order details" 
      />
    );
  }

  if (error) {
    return <div className={styles.error}>{error}</div>;
  }

  return (
    <div className={styles.container}>
      <h2 className={styles.header}>Market Orders</h2>

      {orders.length === 0 ? (
        <div className={styles.emptyState}>
          <p>No market orders found.</p>
        </div>
      ) : (
        ['awaiting payment', 'paid', 'shipped', 'delivered', 'completed', 'cancelled'].map((status) => {
          const statusOrders = getOrdersByStatus(status);
          if (statusOrders.length === 0) return null;

          return (
            <div key={status} className={styles.orderSection}>
              <button 
                onClick={() => toggleDropdown(status as keyof typeof dropdowns)}
                className={styles.sectionToggle}
              >
                {status.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')} Orders ({statusOrders.length})
                <span className={styles.toggleIcon}>
                  {dropdowns[status as keyof typeof dropdowns] ? '▼' : '▲'}
                </span>
              </button>

              {dropdowns[status as keyof typeof dropdowns] && (
                <div className={styles.ordersList}>
                  {statusOrders.map((order) => (
                    <div key={order.id} className={styles.orderCard}>
                      <div className={styles.orderHeader}>
                        <img
                        //   src={order.product?.media_url || '/default-product.jpg'}
                          alt={order.product?.title}
                          className={styles.productImage}
                        />
                        <div className={styles.orderInfo}>
                          <h3 className={styles.productTitle}>
                            {order.product?.title || 'Unknown Product'}
                          </h3>
                          <p className={styles.orderDate}>
                            Order Date: {new Date(order.date).toLocaleDateString()}
                          </p>
                          <p className={styles.orderAmount}>
                            Total: £{order.amount}
                          </p>
                        </div>
                      </div>

                      <div className={styles.orderActions}>
                        {order.status === 'completed' && (
                          <button
                            onClick={() => handleGenerateInvoice(order)}
                            className={styles.actionButton}
                          >
                            Generate Invoice
                          </button>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          );
        })
      )}
    </div>
  );
};

export default MarketOrders; 