import React, { useState, useEffect } from 'react';
import { Product, Invoice, Auction, ProductCategory } from '../../../types';
import { ShoppingCart, Package, Clock, Euro,PoundSterling, Eye, ChevronRight, ChevronDown } from 'lucide-react';
import styles from './MarketPage.module.css';
import { getProducts, getAuctions, getCategories } from '../utils/coreAPI';
import { Link, useNavigate } from 'react-router-dom';
import { useError } from '../components/ErrorContext';
import Banner from '../components/Banner';
import CategoryFilterPanel from '../components/CategoryFilterPanel';
import Pagination from '../components/pagination';
import AuctionGrid from '../auction/components/AuctionGrid';
import Loading from '../components/loading';

type SortType = 'rrp-asc' | 'rrp-desc' | 'newest' | 'popular';

const sampleBannerSlides = [
  {
    image: '/images/banners/electronics-banner.jpg',
    title: 'Latest Electronics',
    description: 'Discover cutting-edge technology at unbeatable prices',
    link: '/category/electronics'
  },
  {
    image: '/images/banners/fashion-banner.jpg',
    title: 'Fashion Essentials',
    description: 'Style meets comfort in our new collection',
    link: '/category/fashion'
  },
  {
    image: '/images/banners/home-banner.jpg',
    title: 'Home & Living',
    description: 'Transform your space with our premium selection',
    link: '/category/home'
  }
];

const MarketPage: React.FC = () => {
  const [availableProducts, setAvailableProducts] = useState<Product[]>([]);
  const [activeAuctions, setActiveAuctions] = useState<Auction[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { showError } = useError();
  const [selectedCategory, setSelectedCategory] = useState<number | null>(null);
  const [sortType, setSortType] = useState<SortType>('rrp-asc');
  const [categories, setCategories] = useState<ProductCategory[]>([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(12);
  const [timers, setTimers] = useState<Map<number, number>>(new Map());
  const [prevBidders, setPrevBidders] = useState<Map<number, { username: string; uid: number }>>(new Map());

  useEffect(() => {
    const token = localStorage.getItem('token');
    setIsLoggedIn(!!token);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [productsRes, auctionsRes] = await Promise.all([
          getProducts(),
          getAuctions()
        ]);

        // Get all active/upcoming auctions and sum their quantities by product
        const auctionQuantities = auctionsRes.data
          .filter(auction => auction.status === 'upcoming' || auction.status === 'ongoing')
          .reduce((acc, auction) => {
            const productId = auction.product_id;
            acc[productId] = (acc[productId] || 0) + 1; // Each auction reserves 1 product
            return acc;
          }, {} as Record<number, number>);

        // Filter products that have more than 10 available after reserving auction stock
        const availableForPurchase = productsRes.data.filter(product => {
          const reservedForAuctions = auctionQuantities[product.id] || 0;
          const availableStock = product.stock - reservedForAuctions;
          return availableStock >= 10;
        });

        setAvailableProducts(availableForPurchase);
        setActiveAuctions(auctionsRes.data.filter(a => a.status === 'ongoing'));
        setLoading(false);
      } catch (err) {
        setError('Failed to load products. Please try again later.');
        showError('Error loading market data');
      }
    };

    fetchData();
  }, [showError]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await getCategories(); // Add this API call
        setCategories(response.data);
      } catch (err) {
        showError('Error loading categories');
      }
    };
    fetchCategories();
  }, []);

  const filteredProducts = availableProducts
    .filter(product => !selectedCategory || product.category_id === selectedCategory)
    .sort((a, b) => {
      switch (sortType) {
        case 'rrp-asc': return a.rrp - b.rrp;
        case 'rrp-desc': return b.rrp - a.rrp;
        case 'newest': return new Date(b.id).getTime() - new Date(a.id).getTime();
        case 'popular': return b.stock - a.stock;
        default: return 0;
      }
    });

  const handlePurchase = async (product: Product) => {
    try {
      // Create invoice logic here
      const invoice: Partial<Invoice> = {
        amount: product.rrp,
        credits: 0,
        status: 'awaiting payment',
        date: new Date().toISOString(),
      };

      // TODO: Add API call to create invoice and process payment
      // This would typically involve:
      // 1. Creating an invoice
      // 2. Initiating payment processing
      // 3. Updating stock levels
      // 4. Redirecting to payment confirmation
      
    } catch (err) {
      setError('Failed to process purchase. Please try again.');
      showError('Payment processing failed');
    }
  };

  const handleUnauthorizedClick = (e: React.MouseEvent) => {
    e.preventDefault();
    showError('Please sign in or register to view product details and make purchases');
    navigate('/login');
  };

  const handlePageChange = (page: number) => {
    const marketContent = document.querySelector(`.${styles.mainContent}`);
    if (marketContent) {
        marketContent.scrollIntoView({ behavior: 'smooth' });
    }
    
    setCurrentPage(page);
  };

  const handlePageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize);
    setCurrentPage(1);
  };

  const indexOfLastItem = currentPage * pageSize;
  const indexOfFirstItem = indexOfLastItem - pageSize;
  const currentProducts = filteredProducts.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredProducts.length / pageSize);

  const handleBid = async (auctionId: number) => {
    if (!isLoggedIn) {
      handleUnauthorizedClick(new MouseEvent('click') as unknown as React.MouseEvent);
      return;
    }
    navigate(`/auction/${auctionId}`);
  };

  if (loading) {
    return (
      <Loading 
        message="Loading market products..." 
        subMessage="Fetching available items for purchase " 
      />
    );
  }

  if (error) {
    return <div className={styles.errorMessage}>{error}</div>;
  }

  return (
    <div className={styles.marketContainer}>
      <Banner slides={sampleBannerSlides} autoPlayInterval={6000} />
      
      {activeAuctions.length > 0 && (
        <div className={styles.auctionSection}>
          <h2>Active Auctions</h2>
          <AuctionGrid
            auctions={activeAuctions}
            products={availableProducts}
            timers={timers}
            prevBidders={prevBidders}
            handleBid={handleBid}
          />
        </div>
      )}

      <div className={styles.marketHeader}>
        <h1>Market</h1>
        <p>Purchase products directly at retail price</p>
      </div>

      <div className={styles.marketContent}>
        <aside className={styles.filterSidebar}>
          <CategoryFilterPanel
            categories={categories}
            selectedCategory={selectedCategory}
            onCategoryChange={setSelectedCategory}
          />
        </aside>

        <main className={styles.mainContent}>
          <div className={styles.titleContainer}>
            <div className={styles.filterSortContainer}>
              <div className={styles.sortGroup}>
                <select 
                  className={styles.sortSelect}
                  value={sortType}
                  onChange={(e) => setSortType(e.target.value as SortType)}
                >
                  <option value="rrp-asc">Price: Low to High</option>
                  <option value="rrp-desc">Price: High to Low</option>
                  <option value="newest">Newest First</option>
                  <option value="popular">Most Popular</option>
                </select>
                <ChevronDown className={styles.sortIcon} size={16} />
              </div>
            </div>
          </div>

          <div className={styles.productGrid}>
            {currentProducts.map((product) => {
              const category = categories.find(c => c.id === product.category_id);
              const firstImage = product.media?.length > 0 
                ? product.media.sort((a, b) => a.order - b.order)[0].url
                : '/default-product.jpg';
              
              return (
                <Link 
                  to={`/details/${product.id}?mode=market`}
                  className={styles.productCard}
                  key={product.id}
                  onClick={!isLoggedIn ? handleUnauthorizedClick : undefined}
                >
                  <div className={styles.imageContainer}>
                    <img 
                      src={firstImage}
                      alt={product.title}
                      className={styles.productImage}
                      onError={(e) => {
                        const target = e.target as HTMLImageElement;
                        target.src = '/default-product.jpg';
                      }}
                    />
                    {category && (
                      <div className={styles.productCategory}>
                        {category.name}
                      </div>
                    )}
                  </div>

                  <div className={styles.productDetails}>
                    <h3 className={styles.productTitle}>{product.title}</h3>
                    <p className={styles.productDescription}>{product.description}</p>
                    
                    <div className={styles.productPrice}>
                      £{(product.rrp)}
                      <span className={styles.productPostage}>
                        + £{(product.postage/100)} shipping
                      </span>
                    </div>

                    <div className={styles.productActions}>
                      <button 
                        className={styles.viewDetailsButton}
                        onClick={(e) => {
                          e.preventDefault();
                          if (!isLoggedIn) {
                            handleUnauthorizedClick(e);
                          }
                        }}
                      >
                        <Eye className={styles.icon} />
                        {isLoggedIn ? 'BUY NOW!' : 'SIGN IN TO BUY'}
                      </button>
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>

          {totalPages > 1 && (
            <Pagination
              currentPage={currentPage}
              totalItems={filteredProducts.length}
              pageSize={pageSize}
              onPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
              pageSizeOptions={[12, 24, 48]}
              scrollToTop={false}
            />
          )}
        </main>
      </div>
    </div>
  );
};

export default MarketPage;
