import React, { useState } from 'react';
import { Auction, Product, ProductCategory } from '../../../../types';
import { ChevronDown } from 'lucide-react';
import styles from './FilteredGrid.module.css';
import AuctionGrid from './AuctionGrid';
import CategoryFilterPanel from '../../components/CategoryFilterPanel';
import Pagination from '../../components/pagination';

type FilterType = 'all' | 'active' | 'upcoming';
type SortType = 'price-asc' | 'price-desc' | 'time-asc' | 'time-desc';

interface FilteredGridProps {
    auctions: Auction[];
    products: Product[];
    timers: Map<number, number>;
    prevBidders: Map<number, { username: string; uid: number }>;
    handleBid: (auctionId: number) => Promise<void>;
    currentPage: number;
    itemsPerPage: number;
    onPageChange: (page: number) => void;
    totalItems: number;
    categories: ProductCategory[];
}

const FilteredGrid: React.FC<FilteredGridProps> = ({
    auctions,
    products,
    timers,
    prevBidders,
    handleBid,
    currentPage,
    itemsPerPage,
    onPageChange,
    totalItems,
    
    categories
}) => {
    // Get URL parameters
    const urlParams = new URLSearchParams(window.location.search);
    const urlCategory = urlParams.get('category');
    const urlPage = parseInt(urlParams.get('page') || '1');

    const [filterType, setFilterType] = React.useState<FilterType>('all');
    const [sortType, setSortType] = React.useState<SortType>('time-asc');
    const [selectedCategory, setSelectedCategory] = React.useState<string | null>(urlCategory);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [pageSize, setPageSize] = useState(10);

    // Update URL and state when parameters change
    React.useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const category = params.get('category');
        const page = parseInt(params.get('page') || '1');
        
        setSelectedCategory(category);
        onPageChange(page);
    }, [window.location.search]);

    // Update URL with new parameters
    const updateURL = (params: { category?: string | null; page?: number }) => {
        const urlParams = new URLSearchParams(window.location.search);
        
        // Update category
        if (params.category !== undefined) {
            if (params.category) {
                urlParams.set('category', params.category);
            } else {
                urlParams.delete('category');
            }
        }
        
        // Update page
        if (params.page !== undefined) {
            if (params.page && params.page > 1) {
                urlParams.set('page', params.page.toString());
            } else {
                urlParams.delete('page');
            }
        }
        
        const newUrl = `${window.location.pathname}${urlParams.toString() ? `?${urlParams.toString()}` : ''}`;
        window.history.pushState({}, '', newUrl);
    };

    const handleCategoryChange = (categoryId: number | null) => {
        const newCategory = categoryId?.toString() || null;
        setSelectedCategory(newCategory);
        
        // Reset to page 1 and update URL with new category
        onPageChange(1);
        updateURL({ 
            category: newCategory, 
            page: 1  // Always reset to page 1 when category changes
        });
    };

    const handlePageChange = (page: number) => {
        onPageChange(page);
        updateURL({ page });
    };

    const handlePageSizeChange = (newPageSize: number) => {
        setPageSize(newPageSize);
        handlePageChange(1); // Reset to first page when changing page size
    };

    const filterOptions = [
        { type: 'all', label: 'All Auctions' },
        { type: 'active', label: 'Active Auctions' },
        { type: 'upcoming', label: 'Upcoming Auctions' }
    ];

    const sortOptions = [
        { value: 'price-asc', label: 'Price: Low to High' },
        { value: 'price-desc', label: 'Price: High to Low' },
        { value: 'time-asc', label: 'Time: Least Remaining' },
        { value: 'time-desc', label: 'Time: Most Remaining' }
    ];

    // Filter and sort auctions
    const getFilteredAndSortedAuctions = () => {
        let filteredAuctions = [...auctions];
        
        // Apply category filter
        if (selectedCategory) {
            filteredAuctions = filteredAuctions.filter(auction => {
                const product = products.find(p => p.id === auction.product_id);
                return product?.category_id === parseInt(selectedCategory);
            });
        }
        
        // Apply status filter
        switch (filterType) {
            case 'active':
                filteredAuctions = filteredAuctions.filter(auction => auction.status === 'active');
                break;
            case 'upcoming':
                filteredAuctions = filteredAuctions.filter(auction => auction.status === 'upcoming');
                break;
        }

        // Apply sorting
        return filteredAuctions.sort((a, b) => {
            switch (sortType) {
                case 'price-asc':
                    return a.current_bid - b.current_bid;
                case 'price-desc':
                    return b.current_bid - a.current_bid;
                case 'time-asc':
                    const timeA = a.status === 'active' ? (timers.get(a.id) || 0) : new Date(a.start_time).getTime();
                    const timeB = b.status === 'active' ? (timers.get(b.id) || 0) : new Date(b.start_time).getTime();
                    return timeA - timeB;
                case 'time-desc':
                    const timeC = a.status === 'active' ? (timers.get(a.id) || 0) : new Date(a.start_time).getTime();
                    const timeD = b.status === 'active' ? (timers.get(b.id) || 0) : new Date(b.start_time).getTime();
                    return timeD - timeC;
                default:
                    return 0;
            }
        });
    };

    const filteredAndSortedAuctions = getFilteredAndSortedAuctions();
    const totalFilteredItems = filteredAndSortedAuctions.length;

    const getGridTitle = () => {
        const filterMap = {
            'all': 'All',
            'active': 'Active',
            'upcoming': 'Upcoming'
        };

        const categoryName = selectedCategory
            ? categories.find(c => c.id === parseInt(selectedCategory))?.name
            : 'Auctions';

        return `${filterMap[filterType]} ${categoryName}`;
    };

    // Reset to first page when filters change
    React.useEffect(() => {
        handlePageChange(1);
    }, [filterType, sortType, selectedCategory]);

    return (
        <div className={styles.gridContainer}>
            <div className={styles.titleSection}>
                <h2>{getGridTitle()}</h2>
                <p>Bid on exclusive items in our live auctions</p>
            </div>

            <div className={styles.filterControls}>
                <div className={styles.filterGroup}>
                    {filterOptions.map(({ type, label }) => (
                        <button 
                            key={type}
                            className={`${styles.filterButton} ${filterType === type ? styles.active : ''}`}
                            onClick={() => setFilterType(type as FilterType)}
                        >
                            {label}
                        </button>
                    ))}
                </div>

                <div className={styles.sortGroup}>
                    <button 
                        className={styles.sortButton}
                        onClick={() => setIsPopupOpen(true)}
                    >
                        <ChevronDown size={16} />
                        Sort By
                    </button>
                </div>
            </div>

            {/* Sort Popup */}
            <div 
                className={`${styles.popupMenu} ${isPopupOpen ? styles.open : ''}`}
                onClick={() => setIsPopupOpen(false)}
            >
                <div 
                    className={styles.popupContent}
                    onClick={(e) => e.stopPropagation()}
                >
                    <h3 className={styles.popupTitle}>Sort Auctions</h3>
                    {sortOptions.map(({ value, label }) => (
                        <button
                            key={value}
                            onClick={() => {
                                setSortType(value as SortType);
                                setIsPopupOpen(false);
                            }}
                            className={`${styles.popupButton} ${sortType === value ? styles.active : ''}`}
                        >
                            {label}
                        </button>
                    ))}
                </div>
            </div>

            <div className={styles.pageLayout}>
                <aside className={styles.categoryFilterPanel}>
                    <CategoryFilterPanel
                        categories={categories}
                        selectedCategory={selectedCategory ? parseInt(selectedCategory) : null}
                        onCategoryChange={handleCategoryChange}
                    />
                </aside>

                <main className={styles.mainContent}>
                    <AuctionGrid
                        auctions={filteredAndSortedAuctions.slice(
                            (currentPage - 1) * pageSize,
                            currentPage * pageSize
                        )}
                        products={products}
                        timers={timers}
                        prevBidders={prevBidders}
                        handleBid={handleBid}
                    />

                    {totalFilteredItems > pageSize && (
                        <Pagination
                            currentPage={currentPage}
                            totalItems={totalFilteredItems}
                            pageSize={pageSize}
                            onPageChange={handlePageChange}
                            onPageSizeChange={handlePageSizeChange}
                            pageSizeOptions={[10, 20, 50]}
                            scrollToTop={true}
                            containerRef={`.${styles.gridContainer}`}
                        />
                    )}
                </main>
            </div>
        </div>
    );
};

export default FilteredGrid;
