import React, { useState } from 'react';
import { loginUser } from '../../utils/accountAPI';
import styles from './LoginPage.module.css';

const LoginPage: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const handleLoginSuccess = (token: string) => {
    localStorage.setItem('token', token);
    window.location.href = '/account';
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const sanitizedEmail = email.trim().toLowerCase();
    console.log(sanitizedEmail);
    try {
      const response = await loginUser(sanitizedEmail, password);
      handleLoginSuccess(response.data.token);
      setError(null);
    } catch (err) {
      setError('Invalid email or password');
    }
  };

  return (
    <div className={styles.loginContainer}>
      <h2 className={styles.loginTitle}>Sign In</h2>
      <form onSubmit={handleSubmit}>
        <div className={styles.formGroup}>
          <label className={styles.formLabel} htmlFor="email">Email</label>
          <input
            className={styles.formInput}
            id="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>

        <div className={styles.formGroup}>
          <label className={styles.formLabel} htmlFor="password">Password</label>
          <input
            className={styles.formInput}
            id="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>

        <p className={styles.forgotPassword}>
          <a href="/forgot-password">Forgot Password?</a>
        </p>

        {/* <div className={`${styles.formGroup} ${styles.rememberMe}`}>
          <input
            id="rememberMe"
            type="checkbox"
            checked={rememberMe}
            onChange={() => setRememberMe(!rememberMe)}
          />
          <label htmlFor="rememberMe"> Remember me</label>
        </div> */}

        {error && <p className={styles.errorMessage}>{error}</p>}
        
        <button type="submit" className={styles.submitButton}>
          <i className="fa fa-sign-in-alt fa-fw"></i> Sign In
        </button>

        <p className={styles.registerPrompt}>
          Don't have an account yet? <br />
          <b>FREE sign-up. 2 FREE bids.</b> <a href="/register">Register</a>
        </p>
      </form>
    </div>
  );
};

export default LoginPage;
