import React, { useState, useEffect } from 'react';
import { Address } from '../../../../types';
import { createAddress, updateAddress } from '../../utils/adminAPI';
import styles from './AddressForm.module.css';

interface AddressFormProps {
  selectedAddress: Address | null;
  userId: number;
  onSubmit: () => void;
  onCancel?: () => void;  // Make onCancel optional
}

const AddressForm: React.FC<AddressFormProps> = ({
  selectedAddress,
  userId,
  onSubmit,
  onCancel,
}) => {
  const initialAddressState = {
    user_id: userId,
    address_type: 'SHIPPING',
    street_address: '',
    city: '',
    state_or_province: '',
    postal_code: '',
    country: '',
  };

  const [address, setAddress] = useState<Omit<Address, 'id'>>(initialAddressState);

  useEffect(() => {
    if (selectedAddress) {
      // Populate form with selected address data for editing
      const { id, ...rest } = selectedAddress;
      setAddress(rest);
    } else {
      // Reset form to initial state when adding a new address
      setAddress(initialAddressState);
    }
  }, [selectedAddress, userId]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setAddress((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('No token found');
      return;
    }

    try {
      if (selectedAddress) {
        // Update existing address
        await updateAddress(token, selectedAddress.id, {
          ...address,
          id: selectedAddress.id,
        });
      } else {
        // Create new address
        await createAddress(token, address);
      }
      onSubmit(); // Call the onSubmit prop to refresh the address list
      // Reset the form after successful submission
      setAddress(initialAddressState);
    } catch (error) {
      console.error('Error submitting address:', error);
      // Handle error (e.g., show error message to user)
    }
  };

  const handleCancel = () => {
    // Reset the form to initial state
    setAddress(initialAddressState);

    // Call the onCancel prop if it exists
    if (onCancel) {
      onCancel();
    }
  };

  return (
    <form onSubmit={handleSubmit} className={styles.addressForm}>
      <div className={styles.formContent}>
        <div className={styles.formGroup}>
          <label className={styles.formLabel}>Address Type</label>
          <select
            name="address_type"
            value={address.address_type}
            onChange={handleChange}
            required
            className={styles.formSelect}
          >
            <option value="SHIPPING">Shipping</option>
            <option value="BILLING">Billing</option>
          </select>
        </div>
        <div className={styles.formGroup}>
          <label className={styles.formLabel}>Street Address</label>
          <input
            type="text"
            name="street_address"
            value={address.street_address}
            onChange={handleChange}
            required
          />
        </div>
        <div className={styles.formGroup}>
          <label className={styles.formLabel}>City</label>
          <input
            type="text"
            name="city"
            value={address.city}
            onChange={handleChange}
            required
          />
        </div>
        <div className={styles.formGroup}>
          <label className={styles.formLabel}>State/Province</label>
          <input
            type="text"
            name="state_or_province"
            value={address.state_or_province}
            onChange={handleChange}
            required
          />
        </div>
        <div className={styles.formGroup}>
          <label className={styles.formLabel}>Postal Code</label>
          <input
            type="text"
            name="postal_code"
            value={address.postal_code}
            onChange={handleChange}
            required
          />
        </div>
        <div className={styles.formGroup}>
          <label className={styles.formLabel}>Country</label>
          <input
            type="text"
            name="country"
            value={address.country}
            onChange={handleChange}
            required
          />
        </div>
      </div>
      <div className={styles.buttonGroup}>
        <button type="submit" className={`${styles.button} ${styles.submitButton}`}>
          {selectedAddress ? 'Update' : 'Add'} Address
        </button>
        <button
          type="button"
          onClick={handleCancel}
          className={`${styles.button} ${styles.cancelButton}`}
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

export default AddressForm;
