import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';
import Banner from '../components/Banner';

interface FAQCategory {
  category: string;
  items: FAQItem[];
}

interface FAQItem {
  question: string;
  answer: React.ReactNode;
}

const faqData: FAQCategory[] = [
  {
    category: "How UShop.bid Works",
    items: [
      {
        question: "What is UShop.bid and how does it work?",
        answer: <>
          UShop.bid is an online auction platform where users can bid on new retail products. Each bid:
          <ul>
            <li>Costs a fixed amount (paid using pre-purchased credits)</li>
            <li>Increases the auction price by £0.01</li>
            <li>Extends the countdown timer</li>
          </ul>
          The last bidder when the timer reaches zero wins the right to purchase the item at the final auction price. 
          This is a competition of skill and timing - success depends on your bidding strategy and understanding of the platform.
        </>
      },
      {
        question: "Who can use UShop.bid?",
        answer: <>
          UShop.bid is strictly for users aged 18 and over:
          <ul>
            <li>You must be at least 18 years old to register and participate</li>
            {/* <li>Valid ID verification is required during registration</li> */}
            <li>Accounts found to belong to underage users will be terminated</li>
            <li>Any unused credits will be refunded if age verification fails</li>
          </ul>
          This age restriction is in place to ensure responsible participation.
        </>
      },
      {
        question: "Is this gambling?",
        answer: <>
          No. UShop.bid is not a gambling platform. Here's why:
          <ul>
            <li>Each bid is a conscious decision, not a random event</li>
            <li>Success depends on strategy, timing, and skill</li>
            <li>You always know exactly what you're bidding on and the current price</li>
            <li>The outcome is determined by user actions, not chance</li>
          </ul>
          However, like any auction system, there is no guarantee of winning or saving money. Please bid responsibly and within your means.
        </>
      },
      {
        question: "How do credits and bidding work?",
        answer: <>
          Credits are our platform's bidding currency:
          <ul>
            <li>Each credit allows one bid</li>
            <li>Credits must be purchased in advance</li>
            <li>Credits are non-refundable once used</li>
            <li>Each bid increases the auction price by £0.01</li>
            <li>The timer extends with each bid</li>
          </ul>
          Always consider the total cost: credit purchases plus the final auction price if you win.
        </>
      }
    ]
  },
  {
    category: "Bidding and Winning",
    items: [
      {
        question: "How can I increase my chances of winning?",
        answer: <>
          Success on UShop.bid requires:
          <ul>
            <li>Understanding how the timer and bidding work</li>
            <li>Careful timing of your bids</li>
            <li>Monitoring auction patterns</li>
            <li>Setting personal limits on spending</li>
            <li>Choosing auctions with fewer active bidders</li>
          </ul>
          Remember: There's no guarantee of winning or saving money. Bid responsibly and within your means.
        </>
      },
      {
        question: "What happens when I win an auction?",
        answer: <>
          When you win an auction:
          <ul>
            <li>You'll receive an immediate notification</li>
            <li>You have 24 hours to complete the purchase at the final auction price</li>
            <li>You can review the total cost (auction price + any applicable shipping)</li>
            <li>Standard consumer rights apply to your purchase</li>
          </ul>
          If you don't complete the purchase within 24 hours, you may be blocked from future auctions.
        </>
      }
    ]
  },
  {
    category: "Legal Rights and Consumer Protection",
    items: [
      {
        question: "What are my consumer rights?",
        answer: <>
          Your statutory rights are protected under UK and EU consumer law:
          <ul>
            <li>14-day right to cancel credit purchases before use</li>
            <li>Standard consumer rights for won auction items</li>
            <li>Protection under Distance Selling Regulations</li>
            <li>Data protection under GDPR</li>
          </ul>
          For items won at auction, your rights include:
          <ul>
            <li>Items must match their description</li>
            <li>Items must be of satisfactory quality</li>
            <li>Items must be fit for purpose</li>
          </ul>
        </>
      },
      {
        question: "What is your returns policy?",
        answer: <>
          For won auction items:
          <ul>
            <li>30-day returns for unopened items</li>
            <li>Refund of purchase price (excluding bid costs)</li>
            <li>Return shipping costs may apply</li>
            <li>Statutory rights not affected</li>
          </ul>
          Note: Used credits are non-refundable as they represent participation in completed auctions.
        </>
      }
    ]
  },
  {
    category: "Payments and Security",
    items: [
      {
        question: "How secure are payments?",
        answer: <>
          We maintain high security standards:
          <ul>
            <li>PCI DSS compliant payment processing</li>
            <li>SSL/TLS encryption for all transactions</li>
            <li>Secure storage of payment information</li>
            <li>Regular security audits</li>
          </ul>
          We accept major credit/debit cards and standard payment methods.
        </>
      }
    ]
  },
  {
    category: "Responsible Bidding",
    items: [
      {
        question: "How can I bid responsibly?",
        answer: <>
          We encourage responsible participation:
          <ul>
            <li>Set a personal budget before bidding</li>
            <li>Only bid what you can afford to spend</li>
            <li>Remember that winning isn't guaranteed</li>
            <li>Consider the total cost (credits + final price)</li>
            <li>Take regular breaks from bidding</li>
          </ul>
          If you feel you're spending too much time or money on the platform, please contact our support team.
        </>
      }
    ]
  },
  {
    category: "Technical Support",
    items: [
      {
        question: "What if I experience technical issues?",
        answer: <>
          For technical problems:
          <ul>
            <li>Document the issue with screenshots</li>
            <li>Contact support immediately</li>
            <li>Check our status page for known issues</li>
          </ul>
          We review all technical issues fairly and compensate users when appropriate for verified platform errors.
        </>
      }
    ]
  }
];

interface BannerSlide {
  image: string;
  title: string;
  description: string;
  link: string;
}

const faqBannerSlides: BannerSlide[] = [
  {
    image: 'https://images.unsplash.com/photo-1450101499163-c8848c66ca85?auto=format&fit=crop&q=80', // Help desk/customer support image
    title: 'Need Help?',
    description: 'Find answers to common questions about UShop.bid auctions, bidding, and winning',
    link: '#how-ushop-works'
  },
  {
    image: 'https://images.unsplash.com/photo-1460925895917-afdab827c52f?auto=format&fit=crop&q=80', // Business/auction related image
    title: 'New to Bidding?',
    description: 'Learn how our unique auction system works and get started with your first bid',
    link: '#bidding-and-winning'
  },
  {
    image: 'https://images.unsplash.com/photo-1516321318423-f06f85e504b3?auto=format&fit=crop&q=80', // Security/technology image
    title: 'Safe & Secure',
    description: 'Understand your rights and our commitment to consumer protection',
    link: '#legal-rights'
  }
];

const FAQPage: React.FC = () => {
  const [openCategory, setOpenCategory] = useState<number | null>(null);
  const [openQuestion, setOpenQuestion] = useState<{category: number, question: number} | null>(null);

  const toggleCategory = (categoryIndex: number) => {
    setOpenCategory(openCategory === categoryIndex ? null : categoryIndex);
    setOpenQuestion(null);
  };

  const toggleQuestion = (categoryIndex: number, questionIndex: number) => {
    setOpenQuestion(
      openQuestion?.category === categoryIndex && openQuestion?.question === questionIndex 
        ? null 
        : {category: categoryIndex, question: questionIndex}
    );
  };

  return (
    <div className='container'>
      <Banner slides={faqBannerSlides} autoPlayInterval={6000} />
      <div style={styles.container}>

      <h1 className="sectionTitle">Frequently Asked Questions</h1>
      <p style={styles.disclaimer}>
        This FAQ provides general guidance about UShop.bid. While we strive to keep this information accurate and up-to-date,
        please refer to our Terms of Service and User Agreement for complete legal details.
      </p>
      
      <div style={styles.faqList}>
        {faqData.map((category, categoryIndex) => (
          <div key={categoryIndex} style={styles.category}>
            <div 
              style={styles.categoryHeader} 
              onClick={() => toggleCategory(categoryIndex)}
            >
              <h2 style={styles.categoryTitle}>{category.category}</h2>
              {openCategory === categoryIndex ? <ChevronUp size={24} /> : <ChevronDown size={24} />}
            </div>
            
            {openCategory === categoryIndex && (
              <div style={styles.categoryContent}>
                {category.items.map((item, questionIndex) => (
                  <div key={questionIndex} style={styles.faqItem}>
                    <div 
                      style={styles.question} 
                      onClick={() => toggleQuestion(categoryIndex, questionIndex)}
                    >
                      <span>{item.question}</span>
                      {openQuestion?.category === categoryIndex && 
                       openQuestion?.question === questionIndex ? 
                         <ChevronUp size={20} /> : <ChevronDown size={20} />}
                    </div>
                    {openQuestion?.category === categoryIndex && 
                     openQuestion?.question === questionIndex && (
                      <div style={styles.answer}>
                        {item.answer}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>

      <div style={styles.footer}>
        <p>For additional information or specific concerns, please contact our support team at support@ushop.bid</p>
        <p>Last updated: {new Date().toLocaleDateString()}</p>
      </div>
      </div>
      </div>
    );
};

const styles = {
  container: {
    maxWidth: '95vw',
    margin: '0 auto',
    marginBottom: '50px',
    // padding: '40mapx 20px',
    backgroundColor: '#ffffff',
    fontFamily: 'Roboto, sans-serif',
  } as React.CSSProperties,
  title: {
    fontSize: '32px',
    color: '#e53e3e',
    textAlign: 'center',
    marginBottom: '20px',
  } as React.CSSProperties,
  disclaimer: {
    textAlign: 'center',
    color: '#666',
    marginBottom: '40px',
    fontSize: '14px',
  } as React.CSSProperties,
  faqList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
  } as React.CSSProperties,
  category: {
    border: '1px solid #eee',
    borderRadius: '10px',
    overflow: 'hidden',
  } as React.CSSProperties,
  categoryHeader: {
    backgroundColor: '#e53e3e',
    color: 'white',
    padding: '15px 20px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    transition: 'background-color 0.3s ease',
  } as React.CSSProperties,
  categoryTitle: {
    margin: 0,
    fontSize: '20px',
    fontWeight: 'bold',
  } as React.CSSProperties,
  categoryContent: {
    backgroundColor: 'white',
  } as React.CSSProperties,
  faqItem: {
    borderBottom: '1px solid #eee',
    '&:last-child': {
      borderBottom: 'none',
    },
  } as React.CSSProperties,
  question: {
    backgroundColor: '#f8f8f8',
    padding: '15px 20px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontWeight: 'bold',
    transition: 'background-color 0.3s ease',
    '&:hover': {
      backgroundColor: '#f0f0f0',
    },
  } as React.CSSProperties,
  answer: {
    padding: '20px',
    backgroundColor: '#ffffff',
    lineHeight: '1.6',
    '& ul': {
      paddingLeft: '20px',
      marginTop: '10px',
      marginBottom: '10px',
    },
    '& li': {
      marginBottom: '5px',
    },
  } as React.CSSProperties,
  footer: {
    marginTop: '40px',
    textAlign: 'center',
    color: '#666',
    fontSize: '14px',
  } as React.CSSProperties,
};

export default FAQPage;