import React, { useState, useEffect } from 'react';
import { Media, Product, ProductCategory, Review } from '../../../../types';
import { getMedia, deleteMedia, updateMedia, getProducts, getCategories, getReviews, uploadMedia } from '../../utils/adminAPI';
import styles from './MediaManager.module.css';

const MediaManager: React.FC = () => {
  const token = localStorage.getItem('token');
  const [media, setMedia] = useState<Media[]>([]);
  const [products, setProducts] = useState<Product[]>([]);
  const [categories, setCategories] = useState<ProductCategory[]>([]);
  const [reviews, setReviews] = useState<Review[]>([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState({
    entityType: 'product' as 'product' | 'category' | 'review',
    searchTerm: '',
    entityId: null as number | null
  });

  const [uploadData, setUploadData] = useState({
    files: [] as { file: File; order: number }[],
    selectedProduct: null as Product | null,
    selectedCategory: null as ProductCategory | null,
    selectedReview: null as Review | null,
    entityType: 'product' as 'product' | 'category' | 'review'
  });

  const [notification, setNotification] = useState<{
    message: string;
    type: 'success' | 'error';
  } | null>(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const [mediaRes, productsRes, categoriesRes, reviewsRes] = await Promise.all([
        getMedia(token || ''),
        getProducts(token || ''),
        getCategories(token || ''),
        getReviews(token || '')
      ]);
      setMedia(mediaRes.data);
      setProducts(productsRes.data);
      setCategories(categoriesRes.data);
      setReviews(reviewsRes.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const newFiles = Array.from(e.target.files).map((file, index) => ({
        file,
        order: index
      }));
      setUploadData(prev => ({
        ...prev,
        files: [...prev.files, ...newFiles].sort((a, b) => a.order - b.order)
      }));
    }
  };

  const updateFileOrder = (fileIndex: number, newOrder: number) => {
    setUploadData(prev => ({
      ...prev,
      files: prev.files.map((file, index) => 
        index === fileIndex ? { ...file, order: newOrder } : file
      ).sort((a, b) => a.order - b.order)
    }));
  };

  const handleUpload = async () => {
    const getEntityDetails = () => {
      switch (uploadData.entityType) {
        case 'product':
          return { id: uploadData.selectedProduct?.id || 0, type: 'product' };
        case 'category':
          return { id: uploadData.selectedCategory?.id || 0, type: 'category' };
        case 'review':
          return { id: uploadData.selectedReview?.id || 0, type: 'review' };
      }
    };

    const entityDetails = getEntityDetails();
    if (!entityDetails.id || !uploadData.files.length) return;

    try {
      const formData = new FormData();
      
      uploadData.files.forEach(({ file }, index) => {
        formData.append('media', file);
        formData.append(`order[${index}]`, index.toString());
      });
      
      formData.append('entity_type', entityDetails.type);
      formData.append('entity_id', entityDetails.id.toString());

      const response = await uploadMedia(token || '', formData, entityDetails.type, entityDetails.id);
      
      setUploadData({
        files: [],
        selectedProduct: null,
        selectedCategory: null,
        selectedReview: null,
        entityType: 'product'
      });
      
      showNotification('Media uploaded successfully', 'success');
      
      fetchData();
    } catch (error) {
      console.error('Error uploading media:', error);
      showNotification('Error uploading media', 'error');
    }
  };

  const handleUpdateOrder = async (mediaId: number, newOrder: number) => {
    try {
      await updateMedia(token || '', mediaId, { order: newOrder });
      fetchData();
    } catch (error) {
      console.error('Error updating media order:', error);
    }
  };

  const handleDelete = async (id: number) => {
    if (window.confirm('Are you sure you want to delete this media?')) {
      try {
        await deleteMedia(token || '', id);
        setMedia(prev => prev.filter(m => m.id !== id));
        showNotification('Media deleted successfully', 'success');
      } catch (error) {
        showNotification('Error deleting media', 'error');
        console.error('Error deleting media:', error);
      }
    }
  };

  const handleBulkReorder = async (reorderedMedia: Media[], entityId: number) => {
    try {
      const updates = reorderedMedia.map((media, index) => 
        updateMedia(token || '', media.id, { order: index })
      );
      await Promise.all(updates);
      showNotification('Order updated successfully', 'success');
      fetchData();
    } catch (error) {
      showNotification('Error updating order', 'error');
      console.error('Error updating media order:', error);
    }
  };

  const showNotification = (message: string, type: 'success' | 'error') => {
    setNotification({ message, type });
    setTimeout(() => setNotification(null), 3000);
  };

  // Group media by product
  const groupedMedia = media.reduce((acc, m) => {
    const key = `${m.entity_type}-${m.entity_id}`;
    if (!acc[key]) {
      let entity;
      let title;
      
      switch (m.entity_type) {
        case 'product':
          entity = products.find(p => p.id === m.entity_id);
          title = entity ? entity.title : 'Unknown Product';
          break;
        case 'category':
          entity = categories.find(c => c.id === m.entity_id);
          title = entity ? entity.name : 'Unknown Category';
          break;
        case 'review':
          entity = reviews.find(r => r.id === m.entity_id);
          title = entity ? `Review #${entity.id}` : 'Unknown Review';
          break;
      }

      acc[key] = {
        entity,
        title: title || 'Unknown Entity',
        entityType: m.entity_type,
        media: []
      };
    }
    acc[key].media.push(m);
    return acc;
  }, {} as Record<string, { entity: any; title: string; entityType: string; media: Media[] }>);
  const handleEntityTypeChange = (newEntityType: 'product' | 'category' | 'review') => {
    setFilter(prev => ({
      ...prev,
      entityType: newEntityType
    }));
    setUploadData(prev => ({
      ...prev,
      entityType: newEntityType,
      selectedProduct: null,
      selectedCategory: null,
      selectedReview: null
    }));
  };

  const filteredMedia = Object.entries(groupedMedia).filter(([_, { entityType, entity, title }]) => {
    const matchesType = entityType === filter.entityType;
    const matchesSearch = title.toLowerCase().includes(filter.searchTerm.toLowerCase());
    const matchesEntity = !filter.entityId || entity?.id === filter.entityId;
    return matchesType && matchesSearch && matchesEntity;
  });

  // Function to delete media
  const handleDeleteMedia = async (mediaId: number) => {
    if (window.confirm('Are you sure you want to delete this media?')) {
      try {
        await deleteMedia(token || '', mediaId); // Ensure this function is defined in your API
        setMedia(prev => prev.filter(m => m.id !== mediaId)); // Update state to remove deleted media
        showNotification('Media deleted successfully', 'success');
      } catch (error) {
        showNotification('Error deleting media', 'error');
        console.error('Error deleting media:', error);
      }
    }
  };

  return (
    <div className={styles.mediaManager}>
      <h2>Media Manager</h2>

      {notification && (
        <div className={`${styles.notification} ${styles[notification.type]}`}>
          {notification.message}
        </div>
      )}




<div className={styles.uploadSection}>
        <h3>Upload New Media</h3>
        <div className={styles.uploadControls}>
          <select
            value={uploadData.entityType}
            onChange={e => handleEntityTypeChange(e.target.value as 'product' | 'category' | 'review')}
            className={styles.select}
          >
            <option value="product">Product</option>
            <option value="category">Category</option>
            <option value="review">Review</option>
          </select>

          <select
            value={uploadData.entityType === 'product' ? uploadData.selectedProduct?.id : 
                   uploadData.entityType === 'category' ? uploadData.selectedCategory?.id :
                   uploadData.selectedReview?.id || ''}
            onChange={e => {
              const id = parseInt(e.target.value);
              setUploadData(prev => ({
                ...prev,
                selectedProduct: uploadData.entityType === 'product' ? products.find(p => p.id === id) || null : null,
                selectedCategory: uploadData.entityType === 'category' ? categories.find(c => c.id === id) || null : null,
                selectedReview: uploadData.entityType === 'review' ? reviews.find(r => r.id === id) || null : null
              }));
            }}
            className={styles.select}
          >
            <option value="">Select {uploadData.entityType}</option>
            {uploadData.entityType === 'product' && products.map(p => (
              <option key={p.id} value={p.id}>{p.title}</option>
            ))}
            {uploadData.entityType === 'category' && categories.map(c => (
              <option key={c.id} value={c.id}>{c.name}</option>
            ))}
            {uploadData.entityType === 'review' && reviews.map(r => (
              <option key={r.id} value={r.id}>Review #{r.id}</option>
            ))}
          </select>

          <input
            type="file"
            multiple
            accept="image/*,video/*"
            onChange={handleFileSelect}
            className={styles.fileInput}
          />

          <button 
            onClick={handleUpload}
            disabled={!uploadData.files.length || 
              !(uploadData.selectedProduct || uploadData.selectedCategory || uploadData.selectedReview)}
            className={styles.uploadButton}
          >
            Upload Files
          </button>
        </div>

        {uploadData.files.length > 0 && (
          <div className={styles.previewSection}>
            <h4>Selected Files:</h4>
            <div className={styles.previewGrid}>
              {uploadData.files.map((fileData, index) => {
                const previewUrl = URL.createObjectURL(fileData.file);
                
                return (
                  <div key={index} className={styles.previewItem}>
                    {fileData.file.type.startsWith('image/') ? (
                      <img 
                        src={previewUrl} 
                        alt={`Preview ${index + 1}`} 
                        onLoad={() => URL.revokeObjectURL(previewUrl)}
                      />
                    ) : (
                      <video 
                        src={previewUrl} 
                        controls 
                        onLoadedData={() => URL.revokeObjectURL(previewUrl)}
                      />
                    )}
                    <input
                      type="number"
                      value={fileData.order}
                      onChange={e => updateFileOrder(index, parseInt(e.target.value))}
                      min="0"
                      className={styles.orderInput}
                    />
                    <button
                      onClick={() => setUploadData(prev => ({
                        ...prev,
                        files: prev.files.filter((_, i) => i !== index)
                      }))}
                      className={styles.removeButton}
                    >
                      Remove
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
<br /><br />



      <div className={styles.filterControls}>
        <div className={styles.filterGroup}>
          <select
            value={filter.entityType}
            onChange={e => handleEntityTypeChange(e.target.value as 'product' | 'category' | 'review')}
            className={styles.select}
          >
            <option value="product">Products</option>
            <option value="category">Categories</option>
            <option value="review">Reviews</option>
          </select>

          <select
            value={filter.entityId || ''}
            onChange={e => setFilter(prev => ({ 
              ...prev, 
              entityId: e.target.value ? parseInt(e.target.value) : null 
            }))}
            className={styles.select}
          >
            <option value="">All {filter.entityType}s</option>
            {filter.entityType === 'product' && products.map(p => (
              <option key={p.id} value={p.id}>{p.title}</option>
            ))}
            {filter.entityType === 'category' && categories.map(c => (
              <option key={c.id} value={c.id}>{c.name}</option>
            ))}
            {filter.entityType === 'review' && reviews.map(r => (
              <option key={r.id} value={r.id}>Review #{r.id}</option>
            ))}
          </select>

          <input
            type="text"
            placeholder="Search..."
            value={filter.searchTerm}
            onChange={e => setFilter(prev => ({ ...prev, searchTerm: e.target.value }))}
            className={styles.searchInput}
          />
        </div>
      </div>


      <div className={styles.mediaGrid}>
        {filteredMedia.map(([key, { title, media: entityMedia }]) => (
          <div key={key} className={styles.mediaGroup}>
            <h3>{title}</h3>
            <div className={styles.mediaItems}>
              {entityMedia
                .sort((a, b) => a.order - b.order)
                .map((m, index) => (
                  <div 
                    key={m.id} 
                    className={styles.mediaItem}
                    draggable
                    onDragStart={e => e.dataTransfer.setData('mediaIndex', index.toString())}
                    onDragOver={e => e.preventDefault()}
                    onDrop={e => {
                      e.preventDefault();
                      const fromIndex = parseInt(e.dataTransfer.getData('mediaIndex'));
                      const toIndex = index;
                      if (fromIndex === toIndex) return;
                      
                      const reorderedMedia = [...entityMedia];
                      const [moved] = reorderedMedia.splice(fromIndex, 1);
                      reorderedMedia.splice(toIndex, 0, moved);
                      handleBulkReorder(reorderedMedia, m.entity_id);
                    }}
                  >
                    {m.type === 'image' ? (
                      <img src={m.url} alt={`${title} ${index + 1}`} />
                    ) : (
                      <video src={m.url} controls />
                    )}
                    <div className={styles.mediaControls}>
                      <button onClick={() => handleDeleteMedia(m.id)} className={styles.deleteButton}>
                        Delete
                      </button>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MediaManager; 