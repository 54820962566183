import React, { useEffect, useState } from 'react';
import { Heart, Users, DollarSign, Award } from 'lucide-react';
import styles from './CharityPage.module.css';
import * as coreAPI from '../utils/coreAPI';
import Banner from '../components/Banner';

interface BannerSlide {
  image: string;
  title: string;
  description: string;
  link: string;
}
interface CharityStats {
  totalDonated: number;
  projectsSupported: number;
  peopleHelped: number;
  activeInitiatives: number;
}

interface CharityProject {
  id: number;
  name: string;
  description: string;
  amountRaised: number;
  goal: number;
  imageUrl: string;
  category: string;
}

const charityBannerSlides: BannerSlide[] = [
  {
    image: 'https://images.unsplash.com/photo-1497633762265-9d179a990aa6',
    title: 'Meem Madaat Education Fund',
    description: 'Supporting educational initiatives for underprivileged students in our community',
    link: '#current-initiatives'
  },
  {
    image: 'https://images.unsplash.com/photo-1488521787991-ed7bbaae773c',
    title: 'Our Impact',
    description: '50% of every successful auction supports Meem Madaat charitable initiatives',
    link: '#impact-section'
  },
  {
    image: 'https://images.unsplash.com/photo-1559027615-cd4628902d4a',
    title: 'Community Support',
    description: 'Building stronger communities through sustainable charitable programs',
    link: '#stats-section'
  }
];

const CharityPage: React.FC = () => {
  const [stats, setStats] = useState<CharityStats>({
    totalDonated: 0,
    projectsSupported: 0,
    peopleHelped: 200,
    activeInitiatives: 1
  });

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const completedResponse = await coreAPI.getCompletedAuctions();
        const totalDonated = completedResponse.data.reduce((sum, auction) => 
          sum + auction.current_bid, 0) / 100; //

        const bidsResponse = await coreAPI.getBids();
        const totalPledged = (bidsResponse.data.length / 200); //50% of every bid goes to charity

        setStats(prev => ({
          ...prev,
          totalDonated,
          projectsSupported: totalPledged
        }));
      } catch (error) {
        console.error('Failed to fetch stats:', error);
      }
    };

    fetchStats();
  }, []);

  const [projects, setProjects] = useState<CharityProject[]>([
    {
      id: 1,
      name: "Meem Madaat Education Fund",
      description: "Supporting educational initiatives and providing resources for underprivileged students in our community.",
      amountRaised: 2,
      goal: 10000,
      imageUrl: "/images/charity/meem-education.jpg",
      category: "Education"
    },
    // {
    //   id: 2,
    //   name: "Community Support Program",
    //   description: "Providing essential support and resources to families in need within our local community.",
    //   amountRaised: 6200,
    //   goal: 7500,
    //   imageUrl: "/images/charity/community-support.jpg",
    //   category: "Community"
    // },
    // {
    //   id: 3,
    //   name: "Youth Development Initiative",
    //   description: "Empowering young people through mentorship, skills training, and leadership development.",
    //   amountRaised: 4800,
    //   goal: 5000,
    //   imageUrl: "/images/charity/youth-development.jpg",
    //   category: "Youth"
    // }
  ]);

  return (
    <div className={styles.charityPage}>
      <Banner slides={charityBannerSlides} autoPlayInterval={6000} />
      <h2 className="sectionTitle">Current Stats</h2>

      <div className={styles.statsGrid}>

        <div className={styles.statCard}>
          <DollarSign size={24} />
          <h3>Total Donated</h3>
          <p>£{stats.totalDonated.toLocaleString()}</p>
        </div>
        <div className={styles.statCard}>
          <Award size={24} />
          <h3>Pledged Donations</h3>
          <p>£{stats.projectsSupported.toLocaleString()}</p>
        </div>
        {/* <div className={styles.statCard}>
          <Users size={24} />
          <h3>People Helped</h3>
          <p>{stats.peopleHelped.toLocaleString()}</p>
        </div> */}
        <div className={styles.statCard}>
          <Heart size={24} />
          <h3>Active Initiatives</h3>
          <p>{stats.activeInitiatives}</p>
        </div>
      </div>

      <section className={styles.currentProjects}>
        <h2 className="sectionTitle">Current Initiatives</h2>
        <div className={styles.projectsGrid}>
          {projects.map(project => (
            <div key={project.id} className={styles.projectCard}>
              {/* <div className={styles.projectImage}>
                <img src={project.imageUrl} alt={project.name} />
                <span className={styles.category}>{project.category}</span>
              </div> */}
              <div className={styles.projectContent}>
                <h3>{project.name}</h3>
                <p>{project.description}</p>
                <div className={styles.progressBar}>
                  <div 
                    className={styles.progress} 
                    style={{ width: `${(project.amountRaised / project.goal) * 100}%` }}
                  />
                </div>
                <div className={styles.projectStats}>
                  <span>£{project.amountRaised.toLocaleString()} raised</span>
                  <span>of £{project.goal.toLocaleString()}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>

      <section className={styles.impactSection}>
        <h2 className="sectionTitle">Our Impact</h2>
        <p>Every bid on UShop.bid contributes to these worthy causes. We're committed to transparency and regularly update our charity contributions and impact metrics.</p>
        {/* <button className={styles.impactButton}>View Detailed Impact Report</button> */}
      </section>
    </div>
  );
};

export default CharityPage; 